/*
 Domain calculation for the X-axis:

 On generation of the graphs, the user would ideally be able to view the entire span of possible
 price outcomes for his/her portfolio through expiration. Of course, this can't be guaranteed as
 markets always have the potential to move to extreme levels that weren't anticipated. So, in light
 of this, we have to settle for 'most theoretically-possible price outcomes through expiration'. In
 the context of the Black-76 model, ~68.27% of outcomes will lie within 1 standard deviation of the
 corresponding underlying futures price, ~95.45% will lie within 2, and ~99.73% will lie within 3.
 The question then becomes: since each option in the portfolio has its own measure of standard
 deviation in the form of annualized implied volatility, how can we arrive at a single, relevant
 value for the 'implied volatility of the portfolio' that uses information from each option? One
 practical solution is to use the concept of 'Vega-Weighted Implied Volatility':

 VWIV  ~  (sum(Vega_j * ImpVol_j) / sum(Vega_j)) * sqrt(tau/365),

 where 'j' is the index of an option in the portfolio and 'tau' is the time-to-expiration.
 */
import * as d3 from 'd3';
import {arraySum, dotProduct} from './ArrayHelpers';

export const XDomain = (tickers, instruments, type) => {
  const o = {
      AssetPrices: [],
      taus: [],
    },
    // ~99.7% of outcomes
    volScalar = 3;

  let vol;

  if (
    Object.keys(tickers['options']).filter(el => el.includes(type)).length > 0
  ) {
    // options data is available, use 'Vega-Weighted Implied Volatility'
    o['Vegas'] = [];
    o['ImpVols'] = [];

    // aggregate data
    for (let t in tickers['options']) {
      if (
        tickers['options'].hasOwnProperty(t) &&
        tickers['options'][t]['instrument_name'].includes(type)
      ) {
        let tau =
          (instruments['options'][t]['expiration_timestamp'] -
            new Date().getTime()) /
          (24 * 60 * 60 * 1000);

        o['AssetPrices'].push(tickers['options'][t]['underlying_price']);
        o['taus'].push(tau);

        // use a version of Nassim Taleb's 'True Vega', which compensates for sensitivity to realized volatility across the term structure
        o['Vegas'].push(
          Math.sqrt(30 / tau) * tickers['options'][t]['greeks']['vega'],
        );
        o['ImpVols'].push(tickers['options'][t]['mark_iv'] / 100);
      }
    }

    // annualized estimate of portfolio volatility
    vol =
      arraySum(o['Vegas']) !== 0
        ? dotProduct(o['Vegas'], o['ImpVols']) / arraySum(o['Vegas'])
        : 0.5;
  } else {
    // there aren't any options available
    for (let t in tickers['futures']) {
      if (
        tickers['futures'].hasOwnProperty(t) &&
        tickers['futures'][t]['instrument_name'].includes(type)
      ) {
        o['AssetPrices'].push(tickers['futures'][t]['mark_price']);

        if (t.split('-')[1] !== 'PERPETUAL') {
          o['taus'].push(
            (instruments['futures'][t]['expiration_timestamp'] -
              new Date().getTime()) /
              (24 * 60 * 60 * 1000),
          );
        } else {
          // assume a one-year trade horizon
          o['taus'].push(365);
        }
      }
    }

    // assume annualized volatility of 50%
    vol = 0.5;
  }

  // scale using the nearest expiration
  vol *= Math.sqrt(Math.min(...o['taus']) / 365);

  const AvgPrice = arraySum(o['AssetPrices']) / o['AssetPrices'].length;

  let min = (-volScalar * vol + 1) * AvgPrice;
  min = min > 1 ? min : 1;

  const max = (volScalar * vol + 1) * AvgPrice;

  return [min, max];
};

export const YDomain = (isUSD, portfolio, data) => {
  const globalExtentY = (() => {
    let extent = d3.extent(
      d3.merge([data[0].map(d => d.y), data[1].map(d => d.y)]),
    );

    // cap infinite-BTC scenarios
    if (!isUSD) {
      /**
       * Method #1 (breaks with certain credit spreads)
       */
      // let putsNetSize = 0, futuresNetSize = 0

      // if (portfolio.options) {
      //   putsNetSize = portfolio.options
      //     .filter(el => el.instrument.split('-')[3] === 'P')
      //     .reduce((acc, cur) => +cur.size + acc, 0)
      // }

      // if (portfolio.futures) {
      //   futuresNetSize = portfolio.futures
      //     .reduce((acc, cur) => (+cur.size / +cur.avg_price) + acc, 0)
      // }

      // let altExtent0 = 0, altExtent1 = 0

      // if (Math.sign(putsNetSize) === -1) { altExtent0 += putsNetSize } else { altExtent1 += putsNetSize }
      // if (Math.sign(futuresNetSize) === -1) { altExtent1 -= futuresNetSize } else { altExtent0 -= futuresNetSize }

      /**
       * Method #2
       */
      let altExtent0 = 0,
        altExtent1 = 0;

      if (portfolio.options) {
        altExtent0 += portfolio.options
          .filter(
            el =>
              el.instrument.split('-')[3] === 'P' && Math.sign(el.size) === -1,
          )
          .reduce((acc, cur) => +cur.size + acc, 0);

        altExtent1 += portfolio.options
          .filter(
            el =>
              el.instrument.split('-')[3] === 'P' && Math.sign(el.size) === 1,
          )
          .reduce((acc, cur) => +cur.size + acc, 0);
      }

      if (portfolio.futures) {
        altExtent0 -= portfolio.futures
          .filter(el => Math.sign(el.size) === 1)
          .reduce((acc, cur) => +cur.size / +cur.avg_price + acc, 0);

        altExtent1 -= portfolio.futures
          .filter(el => Math.sign(el.size) === -1)
          .reduce((acc, cur) => +cur.size / +cur.avg_price + acc, 0);
      }

      if (altExtent0) {
        extent[0] = extent[0] > altExtent0 ? extent[0] : altExtent0;
      }
      if (altExtent1) {
        extent[1] = extent[1] < altExtent1 ? extent[1] : altExtent1;
      }
    }

    // initial minimum scale (should USD scalar == BTC scalar * Deribit Index?)
    const scalar = !isUSD ? 5e-2 : 5e2;
    extent[0] = extent[0] < -scalar ? extent[0] : -scalar;
    extent[1] = extent[1] > scalar ? extent[1] : scalar;

    return extent;
  })();

  const globalRangeY = globalExtentY[1] - globalExtentY[0];

  return [
    globalExtentY[0] - globalRangeY * 0.05,
    globalExtentY[1] + globalRangeY * 0.05,
  ];
};
