import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from '@material-ui/styles';
import React, {useCallback, useEffect} from 'react';
import {BroadcastChannel} from 'broadcast-channel';

const tabGUID = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: 'var(--elevated-3)',
  },
  dialogContent: {
    backgroundColor: 'var(--elevated-6)',
    color: 'var(--color-text-main)',
  },
  dialogContentText: {
    color: 'var(--color-text-main)',
  },
  button: {
    color: 'var(--deribit-green)',
  },
}));

const CrossTabGate = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bc] = React.useState(new BroadcastChannel('crossTabCheck'));
  const [guid] = React.useState(tabGUID());

  useEffect(() => {
    bc.postMessage(`${guid}-init`);
    return () => bc.close();
  }, []);

  useEffect(() => {
    bc.onmessage = e => {
      console.log(e);
      const [client, action] = e.split('-');

      // If we receive an init message
      // we have multiple tabs opened so respond accordingly
      if (action === 'init' && !open) {
        bc.postMessage(`${client}-multiple_tabs_opened`);
      }

      if (e === `${guid}-multiple_tabs_opened` || action === 'use_here') {
        setOpen(true);
      }
    };
  }, [open]);

  const useHereClickHandler = useCallback(() => {
    setOpen(false);
    bc.postMessage(`${guid}-use_here`);
  }, []);

  const dialog = (
    <Dialog open={open} className={classes.dialog}>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          Deribit Position Builder is already open in another browser. Press
          &quot;Use Here&quot; to use Deribit Position Builder in this browser.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button onClick={useHereClickHandler} className={classes.button}>
          Use Here
        </Button>
      </DialogActions>
    </Dialog>
  );

  return open ? dialog : props.children;
};

export default CrossTabGate;
