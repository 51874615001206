import shortid from 'shortid';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  client_id: shortid.generate(),
  error: null,
  username: '',
  system_name: '',
  account_type: '',
};

const authFail = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const fetchAccountSummarySuccess = (state, action) => {
  return {
    ...state,
    username: action.response[0].username,
    system_name: action.response[0].system_name,
    account_type: action.response[0].type,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.FETCH_ACCOUNT_SUMMARY_SUCCESS:
      return fetchAccountSummarySuccess(state, action);
    case actionTypes.SWITCH_ENVIRONMENT:
      return initialState;
    case actionTypes.DEAUTH:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
