import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: {},
};

const setIVSkewsGraphData = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    [action.payload.name[1]]: {
      time: action.payload.time,
      future: action.payload.future,
      rate: action.payload.rate,
      points: action.payload.points,
    },
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IVSKEWS_GRAPH_DATA:
      return setIVSkewsGraphData(state, action);
    default:
      return state;
  }
};

export default reducer;
