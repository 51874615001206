import {applyMiddleware, compose, createStore} from 'redux';
import {persistCombineReducers, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as middlewares from './middlewares';
import persistCrosstab from './persistCrosstab.js';
import reducers from './reducers';
import {createHashHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [],
};

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

export const history = createHashHistory({hashType: 'slash'});

// Create store
const store = createStore(
  persistCombineReducers(persistConfig, {
    ...reducers,
    router: connectRouter(history),
  }),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      // middlewares.debugCountActionsMiddleware,
      middlewares.throttleMiddleware,
      middlewares.debounceMiddleware,
      middlewares.websocketMiddleware,
      thunk,
    ),
  ),
);

// Create persistor
export const persistor = persistStore(store);

// Persist store across tabs
persistCrosstab(store, persistConfig, {
  whitelist: [],
});

export default store;
