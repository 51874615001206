/**
 *  Maintenance margin formulas can be found at 'https://www.deribit.com/pages/docs'
 */
export const calculateOptionsMargin = (instrument, side, size, avgPrice) => {
  const isCall = instrument.split('-')[3] === 'C';

  if (side === 'long') {
    return (+0).toFixed(8);
  } else {
    // side === 'short'
    if (isCall) {
      return (+(0.075 + avgPrice) * Math.abs(size)).toFixed(8);
    } else {
      // isPut
      return (
        +(Math.max(0.075, 0.075 * avgPrice) + avgPrice) * Math.abs(size)
      ).toFixed(8);
    }
  }
};

export const calculateFuturesMargin = (instrument, size, avgPrice) => {
  const currency = instrument.split('-')[0],
    numTokens = Math.abs(size) / avgPrice,
    baseBTC = instrument.split('-')[1] === 'PERPETUAL' ? 0.00575 : 0.0055;

  if (currency === 'BTC') {
    return (+(baseBTC + numTokens * 0.00005) * numTokens).toFixed(8);
  } else {
    // currency === 'ETH'
    return (+(0.01 + numTokens * 0.000001) * numTokens).toFixed(8);
  }
};
