import * as d3 from 'd3';
import React, {useEffect, useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import {breakEvens, PLSumOfPositions} from '../../../utils/Graph';

const Overlays = props => {
  const {
    plotWidth,
    plotHeight,
    data,
    xDomain,
    yDomain,
    closestExpiry,
    daysFromToday,
    portfolio,
    currency,
    tickers,
    instruments,
    indices,
    isUSD,
    PNLNormalized,
    lastNow,
    onSetXDomain,
    onSetYDomain,
    onSetPlotDragged,
  } = props;

  useEffect(() => {
    const preventDefault = e => {
      e.preventDefault();
    };

    document
      .getElementById('DualLineChart')
      .addEventListener('gesturestart', preventDefault, {passive: false});
    document
      .getElementById('DualLineChart')
      .addEventListener('touchmove', preventDefault, {passive: false});

    redrawY();
    redrawX();

    return () => {
      document
        .getElementById('DualLineChart')
        .removeEventListener('gesturestart', preventDefault, {passive: false});
      document
        .getElementById('DualLineChart')
        .removeEventListener('touchmove', preventDefault, {passive: false});
    };
  }, []);

  const options = portfolio.options.filter(
      el => el.currency === currency && el.enabled,
    ),
    futures = portfolio.futures.filter(
      el => el.currency === currency && el.enabled,
    );

  const breakEvenValues = breakEvens(data);

  const overlayStyles = {
    fill: 'none',
    pointerEvents: options.length + futures.length > 0 ? 'all' : 'none',
    cursor: 'pointer',
  };

  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(xDomain)
        .range([0, plotWidth]),
    [xDomain, plotWidth],
  );

  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(yDomain)
        .range([plotHeight, 0]),
    [yDomain, plotHeight],
  );

  let inXDataSet,
    isPlotDragged,
    isXAxisDragged,
    isYAxisDragged,
    clickedPoint,
    downX,
    downY,
    wheelDelta,
    tempDistance,
    tempXDomain = xDomain,
    tempYDomain = yDomain,
    tempXScale = xScale,
    tempYScale = yScale,
    tempData = data;

  /*
   ** Draw
   */
  const assignTempData = useCallback(() => {
    return PLSumOfPositions(
      closestExpiry,
      daysFromToday,
      {
        options,
        futures,
      },
      currency,
      indices,
      tickers,
      instruments,
      d3.range(
        ...tempXDomain,
        (tempXDomain[1] - tempXDomain[0]) /
          Math.max(300, Math.ceil(500 - (options.length + futures.length) * 2)),
      ),
      isUSD,
      PNLNormalized,
      lastNow,
    );
  });

  const redrawX = useCallback(() => {
    const index =
      currency.toLowerCase() + '_usd' in indices
        ? indices[currency.toLowerCase() + '_usd']['price']
        : 0;

    d3.select('.x-axis').call(
      d3
        .axisBottom(tempXScale)
        .ticks(8)
        .tickSize(-plotHeight)
        .tickPadding(8)
        .tickFormat(d3.format('.3s')),
    );

    d3.select('.DeribitIndexPrice')
      .attr('x1', tempXScale(index))
      .attr('x2', tempXScale(index));

    d3.select('.DeribitIndexPriceText').attr('x', tempXScale(index) + 7);

    breakEvenValues[0].forEach((x, i) => {
      d3.select('.expiryBE' + (i + 1))
        .attr('x1', tempXScale(x))
        .attr('x2', tempXScale(x));
    });

    breakEvenValues[1].forEach((x, i) => {
      d3.select('.todayBE' + (i + 1))
        .attr('x1', tempXScale(x))
        .attr('x2', tempXScale(x));
    });
  });

  const redrawY = useCallback(() => {
    d3.select('.y-axis').call(
      d3
        .axisRight(tempYScale)
        .ticks(10)
        .tickSize(-plotWidth)
        .tickPadding(7)
        .tickFormat(d3.format('.2s')),
    );

    d3.select('.negative-region')
      .attr(
        'height',
        tempYScale(0) < plotHeight ? plotHeight - tempYScale(0) : 0,
      )
      .attr('transform', `translate(0, ${tempYScale(0)})`);

    d3.select('.line0')
      .attr('y1', tempYScale(0))
      .attr('y2', tempYScale(0));

    breakEvenValues[0].forEach((_, i) => {
      d3.select('.expiryBE' + (i + 1)).style(
        'transform',
        `translate(0px, ${tempYScale(0) - plotHeight * 0.025}px)`,
      );
    });

    breakEvenValues[1].forEach((_, i) => {
      d3.select('.todayBE' + (i + 1)).style(
        'transform',
        `translate(0px, ${tempYScale(0) - plotHeight * 0.025}px)`,
      );
    });
  });

  const redrawDataLines = useCallback(() => {
    let tempLine = d3
      .line()
      .x(d => tempXScale(d.x))
      .y(d => tempYScale(d.y));

    d3.select('.line1').attr('d', tempLine(tempData[0]));
    d3.select('.line2').attr('d', tempLine(tempData[1]));
  });

  const redrawTooltip = useCallback(mouse => {
    mouse = mouse || window.mousePoint;

    if (mouse && tempData[0][0]) {
      let x0 = xScale.invert(mouse[0]);
      inXDataSet =
        tempData[0][0]['x'] <= x0 &&
        x0 <= tempData[0][tempData[0].length - 1]['x'];

      if (inXDataSet) {
        let i = d3.bisector(d => d.x).left(tempData[0], x0, 1),
          d0 = tempData[0][i - 1],
          d1 = tempData[0][i],
          nextX = x0 - d0.x > d1.x - x0,
          n = nextX ? i : i - 1,
          d = nextX ? d1 : d0;

        let moveToLine1Point = `translate(${xScale(d.x)}, ${yScale(
            tempData[0][n].y,
          )})`,
          moveToLine2Point = `translate(${xScale(d.x)}, ${yScale(
            tempData[1][n].y,
          )})`;

        d3.select('.lineBisectX').attr(
          'transform',
          `translate(${xScale(d.x)}, 0)`,
        );
        d3.select('.lineBisectY1').attr(
          'transform',
          `translate(0, ${yScale(tempData[0][n].y)})`,
        );
        d3.select('.lineBisectY2').attr(
          'transform',
          `translate(0, ${yScale(tempData[1][n].y)})`,
        );

        d3.select('.circle-line1').attr('transform', moveToLine1Point);
        d3.select('.circle-line2').attr('transform', moveToLine2Point);

        d3.select('.text-line1').attr('transform', moveToLine1Point);
        d3.select('.text-line1-x').text(`${d.x.toFixed(0)}`);
        d3.select('.text-line1-y').text(
          isUSD ? tempData[0][n].y.toFixed(2) : tempData[0][n].y.toFixed(4),
        );

        d3.select('.text-line2').attr('transform', moveToLine2Point);
        d3.select('.text-line2-x').text(`${d.x.toFixed(0)}`);
        d3.select('.text-line2-y').text(
          isUSD ? tempData[1][n].y.toFixed(2) : tempData[1][n].y.toFixed(4),
        );

        // reveal tooltip
        d3.select('.focus').style('display', null);
      } else {
        // !inXDataSet
        // hide tooltip
        d3.select('.focus').style('display', 'none');
      }
    }
  });

  /*
   ** Transform
   */
  const translateXY = useCallback(() => {
    let dX = tempXScale.invert(downX) - tempXScale.invert(window.mousePoint[0]),
      dY = tempYScale.invert(downY) - tempYScale.invert(window.mousePoint[1]);

    tempXDomain = [xDomain[0] + dX > 1 ? xDomain[0] + dX : 1, xDomain[1] + dX];

    tempYDomain = [yDomain[0] + dY, yDomain[1] + dY];

    tempXScale.domain(tempXDomain);
    tempYScale.domain(tempYDomain);
    tempData = assignTempData();

    redrawDataLines();
    redrawX();
    redrawY();
  });

  const scaleX = useCallback(() => {
    let cX = tempXScale.invert(downX) / tempXScale.invert(window.mousePoint[0]),
      eX = xDomain[1] - xDomain[0];

    tempXDomain = [xDomain[0], xDomain[0] + eX * cX];

    tempXScale.domain(tempXDomain);
    tempData = assignTempData();

    redrawDataLines();
    redrawX();
  });

  const scaleY = useCallback(() => {
    let cY =
        (tempYScale.invert(downY) - yDomain[0]) /
        (tempYScale.invert(window.mousePoint[1]) - yDomain[0]),
      eY = yDomain[1] - yDomain[0];

    tempYDomain = [yDomain[0], yDomain[0] + eY * cY];

    tempYScale.domain(tempYDomain);

    redrawDataLines();
    redrawY();
  });

  const zoomXY = useCallback(() => {
    const mX = window.mousePoint[0],
      mY = window.mousePoint[1],
      sX = tempXScale.invert(mX),
      sY = tempYScale.invert(mY);

    // Scale
    const wheelSign = Math.sign(wheelDelta),
      percentage = 0.02;

    let eX = tempXDomain[1] - tempXDomain[0],
      eY = tempYDomain[1] - tempYDomain[0];

    tempXDomain = [
      tempXDomain[0] + wheelSign * (eX * percentage),
      tempXDomain[1] - wheelSign * (eX * percentage),
    ];

    tempYDomain = [
      tempYDomain[0] + wheelSign * (eY * percentage),
      tempYDomain[1] - wheelSign * (eY * percentage),
    ];

    tempXScale.domain(tempXDomain);
    tempYScale.domain(tempYDomain);

    // Translate
    let dX = sX - tempXScale.invert(mX),
      dY = sY - tempYScale.invert(mY);

    tempXDomain = [
      tempXDomain[0] + dX > 1 ? tempXDomain[0] + dX : 1,
      tempXDomain[1] + dX,
    ];

    tempYDomain = [tempYDomain[0] + dY, tempYDomain[1] + dY];

    tempXScale.domain(tempXDomain);
    tempYScale.domain(tempYDomain);
  });

  const mousePinchEvent = useCallback(e => {
    const [ax, ay] = d3.clientPoint(e.touches['0'].target, e.touches['0']);
    const [bx, by] = d3.clientPoint(e.touches['1'].target, e.touches['1']);
    window.mousePoint = [(ax + bx) / 2, (ay + by) / 2];

    const d = Math.sqrt(Math.pow(ay - by, 2) + Math.pow(ax - bx, 2));
    wheelDelta = 1 * (tempDistance > d) ? -1 : 1;
    tempDistance = d;

    zoomXY();
    tempData = assignTempData();

    redrawDataLines();
    redrawX();
    redrawY();
  });

  /*
   ** Mouse Events
   */
  const mouseMovePlot = useCallback(e => {
    d3.select('.focus').style('display', 'none');

    if (e.changedTouches) {
      if (e.touches.length === 2) {
        return mousePinchEvent(e);
      }

      e = e.changedTouches[0];
    }

    const [x, y] = d3.clientPoint(e.target, e);
    window.mousePoint = [Math.round(x), Math.round(y)];

    if (!isPlotDragged) {
      redrawTooltip();
    } else {
      translateXY();
    }
  });

  const mouseOutPlot = useCallback(() => {
    d3.select('.focus').style('display', 'none');
    window.mousePoint = null;

    if (isPlotDragged) {
      // reset clickedPoint
      downX = Math.NaN;
      downY = Math.NaN;

      // assign new domains
      onSetXDomain(tempXDomain);
      onSetYDomain(tempYDomain);

      isPlotDragged = false;
      onSetPlotDragged(false);
    }
  });

  const mouseDownPlot = useCallback(e => {
    if (e.changedTouches) {
      if (e.touches.length === 2) {
        const [ax, ay] = d3.clientPoint(e.touches['0'].target, e.touches['0']);
        const [bx, by] = d3.clientPoint(e.touches['1'].target, e.touches['1']);
        downX = (ax + bx) / 2;
        downY = (ay + by) / 2;

        tempDistance = Math.sqrt(Math.pow(ay - by, 2) + Math.pow(ax - bx, 2));
      } else {
        e = e.changedTouches[0];
        clickedPoint = d3.clientPoint(e.target, e);
        downX = clickedPoint[0];
        downY = clickedPoint[1];
      }
    } else {
      clickedPoint = d3.clientPoint(e.target, e);
      downX = clickedPoint[0];
      downY = clickedPoint[1];
    }

    isPlotDragged = true;
    onSetPlotDragged(true);
  });

  const mouseUpPlot = useCallback(() => {
    if (inXDataSet) {
      // reveal tooltip after short delay to prevent flicker
      setTimeout(() => {
        d3.select('.focus').style('display', null);
      }, 25);
    }

    if (isPlotDragged) {
      downX = Math.NaN;
      downY = Math.NaN;

      onSetXDomain(tempXDomain);
      onSetYDomain(tempYDomain);

      isPlotDragged = false;
      onSetPlotDragged(false);
    }
  });

  const mouseWheelPlot = useCallback(e => {
    d3.select('.focus').style('display', 'none');

    window.mousePoint = d3.clientPoint(e.target, e);
    wheelDelta = -e.deltaY;
    zoomXY();

    onSetXDomain(tempXDomain);
    onSetYDomain(tempYDomain);
  });

  const mouseMoveXAxis = useCallback(e => {
    d3.selectAll('.x-axis .tick text').style('fill', 'var(--color-text-main)');
    d3.select('.x-axis-label').style('fill', 'var(--color-text-main)');

    if (e.changedTouches) {
      e = e.changedTouches[0];
    }

    if (isXAxisDragged) {
      window.mousePoint = d3.clientPoint(e.target, e);
      scaleX();
    }
  });

  const mouseLeaveXAxis = useCallback(() => {
    d3.selectAll('.x-axis .tick text').style('fill', 'var(--mute-1)');
    d3.select('.x-axis-label').style('fill', 'var(--mute-1)');
  });

  const mouseOutOrUpXAxis = useCallback(() => {
    if (isXAxisDragged) {
      window.mousePoint = null;
      downX = Math.NaN;

      onSetXDomain(tempXDomain);

      isXAxisDragged = false;
      onSetPlotDragged(false);
    }
  });

  const mouseDownXAxis = useCallback(e => {
    if (e.changedTouches) {
      d3.select('.focus').style('display', 'none');

      d3.selectAll('.x-axis .tick text').style(
        'fill',
        'var(--color-text-main)',
      );
      d3.select('.x-axis-label').style('fill', 'var(--color-text-main)');

      e = e.changedTouches[0];
    }

    clickedPoint = d3.clientPoint(e.target, e);
    downX = clickedPoint[0];

    isXAxisDragged = true;
    onSetPlotDragged(true);
  });

  const mouseMoveYAxis = useCallback(e => {
    d3.selectAll('.y-axis .tick text').style('fill', 'var(--color-text-main)');
    d3.select('.y-axis-label').style('fill', 'var(--color-text-main)');

    if (e.changedTouches) {
      e = e.changedTouches[0];
    }

    if (isYAxisDragged) {
      window.mousePoint = d3.clientPoint(e.target, e);
      scaleY();
    }
  });

  const mouseLeaveYAxis = useCallback(() => {
    d3.selectAll('.y-axis .tick text').style('fill', 'var(--mute-1)');
    d3.select('.y-axis-label').style('fill', 'var(--mute-1)');
  });

  const mouseOutOrUpYAxis = useCallback(() => {
    if (isYAxisDragged) {
      window.mousePoint = null;
      downY = Math.NaN;

      onSetYDomain(tempYDomain);

      isYAxisDragged = false;
      onSetPlotDragged(false);
    }
  });

  const mouseDownYAxis = useCallback(e => {
    if (e.changedTouches) {
      d3.select('.focus').style('display', 'none');

      d3.selectAll('.y-axis .tick text').style(
        'fill',
        'var(--color-text-main)',
      );
      d3.select('.y-axis-label').style('fill', 'var(--color-text-main)');

      e = e.changedTouches[0];
    }

    clickedPoint = d3.clientPoint(e.target, e);
    downY = clickedPoint[1];

    isYAxisDragged = true;
    onSetPlotDragged(true);
  });

  // update tooltip whenever component renders
  redrawTooltip();

  return (
    <React.Fragment>
      <rect
        className="plot-overlay"
        width={plotWidth}
        height={plotHeight}
        style={overlayStyles}
        onMouseMove={mouseMovePlot}
        onMouseOut={mouseOutPlot}
        onMouseDown={mouseDownPlot}
        onMouseUp={mouseUpPlot}
        onWheel={mouseWheelPlot}
        onTouchStart={mouseDownPlot}
        onTouchEnd={mouseUpPlot}
        onTouchMove={mouseMovePlot}
      />

      <rect
        className="x-axis-overlay"
        width={plotWidth}
        height={40}
        transform={`translate(0, ${plotHeight})`}
        style={overlayStyles}
        onMouseMove={mouseMoveXAxis}
        onMouseOut={mouseOutOrUpXAxis}
        onMouseLeave={mouseLeaveXAxis}
        onMouseDown={mouseDownXAxis}
        onMouseUp={mouseOutOrUpXAxis}
        onTouchStart={mouseDownXAxis}
        onTouchEnd={() => {
          mouseOutOrUpXAxis();
          mouseLeaveXAxis();
        }}
        onTouchMove={mouseMoveXAxis}
      />

      <rect
        className="y-axis-overlay"
        width={70}
        height={plotHeight}
        transform={`translate(${plotWidth}, 0)`}
        style={overlayStyles}
        onMouseMove={mouseMoveYAxis}
        onMouseOut={mouseOutOrUpYAxis}
        onMouseLeave={mouseLeaveYAxis}
        onMouseDown={mouseDownYAxis}
        onMouseUp={mouseOutOrUpYAxis}
        onTouchStart={mouseDownYAxis}
        onTouchEnd={() => {
          mouseOutOrUpYAxis();
          mouseLeaveYAxis();
        }}
        onTouchMove={mouseMoveYAxis}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    data: state.graph.data,
    xDomain: state.graph.xDomain,
    yDomain: state.graph.yDomain,
    closestExpiry: state.graph.closestExpiry,
    daysFromToday: state.graph.daysFromToday,
    portfolio: state.portfolio.positions,
    currency: state.settings.currency,
    tickers: state.instrument.tickers,
    instruments: state.instrument.instruments,
    indices: state.instrument.indices,
    isUSD: state.settings.PNLCurrencyInUSD,
    PNLNormalized: state.settings.PNLNormalized,
    lastNow: state.settings.now,
    plotDragged: state.graph.plotDragged,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetXDomain: domain => dispatch(actions.setXDomain(domain)),
    onSetYDomain: domain => dispatch(actions.setYDomain(domain)),
    onSetPlotDragged: bool => dispatch(actions.setPlotDragged(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: (next, prev) => {
    return (
      prev.data === next.data &&
      prev.xDomain === next.xDomain &&
      prev.yDomain === next.yDomain &&
      prev.closestExpiry === next.closestExpiry &&
      prev.daysFromToday === next.daysFromToday &&
      prev.portfolio === next.portfolio &&
      prev.currency === next.currency &&
      // prev.tickers === next.tickers &&
      // prev.instruments === next.instruments &&
      // prev.indices === next.indices &&
      prev.isUSD === next.isUSD &&
      prev.PNLNormalized === next.PNLNormalized &&
      // prev.lastNow === next.lastNow &&          <--------------------- Should this be active?
      prev.plotDragged === next.plotDragged // &&
      // prev.xScale === next.xScale &&
      // prev.yScale === next.yScale
    );
  },
})(
  React.memo(Overlays, (prevProps, nextProps) => {
    return prevProps.plotDragged !== nextProps.plotDragged;
  }),
);
