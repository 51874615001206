const _getSessionsData = storageKey => {
  return JSON.parse(localStorage.getItem(storageKey) || '{}');
};

export const setSessionData = (storageKey, sessionName, sessionData) => {
  const sessionsData = _getSessionsData(storageKey);
  sessionsData[sessionName] = sessionData;
  localStorage.setItem(storageKey, JSON.stringify(sessionsData));
};

export const removeSessionData = (storageKey, sessionName) => {
  const sessionsData = _getSessionsData(storageKey);
  delete sessionsData[sessionName];
  localStorage.setItem(storageKey, JSON.stringify(sessionsData));
};

export const removeExpiredSessionsData = storageKey => {
  const sessionsData = _getSessionsData(storageKey);

  Object.keys(sessionsData).forEach(sessionName => {
    const currentSession = sessionsData[sessionName];
    const refreshTokenExpirationTimestamp = currentSession.refresh_token.split(
      '.',
    )[0];
    if (refreshTokenExpirationTimestamp < Date.now()) {
      removeSessionData(sessionName);
    }
  });
};

export const getLatestSessionData = storageKey => {
  const sessionsData = _getSessionsData(storageKey);

  let latestExpirationTimestamp = 0;
  let latestSessionData;

  Object.keys(sessionsData).forEach(sessionName => {
    const currentSession = sessionsData[sessionName];
    const refreshTokenExpirationTimestamp = currentSession.refresh_token.split(
      '.',
    )[0];
    if (latestExpirationTimestamp < refreshTokenExpirationTimestamp) {
      latestSessionData = currentSession;
      latestExpirationTimestamp = refreshTokenExpirationTimestamp;
    }
  });

  return latestSessionData;
};

export const removeSessionsData = storageKey => {
  localStorage.removeItem(storageKey);
};
