import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/styles';
import Menu from 'material-ui-popup-state/HoverMenu';
import * as React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Input} from '../../components/UI/Input/Input';
import * as actions from '../../store/actions';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
  button: {
    width: 21,
    height: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    boxShadow: 'none',
    border: 'none',
    margin: 6,
    marginRight: 10,
    padding: 8,
  },
  menu: {
    borderBottom: `1px solid var(--light-component-border)`,
  },
  submenu: {
    overflow: 'auto',
    maxHeight: 320,
    borderBottom: `1px solid var(--light-component-border)`,
  },
}));

const PortfolioMenu = props => {
  const classes = useStyles();

  const [newPortfolio, setNewPortfolio] = React.useState(false);
  const [name, setName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{height: '100%'}}>
      <Dialog open={newPortfolio} onClose={() => setNewPortfolio(false)}>
        <DialogTitle>Create portfolio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new portfolio for account {props.username}
          </DialogContentText>
          <DialogContentText>
            <Input
              value={name}
              type="text"
              onChange={e => setName(e.target.value)}
              label="Name"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewPortfolio(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (!props.portfolios.map(el => el.name).includes(name)) {
                props.onNewPortfolio(props.username, name);
                setNewPortfolio(false);
              } else {
                toast.error(
                  'Portfolioname is already in use for logged-in user.',
                );
              }
            }}
            color="primary">
            Create portfolio
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        className={classes.iconButton}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <div className={classes.button}>
          <FontAwesomeIcon size="xs" icon={['fas', 'layer-group']} />
        </div>
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          dense
          disableRipple
          className={classes.menu}
          onClick={() => {
            setNewPortfolio(true);
            handleClose();
          }}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>New portfolio</ListItemText>
        </MenuItem>
        {props.portfolios
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          )
          .map(portfolio => (
            <MenuItem
              dense
              disableRipple
              key={portfolio.key}
              onClick={() => {
                props.onInitPortfolio(portfolio.username, portfolio.name);
                handleClose();
              }}>
              <Grid container item>
                <Grid item>
                  <Radio
                    size="small"
                    classes={{colorPrimary: classes.Radio}}
                    checked={props.portfolioName === portfolio.name}
                    color="primary"
                  />
                </Grid>
                <ListItemText>{portfolio.name}</ListItemText>
              </Grid>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    portfolios: state.portfolio.portfolios.reduce(
      (acc, {username, name, key}) => {
        if (username === state.auth.username) {
          acc.push({username, name, key});
        }
        return acc;
      },
      [],
    ),
    portfolioName: state.portfolio.positions.name,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitPortfolio: (username, name) =>
      dispatch(actions.initPortfolio(username, name)),
    onNewPortfolio: (username, name) =>
      dispatch(actions.newPortfolio(username, name, [], [])),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: (next, prev) => {
    return (
      next.username === prev.username &&
      next.portfolioName === prev.portfolioName &&
      JSON.stringify(next.portfolios) === JSON.stringify(prev.portfolios)
    );
  },
})(PortfolioMenu);
