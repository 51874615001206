import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import qs from 'query-string';

const OAuth = ({onAuth, location: {hash}}) => {
  const {access_token, expires_in, refresh_token} = qs.parse(hash);
  if (access_token && expires_in && refresh_token) {
    onAuth({access_token, expires_in, refresh_token});
  }

  return '';
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: response => dispatch(actions.oauthResponse(response)),
  };
};

export default connect(null, mapDispatchToProps)(OAuth);
