import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import deribitBetaLogoBright from '../../assets/Deribit-beta-logo-bright.svg';
import deribitBetaLogoDark from '../../assets/Deribit-beta-logo-dark.svg';
import * as actions from '../../store/actions';
import classes from './DrawerMenu.module.scss';

const DrawerMenu = props => {
  const rows = {
    // "Charts": [
    // 	{
    // 		"name": "Deribit Indexes",
    // 		"icon": ["fad", "chart-line"],
    // 		"url": "/statistics" + currLink + "/deribit-indexes",
    // 		"state": {"chosenComponent": "DeribitIndexes"}
    // 	},
    // 	{
    // 		"name": "Historical Volatility",
    // 		"icon": ["fad", "chart-line"],
    // 		"url": "/statistics" + currLink + "/historical-volatility",
    // 		"state": {"chosenComponent": "HistoricalVolatility"}
    // 	},
    // 	{
    // 		"name": "Insurance Fund",
    // 		"icon": ["fad", "chart-line"],
    // 		"url": "/statistics" + currLink + "/insurance-fund",
    // 		"state": {"chosenComponent": "InsuranceFund"}
    // 	},
    // ],
    // "products": [
    // 	// {"name": "Deribit Education", "icon": ["fad", "spinner"], "external": true},
    // 	// {"name": "Deribit Insights", "icon": ["fad", "spinner"], "external": true},
    // 	// {"name": "Deribit Analytics", "icon": ["fad", "spinner"], "external": true},
    //
    // ],
    Settings: [
      {
        name: `Use ${props.theme === 'dark' ? 'light' : 'dark'} theme`,
        icon: ['fas', 'adjust'],
        action: () =>
          props.onSetTheme(props.theme === 'dark' ? 'light' : 'dark'),
      },
      {
        name: `Reset layout`,
        icon: ['fas', 'th'],
        action: () => props.onResetLayout(),
      },
      {
        name: `Reset app`,
        icon: ['fas', 'exclamation-triangle'],
        action: () => {
          localStorage.clear();
          location.reload();
        },
      },
      // {
      // 	name: `Realtime updates`,
      // 	icon: ["fas", "circle"],
      // 	color: props.realtimeUpdates ? "var(--deribit-bid)" : "var(--deribit-ask)",
      // 	action: () => props.onSetRealtimeUpdates(!props.realtimeUpdates)
      // },
      ...[
        !['pb.deribit.com', 'positionbuilder.deribit.com'].includes(
          window.location.host,
        ) && {
          name: `Production environment`,
          icon: ['fas', 'circle'],
          color: props.testWs ? 'var(--deribit-ask)' : 'var(--deribit-bid)',
          action: () => props.onSwitchEnvironment(),
        },
      ],
      ...[
        props.username && {
          name: `Load portfolio`,
          icon: ['fas', 'download'],
          action: () => props.onLoadPortfolio(),
        },
      ],
      {
        name: `Clear portfolio`,
        icon: ['fas', 'trash'],
        action: () => props.onClearPortfolio(),
      },
      ...[
        props.username && {
          name: `Share portfolio`,
          icon: ['fas', 'share'],
          action: () => props.onSharePortfolio(),
        },
      ],
      // ...[props.username && {
      // 	name: "Log out",
      // 	icon: ["fad", "sign-out-alt"],
      // 	action: logout
      // }],
    ],
    Links: [
      {
        name: 'Deribit',
        icon: ['fas', 'exchange-alt'],
        color: 'var(--color-text-main)',
        external: true,
        url: 'https://deribit.com',
      },
      // {
      // 	"name": "Deribit | Pro",
      // 	"color": "var(--deribit-green)",
      // 	"icon": ["fas", "exchange-alt"],
      // 	"external": true,
      // 	"url": "https://v3test-aws.deribit.com"
      // },
      {
        name: 'Knowledge base',
        icon: ['fas', 'book'],
        external: true,
        url: 'https://www.deribit.com/pages/kb/index',
      },
      {
        name: 'Blog',
        icon: ['fas', 'rss-square'],
        external: true,
        url: 'https://blog.deribit.com/',
      },
    ],
    ' ': [
      {
        name: `Send feedback`,
        icon: ['fas', 'comment-alt-exclamation'],
        external: true,
        url:
          'https://deribit.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000656832',
      },
      ...[
        props.username && {
          name: `Log out`,
          icon: ['fas', 'sign-out-alt'],
          action: () => {
            props.push(`/${props.currency}/${props.username}/default`);
            props.onDeauth();
          },
        },
      ],
    ],
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Grid container className={classes.Logo}>
        <img
          width="96"
          alt="logo"
          src={
            props.theme === 'dark' ? deribitBetaLogoBright : deribitBetaLogoDark
          }
        />
      </Grid>
      <Grid container className={classes.Container}>
        {Object.keys(rows).map((group, i) => (
          <Grid container item xs={12} key={group} onClick={props.onClose}>
            {group && (
              <Grid item xs={12} className={classes.Title}>
                {group}
              </Grid>
            )}
            {rows[group].map(row => {
              if (row) {
                return (
                  <Grid
                    {...(row.action && {onClick: row.action})}
                    container
                    item
                    xs={12}
                    key={row.name}
                    className={classes.MenuItem}
                    component="a"
                    href={row.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Grid item className={classes.Leading}>
                      <FontAwesomeIcon
                        icon={row.icon}
                        {...(row.color && {color: row.color})}
                        size="1x"
                      />
                    </Grid>
                    <Grid item xs>
                      {row.name}
                    </Grid>
                    <Grid item>
                      {row.external && (
                        <FontAwesomeIcon
                          icon={['fas', 'external-link-alt']}
                          size="1x"
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              }
              return null;
            })}
            {Object.keys(rows).length > i + 1 && (
              <Grid item xs={12}>
                <Divider className={classes.Divider} />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

const mapStateToProps = state => {
  return {
    currency: state.settings.currency,
    username: state.auth.username,
    theme: state.settings.theme,
    realtimeUpdates: state.settings.realtimeUpdates,
    testWs: state.websocket.testWs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeauth: () => dispatch(actions.deauth()),
    onSetTheme: theme => dispatch(actions.beforeSetTheme(theme)),
    onSetRealtimeUpdates: bool => dispatch(actions.setRealtimeUpdates(bool)),
    onResetLayout: () => dispatch(actions.resetLayout()),
    onLoadPortfolio: () =>
      dispatch(actions.fetchPositionsFromDeribitWithMissingTickers()),
    onClearPortfolio: () => dispatch(actions.clearPortfolio()),
    onSwitchEnvironment: () => dispatch(actions.switchEnvironment()),
    onSharePortfolio: () => dispatch(actions.savePositionsToAWS()),
    push,
  };
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)),
);
