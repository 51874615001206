import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import classes from './Indices.module.scss';

export const Indices = props => {
  let indices = [];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  });

  if (props.type === 'inline') {
    for (const [key, value] of Object.entries(props.indices).sort((a, b) =>
      a[0].localeCompare(b[0]),
    )) {
      indices.push(
        <Grid
          item
          key={key}
          className={[
            'NavItem',
            value.base === props.currency ? 'NavItem-active' : '',
          ].join(' ')}>
          <Link
            to={`/${value.base}`}
            onClick={() => props.onSetCurrency(value.base)}>
            <div className="NavItem-inner">
              <span>
                <FontAwesomeIcon
                  icon={['fab', value.base === 'BTC' ? 'btc' : 'ethereum']}
                />
                {props.value}&nbsp;
                {value.base}&nbsp;
                {value.price.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </Link>
        </Grid>,
      );
    }
  } else {
    const rows = [];
    for (const [key, value] of Object.entries(props.indices)) {
      rows.push(
        <Link
          key={key}
          to={`/${value.base}`}
          onClick={() => {
            props.onSetCurrency(value.base);
            handleClose();
          }}>
          <Grid
            className={classes.Button}
            container
            justify="flex-start"
            alignItems="center"
            direction="row">
            <Grid item>
              <span className={classes.Icon}>
                <FontAwesomeIcon
                  icon={['fab', value.base === 'BTC' ? 'btc' : 'ethereum']}
                />
              </span>
            </Grid>
            <Grid item>
              <span>{value.base}</span>
            </Grid>
            <Grid item className={classes.ButtonLastGridItem}>
              <span>
                ${' '}
                {value.price.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </Grid>
          </Grid>
        </Link>,
      );
    }

    indices = (
      <div className={['NavItem', classes.NavItem].join(' ')}>
        <div className="NavItem-inner">
          <button
            aria-controls="currency-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.NavItem}>
            <span>
              <FontAwesomeIcon
                icon={['fab', props.currency === 'BTC' ? 'btc' : 'ethereum']}
              />
              {props.value}&nbsp;
              {props.currency}&nbsp;
              <Hidden
                className={classes.NavItemPriceText}
                xsDown
                implementation="css">
                {props.indices.hasOwnProperty(
                  `${props.currency.toLowerCase()}_usd`,
                ) &&
                  props.indices[
                    `${props.currency.toLowerCase()}_usd`
                  ].price.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </Hidden>
            </span>
            <FontAwesomeIcon
              icon={['fas', anchorEl ? 'angle-up' : 'angle-down']}
            />
          </button>
          <Drawer anchor="top" open={Boolean(anchorEl)} onClose={handleClose}>
            {rows}
          </Drawer>
        </div>
      </div>
    );
  }

  return <div className={classes.Indices}>{indices}</div>;
};

const mapDispatchToProps = dispatch => {
  return {
    onSetCurrency: currency => dispatch(actions.setCurrency(currency)),
  };
};

const mapStateToProps = state => {
  return {
    username: state.portfolio.positions.username,
    indices: state.instrument.indices,
    currency: state.settings.currency,
    portfolioName: state.portfolio.positions.name,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Indices),
);
