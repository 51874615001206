const _throttled = {};

const middleware = () => {
  return next => action => {
    const {meta, type, fn} = action;
    const time = meta && meta.throttle;

    if (!time) {
      return next(action);
    }

    if (_throttled[type]) {
      return;
    }

    _throttled[type] = true;
    setTimeout(() => {
      _throttled[type] = false;
    }, time);

    if (fn) {
      return next(fn);
    } else {
      return next(action);
    }
  };
};

export default middleware;
