import {Grid} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {connect} from 'react-redux';
import logoBrightD from '../../assets/logo-bright-d.svg';
import * as actions from '../../store/actions';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import PortfolioMenu from '../PortfolioMenu/PortfolioMenu';
import Stats from '../Stats/Stats';
import UserMenu from '../UserMenu/UserMenu';
import Indices from './Indices/Indices';
import './Nav.scss';

const useStyles = makeStyles(() => ({
  iconButton: {
    marginRight: 8,
    margin: 4,
    padding: 8,
  },
}));

export const Nav = props => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <Paper elevated={1}>
      <Grid container className="Nav" color="default">
        <Grid item md="auto">
          <Grid
            container
            justify="flex-start"
            direction="row"
            alignItems="center">
            <Hidden mdDown>
              <Grid item className="NavLogo">
                <a href="/">
                  <img width="28px" alt="logo" src={logoBrightD} />
                </a>
              </Grid>
            </Hidden>
            <IconButton
              className={classes.iconButton}
              aria-label="delete"
              onClick={() => setOpenDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <DrawerMenu
              open={openDrawer}
              onClose={() => {
                setOpenDrawer(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs md="auto">
          <Grid container justify="center" direction="row" alignItems="center">
            <Hidden smDown>
              <Indices type="inline" />
            </Hidden>
            <Hidden mdUp>
              <Indices type="dropdown" />
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item style={{flexGrow: 1}}>
            <div className="NavFooter">
              <Stats />
            </div>
          </Grid>
        </Hidden>
        <Grid item>
          <Grid
            container
            justify="flex-end"
            direction="row"
            alignItems="center">
            {!props.username ? (
              <>
                <Hidden mdDown>
                  <div className="NavItem NavItem-register">
                    <div className="NavItem-inner">
                      <a
                        rel="noopener noreferrer"
                        href={`https://${
                          props.testWs ? 'test' : 'www'
                        }.deribit.com/login?#/register`}
                        target="_blank">
                        <span>Register</span>
                      </a>
                    </div>
                  </div>
                </Hidden>
                <Grid item>
                  <div className="NavItem">
                    <div className="NavItem-inner" onClick={props.onOAuth}>
                      <span>Log in</span>
                    </div>
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <PortfolioMenu />
                <UserMenu />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    theme: state.settings.theme,
    testWs: state.websocket.testWs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onOAuth: () => dispatch(actions.oauth()),
    onResetLayout: () => dispatch(actions.resetLayout()),
    onClearPortfolio: () => dispatch(actions.clearPortfolio()),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Nav));
