import * as actionTypes from './actionTypes';
import * as actions from '../actions';

export const updatePositionsWithTicker = ticker => {
  return (dispatch, getState) => {
    const {
      instrument: {instruments},
      portfolio: {positions},
    } = getState();

    if (instruments.options[ticker.instrument_name]) {
      dispatch(
        actions.updateOptionUnderlyingTicker(
          ticker.underlying_index,
          ticker.underlying_price,
        ),
      );

      const [cur, exp, strike] = ticker.instrument_name.split('-');
      const putPos = positions.options.find(el =>
        el.instrument.includes(`${cur}-${exp}-${strike}-P`),
      );
      const callPos = positions.options.find(el =>
        el.instrument.includes(`${cur}-${exp}-${strike}-C`),
      );

      if (callPos) {
        dispatch(
          actions.updateOptionPosition(`${cur}-${exp}-${strike}-C`, 'both', {
            iv: ticker.mark_iv,
          }),
        );
      }

      if (putPos) {
        dispatch(
          actions.updateOptionPosition(`${cur}-${exp}-${strike}-P`, 'both', {
            iv: ticker.mark_iv,
          }),
        );
      }
    }

    if (instruments.futures[ticker.instrument_name]) {
      const pos = positions.futures.find(
        el => el.instrument === ticker.instrument_name,
      );
      if (pos && !pos.cur_price_locked) {
        dispatch(
          actions.updateFuturePosition(ticker.instrument_name, 'both', {
            cur_price: ticker.mark_price,
            ...(!pos.avg_price_locked && {avg_price: ticker.mark_price}),
          }),
        );
      }

      dispatch(
        actions.updateOptionUnderlyingTicker(
          ticker.instrument_name,
          ticker.mark_price,
        ),
      );
    }
  };
};

export const updateOptionUnderlyingTicker = (underlying_instrument, ticker) => {
  return {
    type: actionTypes.UPDATE_OPTION_UNDERYLING_TICKER,
    instrument: underlying_instrument,
    ticker: ticker,
  };
};

export const updateSubscriptions = prev_currency => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTIONS,
    prev_currency: prev_currency,
  };
};

export const checkSubscriptions = () => {
  return async (dispatch, getState) => {
    const {
      portfolio: {
        positions: {options, futures, isLive},
      },
      settings: {currency},
      instrument: {tickers, subscriptions},
      auth: {username},
    } = getState();

    let tickerSubscriptions = [...options, ...futures].reduce(
      (acc, {instrument, kind}) => {
        if (instrument.includes(currency)) {
          acc.push(`ticker.${instrument}.100ms`);
          if (kind === 'option') {
            acc.push(
              `ticker.${tickers['options'][instrument]['underlying_index']}.100ms`,
            );
          }
        }
        return acc;
      },
      [],
    );

    tickerSubscriptions.push(`deribit_price_index.btc_usd`);
    tickerSubscriptions.push(`deribit_price_index.eth_usd`);

    // Delete subscriptions that are in current subscriptions but not in new subscriptions
    let toDelete = subscriptions.filter(x => !tickerSubscriptions.includes(x));
    // Add subscriptions that are not in current subscriptions but are in new subscriptions
    let toAdd = Array.from(
      new Set(tickerSubscriptions.filter(x => !subscriptions.includes(x))),
    );

    if (toDelete.length > 0) {
      const result = await dispatch(
        actions.send('public/unsubscribe', {channels: toDelete}),
      );

      if (!result) return;

      await dispatch({
        type: actionTypes.INSTRUMENT_UNSUBSCRIBE_SUCCESS,
        result,
      });
    }

    if (toAdd.length > 0) {
      const result = await dispatch(
        actions.send('public/subscribe', {channels: toAdd}),
      );

      if (!result) return;

      await dispatch({
        type: actionTypes.INSTRUMENT_SUBSCRIBE_SUCCESS,
        result,
      });
    }

    let toDeletePrivate, toAddPrivate;

    if (isLive && username) {
      // User is logged in, add personal portfolio subscriptions
      toDeletePrivate = subscriptions.filter(
        x => x.includes('user.portfolio') && x !== `user.portfolio.${currency}`,
      );

      toAddPrivate = [
        `user.portfolio.${currency}`,
        'user.changes.any.any.100ms',
      ];
    } else {
      // User is not logged in, delete personal portfolio subscriptions if they exist
      toDeletePrivate = subscriptions.filter(
        x => x.includes('user.portfolio') || x === 'user.changes.any.any.100ms',
      );

      toAddPrivate = [];
    }

    if (toDeletePrivate.length > 0) {
      const result = await dispatch(
        actions.send('private/unsubscribe', {channels: toDeletePrivate}),
      );

      await dispatch({
        type: actionTypes.INSTRUMENT_SUBSCRIBE_SUCCESS,
        result,
      });
    }

    if (toAddPrivate.length > 0) {
      const result = await dispatch(
        actions.send('private/subscribe', {channels: toAddPrivate}),
      );

      await dispatch({
        type: actionTypes.INSTRUMENT_SUBSCRIBE_SUCCESS,
        result,
      });
    }
  };
};

export const clearSubscriptions = () => {
  return {
    type: actionTypes.CLEAR_SUBSCRIPTIONS,
  };
};

export const fetchInstruments = () => {
  return async dispatch => {
    try {
      dispatch({type: actionTypes.FETCH_INSTRUMENTS_REQUEST});
      const calls = [
        {currency: 'BTC', kind: 'option'},
        {currency: 'BTC', kind: 'future'},
        {currency: 'ETH', kind: 'option'},
        {currency: 'ETH', kind: 'future'},
      ];

      const responses = await Promise.all(
        calls.map(call =>
          dispatch(actions.send('public/get_instruments', call)),
        ),
      );

      return dispatch({
        type: actionTypes.FETCH_INSTRUMENTS_SUCCESS,
        response: responses.flat(),
      });
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

export const fetchTickers = instruments => {
  return async dispatch => {
    try {
      dispatch({type: actionTypes.FETCH_TICKERS_REQUEST});

      const responses = await Promise.all(
        instruments.map(instrument_name =>
          dispatch(actions.send('public/ticker', {instrument_name})),
        ),
      );

      await dispatch({
        type: actionTypes.FETCH_TICKERS_SUCCESS,
        response: responses.flat(),
      });

      return dispatch(actions.updatePositionsWithTicker(responses.flat()));
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

export const fetchIndices = currencies => {
  return async dispatch => {
    try {
      dispatch({type: actionTypes.FETCH_INDICES_REQUEST});

      const responses = await Promise.all(
        currencies.map(currency =>
          dispatch(actions.send('public/get_index', {currency})),
        ),
      );

      return dispatch({
        type: actionTypes.FETCH_INDICES_SUCCESS,
        response: responses.flat(),
      });
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};
