const errorsDictionary = {
  10000: 'Authorization issue, invalid or absent signature etc.',
  10001: 'Some general failure, no public information available.',
  10002: 'Order quantity is too low.',
  10003: 'Rejection, order overlap is found and self-trading is not enabled.',
  10004: "Attempt to operate with order that can't be found by specified id.",
  10005: 'Price is too low, `<Limit>` defines current limit for the operation.',
  10006: 'Price is too low for current index, `<Limit>` defines current bottom limit for the operation.',
  10007: 'Price is too high, `<Limit>` defines current up limit for the operation.',
  10008: 'Price is too high for current index, `<Limit>` defines current up limit for the operation.',
  10009: 'Account has not enough funds for the operation.',
  10010: 'Attempt of doing something with closed order.',
  10011: 'This price is not allowed for some reason.',
  10012: 'Operation for instrument which order book had been closed.',
  10013: 'Total limit of open orders has been exceeded, it is applicable for PME users.',
  10014: "Limit of count of futures' open orders has been exceeded, it is applicable for PME users.",
  10015: "Limit of count of options' open orders has been exceeded, it is applicable for PME users.",
  10016: 'Limit of size for futures has been exceeded, it is applicable for PME users.',
  10017: 'Limit of size for options has been exceeded, it is applicable for PME users.',
  10018: 'Limit of size for futures has been exceeded, it is applicable for non-PME users.',
  10019: 'Trading is temporary locked by admin.',
  10020: 'Instrument name is not valid.',
  10021: 'Amount is not valid.',
  10022: 'Quantity was not recognized as a valid number (for API v1).',
  10023: 'Price was not recognized as a valid number.',
  10024: 'Parameter was not recognized as a valid number.',
  10025: 'Order id is missing or its format was not recognized as valid.',
  10026: 'Extra precision of the price is not supported.',
  10027: 'Futures contract amount was not recognized as integer.',
  10028: 'Allowed request rate has been exceeded.',
  10029: 'Attempt to operate with not own order.',
  10030: 'REST request where Websocket is expected.',
  10031: 'Some of arguments are not recognized as valid.',
  10032: 'Total cost is too low.',
  10033: 'Method is not implemented yet.',
  10034: 'Stop price is too high.',
  10035: 'Stop price is too low.',
  10036: 'Max Show Amount is not valid.',
  10040: "Request can't be processed right now and should be retried.",
  10041: 'Settlement is in progress. Every day at settlement time for several seconds, the system calculates user profits and updates balances. That time trading is paused for several seconds till the calculation is completed',
  10043: 'Price has to be rounded to a certain tick size.',
  10044: 'Stop Price has to be rounded to a certain tick size',
  10045: "Liquidation order can't be canceled",
  10046: "Liquidation order can't be edited.",
  10047: 'Reached limit of pending Matching Engine requests for user.',
  10048: 'The requested operation is not available on this server.',
  11008: 'This request is not allowed in regards to the filled order',
  11029: 'Some invalid input has been detected',
  11030: 'Some rejects which are not considered as very often, more info may be specified in `<Reason>`.',
  11031: 'Some errors which are not considered as very often, more info may be specified in `<Error>`.',
  11035: 'Allowed amount of stop orders has been exceeded.',
  11036: 'Invalid `StopPx` (too high or too low) as to current index or market.',
  11037: 'Instrument already not available for trading.',
  11038: 'Advanced orders are not available for futures.',
  11039: 'Advanced post-only orders are not supported yet.',
  11041: "Advanced order properties can't be set if the order is not advanced.",
  11042: 'Permission for the operation has been denied.',
  11043: 'Bad argument has been passed.',
  11044: 'Attempt to do open order operations with the not open order.',
  11045: 'Event name has not been recognized.',
  11046: 'At several minutes to instrument expiration, corresponding advanced implied volatility orders are not allowed.',
  11047: 'The specified combination of arguments is not supported.',
  11048: 'Wrong Max Show for options',
  11049: 'Several bad arguments have been passed.',
  11050: 'Request has not been parsed properly.',
  11051: 'System is under maintenance.',
  11052: 'Subscription error. However, subscription may fail without this error, please check list of subscribed channels returned, as some channels can be not subscribed due to wrong input or lack of permissions.',
  11053: 'Specified transfer is not found',
  11090: 'Invalid address',
  11091: 'Invalid addres for the transfer',
  11092: 'The address already exists',
  11093: 'Limit of allowed addresses has been reached.',
  11094: 'Some unhandled error on server. Please report to admin. The details of the request will help to locate the problem.',
  11095: 'Deposit address creation has been disabled by admin',
  11096: 'Withdrawal instead of transfer',
  12000: 'Wrong 2FA code',
  12001: 'Limit of sub accounts is reached',
  12002: 'Wrong alias, already used',
  12998: `Please wait {param} seconds and try again`,
  12003: 'number of failed login attempts is limited',
  12004: 'The number of registration requests is limited',
  12005: 'The country is banned (possibly via IP check)',
  12100: 'Transfer is not allowed. Possible wrong direction or other mistake',
  12999: '2FA code is correct but it is already used. Please, use next cod',
  13000: 'Login name is invalid (not allowed or it contains wrong characters).',
  13001: 'Account must be activated.',
  13002: 'Account is blocked by admin. ',
  13003: 'This action requires 2FA authentication.',
  13004: 'Invalid credentials has been used.',
  13005: 'Password confirmation error.',
  13006: 'Invalid Security Code.',
  13007: "User's security code has been changed or wrong.",
  13008: 'Request failed because of invalid input or internal failure.',
  13009: 'Wrong or expired authorization token or bad signature. For example, please check scope of the token, "connection" scope cannot be reused for other connections.',
  13010: 'Invalid input, missing value.',
  13011: 'Input is too short.',
  13012: 'Subaccount restrictions.',
  13013: 'Unsupported or invalid phone number.',
  13014: 'SMS sending failed -- phone number is wrong.',
  13015: 'Invalid SMS code.',
  13016: 'Invalid input.',
  13017: 'Subscription hailed, invalid subscription parameters.',
  13018: 'Invalid content type of the request.',
  13019: 'Closed, expired order book.',
  13020: 'Instrument is not found, invalid instrument name.',
  13021: 'Not enough permissions to execute the request, forbidden.',
  13025: 'API method temporarily switched off by administrator.',
  '-32602': 'Invalid params',
  '-32601': 'Method not found',
  '-32700': 'Parse error',
  '-32000': 'Missing params',
};

const disabledErrors = [13009, 13000, -32602, 11093, 12002];

export function isErrorEnabled(errorObject) {
  return disabledErrors.includes(errorObject.code) === false;
}

export function getErrorMessageFromDictionary(errorObject) {
  let errorMessage = errorsDictionary[errorObject.code] || 'Unknown error';

  if (errorMessage.includes('{param}')) {
    switch (errorObject.code) {
      case 12998:
        errorMessage = errorMessage.replace('{param}', errorObject.data.wait);
        break;
      default:
        break;
    }
  }

  return errorMessage;
}
