import {library} from '@fortawesome/fontawesome-svg-core';
import {faBtc, faEthereum} from '@fortawesome/free-brands-svg-icons';
import {
  faExclamationTriangle,
  faAdjust,
  faAngleDown,
  faAngleUp,
  faBars,
  faBook,
  faChartLine,
  faCircle,
  faCog,
  faCopy,
  faDollarSign,
  faDownload,
  faEllipsisV,
  faExchangeAlt,
  faExternalLinkAlt,
  faLayerGroup,
  faListUl,
  faLock,
  faLockOpen,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faPlus,
  faRssSquare,
  faShare,
  faSignOutAlt,
  faTh,
  faTimes,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAltExclamation,
  faCompressAlt,
  faExpandAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import React, {lazy, Suspense} from 'react';
import {connect} from 'react-redux';

import {Slide, toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import DesktopLayout from './containers/DesktopLayout/DesktopLayout';
import Nav from './containers/Nav/Nav';
import Stats from './containers/Stats/Stats';
import theme from './theme/theme';

library.add(
  faEllipsisV,
  faCommentAltExclamation,
  faCompressAlt,
  faExpandAlt,
  faShare,
  faLayerGroup,
  faTrash,
  faSignOutAlt,
  faExchangeAlt,
  faDownload,
  faTh,
  faAdjust,
  faBtc,
  faEthereum,
  faTimes,
  faCircle,
  faPlus,
  faListUl,
  faChartLine,
  faBars,
  faAngleUp,
  faAngleDown,
  faLockOpen,
  faLock,
  faDollarSign,
  faCog,
  faCopy,
  faUser,
  faBook,
  faRssSquare,
  faExternalLinkAlt,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faExclamationTriangle,
);

const MobileLayout = lazy(() =>
  import('./containers/MobileLayout/MobileLayout'),
);
const DisconnectedDialog = lazy(() =>
  import('./containers/DisconnectedDialog/DisconnectedDialog'),
);
const ShareDialog = lazy(() => import('./containers/ShareDialog/ShareDialog'));

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: window.innerWidth <= 960 ? 'top-center' : 'top-right',
  transition: Slide,
});

const themes = {
  dark: {
    '--elevated-0': '#121212',
    '--elevated-1': '#1e1e1e',
    '--elevated-2': '#1F1F1F',
    '--elevated-3': '#202020',
    '--elevated-4': '#222222',
    '--elevated-5': '#242424',
    '--elevated-6': '#3A3A3A',
    '--elevated-shadow-1':
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    '--elevated-shadow-2':
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    '--elevated-shadow-3':
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '--elevated-shadow-10':
      'rgba(0, 0, 0, 0.2) 0px 6px 6px -3px, rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px',
    '--deribit-green-lighten': '#17d6be',
    '--deribit-green': '#15c8b1',
    '--deribit-green-darken': '#14bba6',
    '--component-border': '#121212',
    '--light-component-border': '#3A3A3A',
    '--deribit-bid': '#1eae53',
    '--deribit-bid-disabled': 'rgba(30, 174, 83, 0.3)',
    '--deribit-bid-hover': 'hsl(142, 71%, 36%)',
    '--deribit-bid-selected': 'hsl(142, 70%, 26%)',
    '--input-field-border': 'transparent',
    '--input-field-shadow':
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    '--deribit-ask': '#e94e16',
    '--deribit-ask-disabled': 'rgba(233, 78, 22, 0.3)',
    '--deribit-ask-hover': 'hsl(16, 83%, 46%)',
    '--deribit-ask-selected': 'hsl(16, 82%, 36%)',
    '--mute-1': '#bbb',
    '--mute-2': '#888',
    '--mute-3': '#32373B',
    '--color-text-main': '#ffffff',
    '--color-text-main-darken': '#e1e2e2',
    '--graph-negative-region': 'rgba(255,0,0,0.04)',
    '--border-width': `${Math.min(2 / window.devicePixelRatio, 1)}px`,
  },
  light: {
    '--elevated-0': '#e1e1e1',
    '--elevated-1': '#e2e3e4',
    '--elevated-2': '#e3e5e8',
    '--elevated-3': '#eeeff1',
    '--elevated-4': '#f5f6f7',
    '--elevated-5': '#ffffff',
    '--elevated-6': '#ffffff',
    '--elevated-shadow-1':
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    '--elevated-shadow-2':
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    '--elevated-shadow-3':
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '--elevated-shadow-10':
      'rgba(0, 0, 0, 0.2) 0px 6px 6px -3px, rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px',
    '--deribit-green-lighten': '#17d6be',
    '--deribit-green': '#15c8b1',
    '--deribit-green-darken': '#14bba6',
    '--component-border': '#bbbcbf',
    '--deribit-bid': '#1eae53',
    '--deribit-bid-disabled': 'rgba(30, 174, 83, 0.3)',
    '--deribit-bid-hover': 'hsl(142, 71%, 36%)',
    '--deribit-bid-selected': 'hsl(142, 70%, 26%)',

    '--input-field-border': '#bbbcbf',
    '--input-field-shadow': 'inset 0 1px 2px rgba(27,31,35,.075)',
    '--deribit-ask': '#e94e16',
    '--deribit-ask-disabled': 'rgba(233, 78, 22, 0.3)',
    '--deribit-ask-hover': 'hsl(16, 83%, 46%)',
    '--deribit-ask-selected': 'hsl(16, 82%, 36%)',
    '--mute-1': '#32373B',
    '--mute-2': '#888',
    '--mute-3': '#bbb',
    '--color-text-main': '#0F111C',
    '--color-text-main-darken': '#000000',
    '--graph-negative-region': 'rgba(255,0,0,0.04)',
    '--border-width': `${Math.min(2 / window.devicePixelRatio, 1)}px`,
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: window.innerWidth <= 960,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    for (let [key, value] of Object.entries(themes[this.props.theme])) {
      document.documentElement.style.setProperty(key, value);
    }
  }

  componentDidUpdate() {
    for (let [key, value] of Object.entries(themes[this.props.theme])) {
      document.documentElement.style.setProperty(key, value);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize() {
    if (this.state.mobile !== window.innerWidth <= 960) {
      this.setState({mobile: window.innerWidth <= 960});
    }
  }

  render() {
    return (
      <ThemeProvider theme={{...theme(this.props.theme)}}>
        <CssBaseline>
          <Nav />
          {this.state.mobile ? (
            <Suspense fallback={<div>Loading...</div>}>
              <div className="NavFooter">
                <Stats />
              </div>
              <MobileLayout />
            </Suspense>
          ) : (
            <DesktopLayout />
          )}
          {this.props.reconnecting && (
            <Suspense fallback={<div>Loading...</div>}>
              <DisconnectedDialog />
            </Suspense>
          )}
          {this.props.sharePortfolioId && (
            <Suspense fallback={<div>Loading...</div>}>
              <ShareDialog />
            </Suspense>
          )}
        </CssBaseline>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    sharePortfolioId: state.settings.sharePortfolioId,
    reconnecting: state.websocket.reconnecting,
    theme: state.settings.theme,
  };
};

export default connect(mapStateToProps)(App);
