import {getErrorMessageFromDictionary} from '../../utils/ErrorCodes';
import * as actionTypes from './actionTypes';
import * as actions from '../actions';
import {toast} from 'react-toastify';

export const connect = url => {
  return {
    type: 'WEBSOCKET:CONNECT',
    payload: {url},
  };
};

export const disconnect = () => {
  return {
    type: 'WEBSOCKET:DISCONNECT',
  };
};

export const ping = () => {
  return {
    type: 'WEBSOCKET:PING',
  };
};

export const error = error => {
  return () => {
    const message = getErrorMessageFromDictionary(error);
    toast.error(message);
  };
};

export const open = () => {
  return async (dispatch, getState) => {
    await dispatch(_ping());
    await dispatch(actions.fetchInstruments());
    await dispatch(actions.restoreSession());

    const {
      auth: {username: loggedUsername},
      portfolio: {
        positions: {username, name},
      },
    } = getState();

    // Check if shared portfolio
    if (window.location.hash.includes('shared')) {
      await dispatch(
        actions.loadPositionsFromAWS('6f4f595e87e3088696ea9a8a660a4fa8'),
      );
    } else {
      // Always try to load logged in user's default portfolio first.
      await dispatch(
        actions.initPortfolio(
          loggedUsername ? loggedUsername : 'anonymous',
          loggedUsername === username ? name : 'default',
        ),
      );

      const {
        portfolio: {
          positions: {isLive},
        },
      } = getState();

      // Resync portfolio on init if portfolio is live.
      if (isLive && loggedUsername) {
        await dispatch(actions.fetchPositionsFromDeribitWithMissingTickers());
      }
    }
  };
};

export const send = (method, params) => {
  return {
    type: 'WEBSOCKET:SEND',
    payload: {
      method,
      params,
    },
    meta: {
      synchronous: true,
    },
  };
};

export const switchEnvironment = () => {
  return async dispatch => {
    await dispatch({type: actionTypes.SWITCH_ENVIRONMENT});
    await dispatch(actions.disconnect());

    window.location.reload();
  };
};

const _ping = () => {
  return async dispatch => {
    try {
      await dispatch(actions.send('public/ping', {}));
      return setTimeout(() => dispatch(_ping()), 5000);
    } catch (err) {
      return dispatch(actions.error(err));
    }
  };
};
