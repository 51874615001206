import {createMigrate, persistReducer} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth';
import graphReducer from './graph';
import ivSkewsReducer from './ivskews';
import instrumentReducer from './instrument';
import modalConfigReducer from './modalConfig';
import portfolioReducer from './portfolio';
import settingsReducer from './settings';
import userReducer from './user';
import websocketReducer from './websocket';

const migrations = {
  1: () => undefined,
  2: () => undefined,
};

const portfolioPersistConfig = {
  key: 'portfolio',
  storage: storage,
  whitelist: ['positions', 'portfolios'],
  stateReconciler: autoMergeLevel1,
  version: 2,
  migrate: createMigrate(migrations, {debug: false}),
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const settingsPersistConfig = {
  key: 'settings',
  blacklist: ['graphLoading', 'initialized'],
  storage: storage,
  version: 2,
  migrate: createMigrate(migrations, {debug: false}),
};

const websocketPersistConfig = {
  key: 'websocket',
  storage: storage,
  whitelist: ['testWs', 'wsUrl', 'clientId'],
};

const userPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['accounts'],
};

const reducers = {
  auth: persistReducer(authPersistConfig, authReducer),
  instrument: instrumentReducer,
  portfolio: persistReducer(portfolioPersistConfig, portfolioReducer),
  websocket: persistReducer(websocketPersistConfig, websocketReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  graph: graphReducer,
  ivskews: ivSkewsReducer,
  user: persistReducer(userPersistConfig, userReducer),
  modalConfig: modalConfigReducer,
};

export default reducers;
