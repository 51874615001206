export const DEAUTH = 'DEAUTH';
export const AUTH_FAIL = 'AUTH_FAIL';
export const UPDATE_OPTION_UNDERYLING_TICKER =
  'UPDATE_OPTION_UNDERYLING_TICKER';
export const ADD_OPTION_POSITIONS = 'ADD_OPTION_POSITIONS';
export const ADD_FUTURE_POSITIONS = 'ADD_FUTURE_POSITIONS';
export const DELETE_POSITION = 'DELETE_POSITION';
export const REPLACE_POSITIONS = 'REPLACE_POSITIONS';
export const CLEAR_PORTFOLIO = 'CLEAR_PORTFOLIO';
export const DELETE_POSITIONS = 'DELETE_POSITIONS';
export const PORTFOLIO_UPDATED = 'PORTFOLIO_UPDATED';
export const TOGGLE_POSITIONS = 'TOGGLE_POSITIONS';
export const PORTFOLIO_LOADING = 'PORTFOLIO_LOADING';
export const SWITCH_PORTFOLIO = 'SWITCH_PORTFOLIO';
export const NEW_PORTFOLIO = 'NEW_PORTFOLIO';
export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const INSTRUMENT_UNSUBSCRIBE_SUCCESS = 'INSTRUMENT_UNSUBSCRIBE_SUCCESS';
export const INSTRUMENT_SUBSCRIBE_SUCCESS = 'INSTRUMENT_SUBSCRIBE_SUCCESS';
export const LOAD_POSITIONS_FROM_DERIBIT = 'LOAD_POSITIONS_FROM_DERIBIT';
export const SET_REALTIME_UPDATES = 'SET_REALTIME_UPDATES';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_PNL_CURRENCY_IN_USD = 'SET_PNL_CURRENCY_IN_USD';
export const SET_PNL_NORMALIZED = 'SET_PNL_NORMALIZED';
export const SAVE_FLEX_LAYOUT = 'SAVE_FLEX_LAYOUT';
export const SAVE_COLUMN_LAYOUT = 'SAVE_COLUMN_LAYOUT';
export const RESET_LAYOUT = 'RESET_LAYOUT';
export const SET_TIME_NOW = 'SET_TIME_NOW';
export const SET_GRAPH_DIMENSIONS = 'SET_GRAPH_DIMENSIONS';
export const CALCULATE_GRAPH_DATA_PADDING = 'CALCULATE_GRAPH_DATA_AND_PADDING';
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const SET_CLOSEST_EXPIRY = 'SET_CLOSEST_EXPIRY';
export const SET_DAYS_FROM_TODAY = 'SET_DAYS_FROM_TODAY';
export const SET_X_DOMAIN = 'SET_X_DOMAIN';
export const SET_Y_DOMAIN = 'SET_Y_DOMAIN';
export const SET_XY_DOMAIN = 'SET_XY_DOMAIN';
export const SET_PLOT_DRAGGED = 'SET_PLOT_DRAGGED';
export const SWITCH_ENVIRONMENT = 'SWITCH_ENVIRONMENT';
export const FETCHED_USER_DATA = 'FETCHED_USER_DATA';
export const SET_SHARE_PORTFOLIO_ID = 'SET_SHARE_PORTFOLIO_ID';
export const SET_SHOW_MOBILE_DIALOG = 'SET_SHOW_MOBILE_DIALOG';
export const SET_THEME = 'SET_THEME';
export const BEFORE_SET_THEME = 'BEFORE_SET_THEME';
export const GRAPH_LOADING = 'GRAPH_LOADING';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
export const DELETE_USER_DATA = 'DELETE_USER_DATA';
export const PORTFOLIO_IS_LIVE_CHANGE = 'PORTFOLIO_IS_LIVE_CHANGE';
export const TOGGLE_MODAL_CONFIG = 'TOGGLE_MODAL_CONFIG';
export const ADD_MODAL_CONFIG = 'ADD_MODAL_CONFIG';
export const INIT_COMPLETED = 'INIT_COMPLETED';
export const LOAD_OPTION_POSITIONS_FROM_DERIBIT_COMPLETED =
  'LOAD_OPTION_POSITIONS_FROM_DERIBIT_COMPLETED';
export const SET_ACTIVE_GRAPH = 'SET_ACTIVE_GRAPH';
export const SET_IVSKEWS_GRAPH_DATA = 'SET_IVSKEWS_GRAPH_DATA';
export const FETCH_INSTRUMENTS_REQUEST = 'FETCH_INSTRUMENTS_REQUEST';
export const FETCH_INSTRUMENTS_SUCCESS = 'FETCH_INSTRUMENTS_SUCCESS';
export const FETCH_TICKERS_REQUEST = 'FETCH_TICKERS_REQUEST';
export const FETCH_TICKERS_SUCCESS = 'FETCH_TICKERS_SUCCESS';
export const FETCH_INDICES_REQUEST = 'FETCH_INDICES_REQUEST';
export const FETCH_INDICES_SUCCESS = 'FETCH_INDICES_SUCCESS';
export const FETCH_SUBACCOUNTS_REQUEST = 'FETCH_SUBACCOUNTS_REQUEST';
export const FETCH_SUBACCOUNTS_SUCCESS = 'FETCH_SUBACCOUNTS_SUCCESS';
export const FETCH_POSITIONS_FROM_DERIBIT_SUCCESS =
  'FETCH_POSITIONS_FROM_DERIBIT_SUCCESS';
export const FETCH_ACCOUNT_SUMMARY_REQUEST = 'FETCH_ACCOUNT_SUMMARY_REQUEST';
export const FETCH_ACCOUNT_SUMMARY_SUCCESS = 'FETCH_ACCOUNT_SUMMARY_SUCCESS';
export const UPDATE_FUTURE_POSITION = 'UPDATE_FUTURE_POSITION';
export const UPDATE_OPTION_POSITION = 'UPDATE_OPTION_POSITION';
export const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';
export const SET_PORTFOLIO_OWNER = 'SET_PORTFOLIO_OWNER';
