import React from 'react';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import {connect} from 'react-redux';
import {withSize} from 'react-sizeme';
import deribitBetaLogoBright from '../../assets/Deribit-beta-logo-bright.svg';
import deribitBetaLogoDark from '../../assets/Deribit-beta-logo-dark.svg';
import * as actions from '../../store/actions';
import classes from './DualLineChart.module.scss';
import Actions from './Fragments/Actions';
import Axes from './Fragments/Axes';
import Lines from './Fragments/Lines';
import Overlays from './Fragments/Overlays';
import Plot from './Fragments/Plot';
import {Tooltip} from './Fragments/Tooltip';

export const DualLineChart = props => {
  let {
    graphLoading,
    theme,
    size: {width, height},
  } = props;

  let marginTop = 0,
    marginRight = 40,
    marginBottom = 30,
    marginLeft = 0,
    colorLine1 = 'var(--deribit-ask)',
    colorLine2 = 'var(--deribit-bid)',
    legendTop = 0;

  if (window.innerWidth <= 960) {
    height += 20;
    marginTop = 0;
    legendTop = 0;
    marginBottom = 24;
  }

  let plotWidth = width - (marginLeft + marginRight),
    plotHeight = height - (marginTop + marginBottom);

  return (
    <div
      id="DualLineChart"
      className={classes.DualLineChart}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <Grid item className={classes.Logo}>
        <img
          width="96px"
          alt="logo"
          src={theme === 'dark' ? deribitBetaLogoBright : deribitBetaLogoDark}
        />
      </Grid>
      {graphLoading ? (
        <React.Fragment>
          <div
            style={{
              backgroundColor: 'var(--elevated-2)',
              width: '100%',
              height: '100%',
            }}>
            <CircularProgress
              classes={{
                root: classes.Progress,
                colorPrimary: classes.ProgressColorPrimary,
              }}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Hidden smDown>
            <Actions />
          </Hidden>
          <svg width={width} height={height + legendTop - marginTop}>
            <g
              transform={`translate(${marginLeft}, ${marginTop})`}
              style={{userSelect: 'none'}}>
              <rect
                className="DualLineChartBackground"
                width={plotWidth}
                height={plotHeight}
                style={{fill: 'var(--elevated-1)'}}
              />

              <Axes plotWidth={plotWidth} plotHeight={plotHeight} />

              <Plot plotWidth={plotWidth} plotHeight={plotHeight}>
                <Lines
                  plotWidth={plotWidth}
                  plotHeight={plotHeight}
                  colorLine1={colorLine1}
                  colorLine2={colorLine2}
                />

                <Tooltip
                  plotWidth={plotWidth}
                  plotHeight={plotHeight}
                  colorLine1={colorLine1}
                  colorLine2={colorLine2}
                />
              </Plot>

              <Overlays plotWidth={plotWidth} plotHeight={plotHeight} />
            </g>
          </svg>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  graphLoading: state.settings.graphLoading,
  theme: state.settings.theme,
  initialized: state.settings.initialized,
});

const mapDispatchToProps = dispatch => ({
  setActiveGraph: graph => dispatch(actions.setActiveGraph(graph)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSize({monitorHeight: true})(DualLineChart));
