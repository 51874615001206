import * as d3 from 'd3';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';

const Plot = props => {
  const {plotWidth, plotHeight, yDomain, children} = props;

  let yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(yDomain)
        .range([plotHeight, 0]),
    [yDomain, plotHeight],
  );

  return (
    <React.Fragment>
      <defs>
        <clipPath id="clip">
          <rect width={plotWidth} height={plotHeight} x={0} y={0} />
        </clipPath>
      </defs>

      <g clipPath="url(#clip)">
        <rect
          className="negative-region"
          width={plotWidth}
          height={plotHeight > yScale(0) ? plotHeight - yScale(0) : 0}
          transform={`translate(0, ${yScale(0)})`}
          style={{
            fill: 'var(--graph-negative-region)',
            pointerEvents: 'none',
          }}
        />

        {children}
      </g>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    yDomain: state.graph.yDomain,
  };
};

export default connect(mapStateToProps, null)(Plot);
