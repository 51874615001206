import {LicenseManager} from 'ag-grid-enterprise';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {PersistGate} from 'redux-persist/lib/integration/react';
import App from './App';
import CrossTabGate from './containers/CrossTabGate/CrossTabGate';
import {ConnectedRouter} from 'connected-react-router';
import './index.scss';
import OAuth from './containers/OAuth/OAuth';

import * as serviceWorker from './serviceWorker';
import store, {persistor, history} from './store';
import TagManager from 'react-gtm-module';
import WebSocket from './containers/WebSocket/WebSocket';

const tagManagerArgs = {
  gtmId: 'GTM-MJL7FM3',
};

TagManager.initialize(tagManagerArgs);

LicenseManager.setLicenseKey(
  'Deribit_B.V.__MultiApp_1Devs_2Deployment_9_May_2020__MTU4ODk3ODgwMDAwMA==987002a0d042ea0893f758cf11337509',
);

const Root = ({store}) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CrossTabGate>
          <WebSocket>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/oauth" component={OAuth} />
                <Route strict path="/:currency" component={App} />
                <Route path="/" render={() => <Redirect to="/BTC" />} />
              </Switch>
            </ConnectedRouter>
          </WebSocket>
        </CrossTabGate>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
