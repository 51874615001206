import {createMuiTheme} from '@material-ui/core';

const theme = mode =>
  createMuiTheme({
    overrides: {
      MuiDialogTitle: {
        root: {
          backgroundColor: 'var(--elevated-5)',
        },
      },
      MuiDialogContent: {
        root: {
          backgroundColor: 'var(--elevated-5)',
        },
      },
      MuiDialogActions: {
        root: {
          backgroundColor: 'var(--elevated-5)',
        },
      },
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        root: {
          fontWeight: 'bold',
        },
      },
      MuiOutlinedInput: {
        root: {
          height: 36,
        },
        notchedOutline: {
          border: 'none',
        },
      },
      MuiSlider: {
        valueLabel: {
          top: -14,
          '& *': {
            fontSize: 12,
            background: 'transparent',
            color: 'var(--color-text-main)',
            whiteSpace: 'nowrap',
          },
        },
        markLabel: {
          top: 5,
        },
        marked: {
          marginBottom: 0,
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: 11,
          fontFamily: 'IBM Plex Sans',
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: 11,
          fontFamily: 'IBM Plex Sans',
        },
      },
      MuiDrawer: {
        paperAnchorLeft: {
          padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          height: 'calc(100% - env(safe-area-inset-top))',
          backgroundColor: 'var(--elevated-3)',
        },
        paperAnchorRight: {
          padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          backgroundColor: 'var(--elevated-3)',
        },
        paperAnchorTop: {
          padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          backgroundColor: 'var(--elevated-3)',
          borderRadius: '0 0 10px 10px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'none',
          },
        },
        selectMenu: {
          overflow: 'visible',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
        },
      },
      MuiNativeSelect: {
        select: {
          height: 36,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 14,
          borderBottom: 'none',
          borderRadius: 3,
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: 'IBM Plex Sans',
        },
        button: {
          fontFamily: 'IBM Plex Sans',
          fontWeight: 600,
          fontSize: 10,
          height: 11,
          display: 'flex',
          whiteSpace: 'nowrap',
          letterSpacing: '0.07272727em',
        },
        subtitle1: {
          fontFamily: 'IBM Plex Sans',
          fontSize: 15,
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
        subtitle2: {
          fontSize: 11,
          whiteSpace: 'nowrap',
        },
        colorTextSecondary: {
          color: '#666666',
        },
      },
      MuiListItem: {
        root: {
          padding: '0 16px',
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: 4,
          marginRight: 12,
        },
      },
      MuiIconButton: {
        root: {
          // padding: 8,
          // margin: 4
        },
      },
      MuiTooltip: {
        arrow: {
          color: 'var(--elevated-6)',
        },
      },
    },
    palette: {
      type: mode,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#15c8b1',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#0044ff',
      },
      error: {
        main: '#e94e16',
      },
      // error: will use the default color
    },
  });

export default theme;
