import _ from 'lodash';

// Middleware that counts actions.
// Once an action with loads of dispatches is found,
// consider throttling to improve performance.

const counter = {};

const _print = () => console.log(counter);
const debouncePrint = _.throttle(_print, 1000, false);

const middleware = () => {
  return next => action => {
    const {type, fn} = action;

    if (!counter[type]) {
      counter[type] = 1;
    } else {
      counter[type] += 1;
    }

    debouncePrint();

    if (fn) {
      return next(fn);
    } else {
      return next(action);
    }
  };
};

export default middleware;
