import {FuturePLValue, OptionPLValueToday} from './Graph';
import {calculateFuturesMargin, calculateOptionsMargin} from './MaintMargin';
import {Black76} from './PricingModels';

const daysScalar = 24 * 60 * 60 * 1000;

export const transformPosition = (
  {kind, instrument_name, direction, size, average_price, mark_price, iv},
  ticker,
  instrument,
  settings,
) => {
  if (kind === 'option') {
    const [currency, , strike, type] = instrument_name.split('-');
    const black76 = new Black76({
      contractType: type === 'C' ? 'call' : 'put',
      futureUSD: ticker['underlying_price'],
      strikeUSD: strike,
      timeToExpiry:
        (instrument.expiration_timestamp - settings.now) / daysScalar,
      riskFreeRate: ticker['interest_rate'],
      optionTOK: mark_price,
      ...(iv && {volatility: +iv}),
    });

    return {
      currency,
      instrument: instrument_name,
      side: ['sell', 'short'].includes(direction) ? 'short' : 'long',
      size,
      kind,
      avg_price: iv
        ? +black76.price() / ticker['underlying_price']
        : average_price,
      cur_price: iv
        ? +black76.price() / ticker['underlying_price']
        : mark_price,
      pnl:
        +(OptionPLValueToday(false, 1, average_price, mark_price) * size) *
        (settings.PNLCurrencyInUSD ? ticker['underlying_price'] : 1),
      iv: iv ? iv : (+black76.vol()).toFixed(2),
      delta: (+black76.delta() * size).toFixed(2),
      gamma: (+black76.gamma() * size).toFixed(5),
      theta: (+black76.theta() * size).toFixed(5),
      vega: (+black76.vega() * size).toFixed(5),
      margin: calculateOptionsMargin(
        instrument_name,
        direction === 'sell' ? 'short' : 'long',
        size,
        +average_price,
      ),
      expiration: instrument.expiration_timestamp,
    };
  } else {
    const [currency] = instrument_name.split('-');
    return {
      currency,
      instrument: instrument_name,
      side: ['sell', 'short'].includes(direction) ? 'short' : 'long',
      size,
      kind,
      avg_price: average_price,
      cur_price: mark_price,
      pnl:
        +FuturePLValue(settings.PNLCurrencyInUSD, mark_price, average_price) *
        size,
      margin: +calculateFuturesMargin(instrument_name, +size, +average_price),
      delta: size / mark_price,
    };
  }
};
