module.exports = {
  kind: 'Name and type of the traded instrument',
  side: 'Side of the current position',
  size: 'Size of the current position in contracts',
  size_currency: 'Size of the current position in base currency',
  avg_price: 'Average price at which position was opened',
  cur_price: 'Current market price of the instrument',
  locked: 'Lock current value',
  margin: 'Maintenance margin for the position',
  iv: 'Implied volatility of the instrument',
  delta:
    'Rate of change of option price (USD) per $1 change in the underlying asset',
  gamma: 'Rate of change of Delta per $1 change in the underlying asset',
  theta: 'Rate of change of option price (USD) per day',
  vega:
    'Rate of change of option price (USD) per 1 percentage-point change in implied volatility',
};
