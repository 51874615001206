import * as actionTypes from './actionTypes';

export const addModalConfig = id => {
  return {
    type: actionTypes.ADD_MODAL_CONFIG,
    id,
  };
};

export const toggleModalConfig = (id, x, y) => {
  return {
    type: actionTypes.TOGGLE_MODAL_CONFIG,
    id,
    x,
    y,
  };
};
