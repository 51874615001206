import * as actionTypes from './actionTypes';
import * as actions from './';

export const setRealtimeUpdates = bool => {
  return {
    type: actionTypes.SET_REALTIME_UPDATES,
    enable: bool,
  };
};

export const setCurrency = currency => {
  return async dispatch => {
    await dispatch(actions.graphLoading(true));
    await dispatch({
      type: actionTypes.SET_CURRENCY,
      currency: currency,
    });

    await dispatch(actions.checkSubscriptions());
    await dispatch(actions.portfolioUpdated(true));
    await dispatch(actions.graphLoading(false));
  };
};

export const saveFlexLayout = layout => {
  return {
    type: actionTypes.SAVE_FLEX_LAYOUT,
    layout: layout,
  };
};

export const saveColumnLayout = layout => {
  return {
    type: actionTypes.SAVE_COLUMN_LAYOUT,
    layout: layout,
  };
};

export const resetLayout = () => {
  return {
    type: actionTypes.RESET_LAYOUT,
  };
};

export const setPNLNormalized = bool => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.SET_PNL_NORMALIZED,
      enable: bool,
    });

    return dispatch(actions.portfolioUpdated());
  };
};

export const setPNLCurrencyInUSD = bool => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.SET_PNL_CURRENCY_IN_USD,
      enable: bool,
    });

    return dispatch(actions.portfolioUpdated(true));
  };
};

export const setSharePortfolioId = hash => {
  return {
    type: actionTypes.SET_SHARE_PORTFOLIO_ID,
    hash: hash,
  };
};

export const setShowMobileDialog = bool => {
  return {
    type: actionTypes.SET_SHOW_MOBILE_DIALOG,
    enable: bool,
  };
};

export const setTheme = theme => {
  return {
    type: actionTypes.SET_THEME,
    theme: theme,
  };
};

export const beforeSetTheme = theme => {
  return {
    type: actionTypes.BEFORE_SET_THEME,
    theme: theme,
  };
};

export const graphLoading = bool => {
  return {
    type: actionTypes.GRAPH_LOADING,
    loading: bool,
  };
};

export const portfolioLoading = bool => {
  return {
    type: actionTypes.PORTFOLIO_LOADING,
    loading: bool,
  };
};

export const openDrawer = () => {
  return {
    type: actionTypes.OPEN_DRAWER,
  };
};

export const setTimeNow = now => ({
  type: actionTypes.SET_TIME_NOW,
  now: now,
});

export const initCompleted = () => {
  return async dispatch => {
    await dispatch({type: actionTypes.INIT_COMPLETED});
    return dispatch(actions.checkSubscriptions());
  };
};

export const setActiveGraph = graph => ({
  type: actionTypes.SET_ACTIVE_GRAPH,
  graph: graph,
});
