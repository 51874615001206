import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';

const IVSkews = props => {
  const {node, setActiveGraph} = props;

  useEffect(() => {
    if (props.initialized && node.isVisible()) {
      setActiveGraph('Implied Volatility Skews');
    }
  }, [node]);

  return <div>IVSkews will go here.</div>;
};

const mapStateToProps = state => {
  return {
    initialized: state.settings.initialized,
  };
};

const mapDispatchToProps = dispatch => ({
  setActiveGraph: graph => dispatch(actions.setActiveGraph(graph)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IVSkews);
