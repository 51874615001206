import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import {connect} from 'react-redux';
import ModalConfig from '../../../components/UI/ModalConfig/ModalConfig';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import * as actions from '../../../store/actions';
import classes from './Actions.module.scss';

const StyledSlider = withStyles({
  markLabel: {
    top: '50%',
    'line-height': 0,
  },
  thumb: {
    height: 10,
    width: 10,
    marginTop: -4,
    marginLeft: -5,
  },
  valueLabel: {
    '& span': {
      'font-size': 10,
    },
  },
})(Slider);

export const Actions = props => {
  const {
    closestExpiry,
    daysFromToday,
    PNLCurrencyInUSD,
    PNLNormalized,
    lastNow,
    onSetDaysFromToday,
    onSetPNLCurrencyInUSD,
    onSetPNLNormalized,
  } = props;

  const daysToExpiry = closestExpiry
      ? (closestExpiry - lastNow) / (24 * 60 * 60 * 1000)
      : 0,
    daysMax =
      !closestExpiry || closestExpiry === 32503708800000 ? 1 : daysToExpiry; // handle initial state or PERPETUAL

  const marks = [
    {
      value: 0,
      label: (
        <span
          style={{
            position: 'relative',
            left: '-28px',
            color: 'var(--deribit-bid)',
          }}>
          Today
        </span>
      ),
    },
    {
      value: daysMax,
      label: (
        <span
          style={{
            position: 'relative',
            right: '-28px',
            color: 'var(--deribit-ask)',
          }}>
          Expiry
        </span>
      ),
    },
  ];

  return (
    <Grid item className={classes.Actions}>
      <Grid item className={classes.Slider}>
        <StyledSlider
          disabled={!closestExpiry}
          valueLabelDisplay="on"
          value={daysFromToday}
          getAriaValueText={() => daysToExpiry}
          valueLabelFormat={val => `+${val.toFixed(2)} days`}
          aria-labelledby="discrete-slider"
          step={1 / 144} // 10 minutes
          min={0}
          marks={marks}
          max={daysMax}
          onChange={(e, val) => onSetDaysFromToday(val)}
        />
      </Grid>
      <ModalConfig id="Profit / Loss">
        <Tooltip title="Shifts your PNL line such that for the current index price your pnl is zero.">
          <MenuItem>
            <ListItemIcon>
              <Switch
                color="primary"
                size="small"
                checked={PNLNormalized}
                onChange={e => onSetPNLNormalized(e.target.checked)}
              />
            </ListItemIcon>
            <ListItemText>Normalize PNL</ListItemText>
          </MenuItem>
        </Tooltip>
        <MenuItem>
          <ListItemIcon>
            <Switch
              color="primary"
              size="small"
              checked={PNLCurrencyInUSD}
              onChange={e => {
                window.PNLCurrencySwitched = true;
                onSetPNLCurrencyInUSD(e.target.checked);
              }}
            />
          </ListItemIcon>
          <ListItemText>PNL in USD</ListItemText>
        </MenuItem>
      </ModalConfig>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    closestExpiry: state.graph.closestExpiry,
    daysFromToday: state.graph.daysFromToday,
    PNLCurrencyInUSD: state.settings.PNLCurrencyInUSD,
    PNLNormalized: state.settings.PNLNormalized,
    lastNow: state.settings.now,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetDaysFromToday: days => dispatch(actions.setDaysFromToday(days)),
    onSetPNLCurrencyInUSD: bool => dispatch(actions.setPNLCurrencyInUSD(bool)),
    onSetPNLNormalized: bool => dispatch(actions.setPNLNormalized(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
