import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import classes from '../../WebSocket.module.scss';

const WebSocket = ({connect, wsUrl, children, disconnect, initialized}) => {
  useEffect(() => {
    connect(wsUrl);
    return () => disconnect();
  }, []);

  if (!initialized) {
    return (
      <CircularProgress
        classes={{
          root: classes.Progress,
          colorPrimary: classes.ProgressColorPrimary,
        }}
      />
    );
  }

  return children;
};

const mapStateToProps = state => {
  return {
    wsUrl: state.websocket.wsUrl,
    initialized: state.settings.initialized,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    connect: url => dispatch(actions.connect(url)),
    disconnect: () => dispatch(actions.disconnect()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket);
