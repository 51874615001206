import * as actionTypes from '../actions/actionTypes';

const initialState = {
  BTC: {},
  ETH: {},
  accounts: [],
};

const fetchAccountSummarySuccess = (state, action) => {
  const {response} = action;
  const newState = response.reduce((acc, cur) => {
    acc[cur.currency] = cur;
    return acc;
  }, state);

  return {
    ...state,
    ...newState,
    accounts: [
      {
        username: action.response[0].username,
        type: action.response[0].type,
        system_name: action.response[0].system_name,
        id: action.response[0].id,
        portfolio: {
          btc: newState['BTC'],
          eth: newState['ETH'],
        },
      },
      ...state.accounts.filter(
        el => el.username !== action.response[0].username,
      ),
    ],
  };
};

const fetchSubaccountsSuccess = (state, {response}) => {
  return {
    ...state,
    accounts: response,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_SUMMARY_SUCCESS:
      return fetchAccountSummarySuccess(state, action);
    case actionTypes.FETCH_SUBACCOUNTS_SUCCESS:
      return fetchSubaccountsSuccess(state, action);
    case actionTypes.DELETE_USER_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
