import * as actionTypes from '../actions/actionTypes';

const initialState = {
  realtimeUpdates: true,
  flexLayout: null,
  columnLayout: null,
  currency: 'BTC',
  PNLCurrencyInUSD: false,
  PNLNormalized: false,
  graphLoading: true,
  portfolioLoading: true,
  sharePortfolioId: '',
  showMobileDialog: true,
  theme: 'dark',
  drawerOpen: false,
  now: new Date().getTime(),
  initialized: false,
  activeGraph: null,
};

const setRealtimeUpdates = (state, action) => {
  return {
    ...state,
    realtimeUpdates: action.enable,
  };
};

const setCurrency = (state, action) => {
  return {
    ...state,
    currency: action.currency,
  };
};

const saveFlexLayout = (state, action) => {
  return {
    ...state,
    flexLayout: action.layout,
  };
};

const saveColumnLayout = (state, action) => {
  return {
    ...state,
    columnLayout: action.layout,
  };
};

const resetLayout = state => {
  return {
    ...state,
    columnLayout: null,
    flexLayout: null,
  };
};

const setPNLCurrencyInUSD = (state, action) => {
  return {
    ...state,
    PNLCurrencyInUSD: action.enable,
  };
};

const setPNLNormalized = (state, action) => {
  return {
    ...state,
    PNLNormalized: action.enable,
  };
};

const portfolioLoading = (state, action) => {
  return {
    ...state,
    portfolioLoading: action.loading,
  };
};

const graphLoading = (state, action) => {
  return {
    ...state,
    graphLoading: action.loading,
  };
};

const setSharePortfolioId = (state, action) => {
  return {
    ...state,
    sharePortfolioId: action.hash,
  };
};

const setShowMobileDialog = (state, action) => {
  return {
    ...state,
    showMobileDialog: action.enable,
  };
};

const setTheme = (state, action) => {
  return {
    ...state,
    theme: action.theme,
  };
};

const openDrawer = state => {
  return {
    ...state,
    drawerOpen: true,
  };
};

const setTimeNow = (state, action) => ({
  ...state,
  now: action.now,
});

const initCompleted = state => {
  return {
    ...state,
    initialized: true,
    portfolioLoading: false,
    graphLoading: false,
  };
};

const setActiveGraph = (state, action) => ({
  ...state,
  activeGraph: action.graph,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REALTIME_UPDATES:
      return setRealtimeUpdates(state, action);
    case actionTypes.SET_CURRENCY:
      return setCurrency(state, action);
    case actionTypes.SAVE_FLEX_LAYOUT:
      return saveFlexLayout(state, action);
    case actionTypes.SAVE_COLUMN_LAYOUT:
      return saveColumnLayout(state, action);
    case actionTypes.RESET_LAYOUT:
      return resetLayout(state, action);
    case actionTypes.SET_PNL_CURRENCY_IN_USD:
      return setPNLCurrencyInUSD(state, action);
    case actionTypes.SET_PNL_NORMALIZED:
      return setPNLNormalized(state, action);
    case actionTypes.PORTFOLIO_LOADING:
      return portfolioLoading(state, action);
    case actionTypes.GRAPH_LOADING:
      return graphLoading(state, action);
    case actionTypes.SET_SHARE_PORTFOLIO_ID:
      return setSharePortfolioId(state, action);
    case actionTypes.SET_SHOW_MOBILE_DIALOG:
      return setShowMobileDialog(state, action);
    case actionTypes.SET_THEME:
      return setTheme(state, action);
    case actionTypes.OPEN_DRAWER:
      return openDrawer(state, action);
    case actionTypes.SET_TIME_NOW:
      return setTimeNow(state, action);
    case actionTypes.INIT_COMPLETED:
      return initCompleted(state, action);
    case actionTypes.SET_ACTIVE_GRAPH:
      return setActiveGraph(state, action);
    case actionTypes.LOAD_OPTION_POSITIONS_FROM_DERIBIT_COMPLETED:
      return portfolioLoading(state, {loading: false});
    case actionTypes.SET_GRAPH_DATA:
      return graphLoading(state, {loading: false});
    case actionTypes.CALCULATE_GRAPH_DATA_PADDING:
      return graphLoading(state, {loading: true});
    case actionTypes.LOAD_POSITIONS_FROM_DERIBIT:
      return portfolioLoading(state, {loading: true});
    default:
      return state;
  }
};

export default reducer;
