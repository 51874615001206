import axios from 'axios';
import {toast} from 'react-toastify';
import shortid from 'shortid';
import {FuturePLValue, OptionPLValueToday} from '../../utils/Graph';
import {transformPosition} from '../../utils/PositionTransformer';
import * as actionTypes from './actionTypes';
import * as actions from './index';

const daysScalar = 24 * 60 * 60 * 1000;

export const addFuturePositions = positions => {
  return async (dispatch, getState) => {
    await dispatch({
      type: actionTypes.ADD_FUTURE_POSITIONS,
      payload: positions.map(
        ({side, size, avg_price, cur_price, instrument, verity}) => ({
          key: shortid.generate(),
          enabled: true,
          verity,
          ...transformPosition(
            {
              kind: 'future',
              instrument_name: instrument,
              direction: side,
              size,
              average_price: avg_price,
              mark_price: cur_price ? cur_price : avg_price,
            },
            null,
            null,
            getState().settings,
          ),
        }),
      ),
    });
    await dispatch(actions.checkSubscriptions());
    return dispatch(actions.portfolioUpdated(true));
  };
};

export const addOptionPositions = positions => {
  return async (dispatch, getState) => {
    const state = getState();
    await dispatch({
      type: actionTypes.ADD_OPTION_POSITIONS,
      payload: positions.map(
        ({side, size, avg_price, cur_price, instrument, verity}) => ({
          key: shortid.generate(),
          enabled: true,
          verity,
          ...transformPosition(
            {
              kind: 'option',
              instrument_name: instrument,
              direction: side,
              size,
              average_price: avg_price,
              mark_price: cur_price ? cur_price : avg_price,
            },
            state.instrument.tickers['options'][instrument],
            state.instrument.instruments['options'][instrument],
            state.settings,
          ),
        }),
      ),
    });

    await dispatch(actions.checkSubscriptions());
    return dispatch(actions.portfolioUpdated(true));
  };
};

export const updateFuturePosition = (
  instrument,
  verity,
  updates,
  userAction = false,
  colId,
) => {
  return async (dispatch, getState) => {
    const state = getState();

    const positions = state.portfolio.positions.futures.filter(
      el =>
        el.instrument === instrument &&
        (verity === 'both' ? true : el.verity === verity),
    );

    const newPositions = positions.map(pos => {
      return {
        ...pos,
        ...updates,
      };
    });

    await dispatch({
      type: actionTypes.UPDATE_FUTURE_POSITION,
      payload: newPositions.map(
        ({kind, side, size, avg_price, cur_price, ...rest}) => ({
          ...rest,
          ...transformPosition(
            {
              kind: kind,
              instrument_name: instrument,
              direction: side,
              size: size,
              average_price: avg_price,
              mark_price: cur_price,
            },
            null,
            null,
            state.settings,
          ),
        }),
      ),
      userAction,
      colId,
    });

    if (userAction) {
      return dispatch(actions.portfolioUpdated());
    } else {
      return dispatch(actions.portfolioUpdatedThrottled());
    }
  };
};

export const updateOptionPosition = (
  instrument,
  verity,
  updates,
  userAction = false,
  colId,
) => {
  return async (dispatch, getState) => {
    const state = getState();

    const positions = state.portfolio.positions.options.filter(
      el =>
        el.instrument === instrument &&
        (verity === 'both' ? true : el.verity === verity),
    );

    const newPositions = positions.map(pos => ({
      ...pos,
      ...updates,
    }));

    await dispatch({
      type: actionTypes.UPDATE_OPTION_POSITION,
      payload: newPositions.map(
        ({kind, side, size, avg_price, cur_price, ...rest}) => ({
          ...rest,
          ...transformPosition(
            {
              kind: kind,
              instrument_name: instrument,
              direction: side,
              size: size,
              average_price: avg_price,
              mark_price: cur_price,
              ...(updates.iv && {iv: updates.iv}),
            },
            state.instrument.tickers['options'][instrument],
            state.instrument.instruments['options'][instrument],
            state.settings,
          ),
        }),
      ),
      userAction,
      colId,
    });

    if (userAction) {
      return dispatch(actions.portfolioUpdated());
    } else {
      return dispatch(actions.portfolioUpdatedThrottled());
    }
  };
};

export const deletePosition = (key, kind) => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.DELETE_POSITION,
      key: key,
      kind: kind,
    });

    await dispatch(actions.checkSubscriptions());
    return dispatch(actions.portfolioUpdated());
  };
};

export const replacePositions = data => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.REPLACE_POSITIONS,
      data: data,
    });

    await dispatch(actions.checkSubscriptions());
    return dispatch(actions.portfolioUpdated());
  };
};

export const togglePositions = (currency, positions) => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.TOGGLE_POSITIONS,
      currency: currency,
      positions: positions,
    });
    return dispatch(actions.portfolioUpdated(true));
  };
};

const _switchPortfolio = key => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.SWITCH_PORTFOLIO,
      key,
    });

    return dispatch(actions.portfolioUpdated());
  };
};

export const newPortfolio = (username, id, futures = [], options = []) => {
  return {
    type: actionTypes.NEW_PORTFOLIO,
    id,
    futures,
    options,
    username,
  };
};

export const deletePortfolio = key => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.DELETE_PORTFOLIO,
      key: key,
    });

    return dispatch(actions.portfolioUpdated());
  };
};

export const deletePositions = (verity, currency) => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.DELETE_POSITIONS,
      verity,
      currency,
    });

    await dispatch(actions.checkSubscriptions());
    return dispatch(actions.portfolioUpdated());
  };
};

export const portfolioIsLiveChange = bool => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.PORTFOLIO_IS_LIVE_CHANGE,
      bool,
    });

    await dispatch(actions.checkSubscriptions());

    if (bool) {
      await dispatch(actions.fetchPositionsFromDeribitWithMissingTickers());
    }
  };
};

export const fetchPositionsFromDeribitWithMissingTickers = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const calls = [
      {currency: 'BTC', kind: 'option'},
      {currency: 'BTC', kind: 'future'},
      {currency: 'ETH', kind: 'option'},
      {currency: 'ETH', kind: 'future'},
    ];

    try {
      const response = (
        await Promise.all(
          calls.map(({currency, kind}) =>
            dispatch(actions.send('private/get_positions', {currency, kind})),
          ),
        )
      ).flat();

      const instruments = response.reduce(
        (acc, {instrument_name, kind, size}) => {
          if (
            !state.instrument.tickers[`${kind}s`][instrument_name] &&
            +size !== 0
          ) {
            acc.push(instrument_name);
          }

          return acc;
        },
        [],
      );

      await dispatch(actions.fetchTickers(instruments));
      await dispatch({
        type: actionTypes.FETCH_POSITIONS_FROM_DERIBIT_SUCCESS,
        payload: response
          .filter(el => el.size !== 0)
          .map(
            ({
              kind,
              instrument_name,
              direction,
              size,
              average_price,
              mark_price,
            }) => ({
              ...transformPosition(
                {
                  kind,
                  instrument_name,
                  direction,
                  size,
                  average_price,
                  mark_price,
                },
                state.instrument.tickers[`${kind}s`][instrument_name],
                state.instrument.instruments[`${kind}s`][instrument_name],
                state.settings,
              ),
              key: shortid.generate(),
              enabled: true,
              avg_price_locked: true,
              verity: 'actual',
            }),
          ),
      });
      return dispatch(actions.portfolioUpdated(true));
    } catch (error) {
      console.log(error);
      return dispatch(actions.error(error));
    }
  };
};

export const savePositionsToAWS = () => {
  return async function(dispatch, getState) {
    const {
      portfolio: {positions},
      settings: {currency},
    } = getState();

    if (positions.options.length + positions.futures.length > 0) {
      try {
        const {
          data,
        } = await axios.post(
          'https://tf209d4mce.execute-api.us-east-1.amazonaws.com/default/save',
          {positions},
        );

        return dispatch(
          actions.setSharePortfolioId(
            `${window.location.origin}/#/${currency}/shared/${data.id}`,
          ),
        );
      } catch (error) {
        return dispatch(actions.error(error));
      }
    } else {
      toast.error('Please add a position');
    }
  };
};

export const loadPositionsFromAWS = hash => {
  return async function(dispatch, getState) {
    const {
      instrument: {
        instruments: {futures, options},
      },
    } = getState();

    try {
      const {
        data: {Item},
      } = await axios.post(
        'https://tf209d4mce.execute-api.us-east-1.amazonaws.com/default/load',
        {id: hash},
      );

      if (!Item) {
        toast.error('Portfolio not found.');
        return;
      }

      // Fetch required tickers
      await dispatch(
        actions.fetchTickers(
          [...Item.Positions.futures, ...Item.Positions.options].map(
            el => el.instrument,
          ),
        ),
      );

      await dispatch(
        actions.newPortfolio(
          'shared',
          hash,
          Item.Positions.futures.filter(el => futures[el.instrument]),
          Item.Positions.options.filter(el => options[el.instrument]),
        ),
      );

      return dispatch(actions.initPortfolio('shared', hash));
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

export const initPortfolio = (username, name) => {
  return async function(dispatch, getState) {
    const {
      instrument: {
        instruments: {futures, options},
      },
      portfolio: {portfolios, positions},
    } = getState();

    const portfolio = [positions, ...portfolios].find(
      por => por.username === username && por.name === name,
    );

    const f = portfolio.futures.filter(el => futures[el.instrument]);
    const o = portfolio.options.filter(el => options[el.instrument]);

    await dispatch(
      actions.fetchTickers([
        ...f.map(el => el.instrument),
        ...o.map(el => el.instrument),
      ]),
    );

    await dispatch(_switchPortfolio(portfolio.key));
    return dispatch(actions.initCompleted());
  };
};

export const clearPortfolio = () => {
  return (dispatch, getState) => {
    const {
      settings: {currency},
    } = getState();
    return dispatch(actions.deletePositions('both', currency));
  };
};

/**
 * Side effect that recalculates all PNL values for all positions
 */
export const recalculatePNL = () => {
  return (dispatch, getState) => {
    const {
      portfolio: {positions},
      settings,
      instrument: {tickers},
    } = getState();

    for (let pos of positions.futures) {
      pos.pnl =
        +FuturePLValue(
          settings.PNLCurrencyInUSD,
          pos.cur_price,
          pos.avg_price,
        ) * pos.size;
    }

    for (let pos of positions.options) {
      const ticker = tickers.options[pos.instrument];
      pos.pnl =
        +(
          OptionPLValueToday(false, 1, pos.avg_price, pos.cur_price) * pos.size
        ) * (settings.PNLCurrencyInUSD ? ticker['underlying_price'] : 1);
    }

    return dispatch(actions.replacePositions(positions));
  };
};

export const fetchedPositionUpdate = ({positions}) => {
  return async (dispatch, getState) => {
    const {
      portfolio: {
        positions: {options, futures},
      },
    } = getState();
    for (let {
      instrument_name,
      kind,
      direction,
      average_price,
      mark_price,
      size,
    } of positions) {
      if (kind === 'future') {
        const index = futures.findIndex(
          item =>
            item.instrument === instrument_name && item.verity === 'actual',
        );

        if (index >= 0) {
          await dispatch(
            actions.updateFuturePosition(instrument_name, 'actual', {
              avg_price: average_price,
              size: size,
              side: direction,
            }),
          );
        } else {
          await dispatch(actions.fetchTickers([instrument_name]));
          await dispatch(
            actions.addFuturePositions([
              {
                instrument: instrument_name,
                side: direction,
                size: size,
                avg_price: average_price,
                cur_price: mark_price,
                verity: 'actual',
              },
            ]),
          );
        }
      } else {
        const index = options.findIndex(
          item =>
            item.instrument === instrument_name && item.verity === 'actual',
        );
        if (index >= 0) {
          await dispatch(
            actions.updateOptionPosition(instrument_name, 'actual', {
              avg_price: average_price,
              size: size,
              side: direction,
            }),
          );
        } else {
          await dispatch(actions.fetchTickers([instrument_name]));
          await dispatch(
            actions.addOptionPositions([
              {
                instrument: instrument_name,
                side: direction,
                size: size,
                avg_price: average_price,
                cur_price: mark_price,
                verity: 'actual',
              },
            ]),
          );
        }
      }
    }
  };
};

export const portfolioUpdated = (recalculateScales = false) => {
  return async (dispatch, getState) => {
    const {
      graph: {plotDragged},
    } = getState();
    if (plotDragged) return;

    await dispatch(actions.calculateClosestExpiry());

    const {
      graph: {closestExpiry, daysFromToday},
      settings,
    } = getState();
    if (closestExpiry) {
      if (daysFromToday < (closestExpiry - settings.now) / daysScalar) {
        await dispatch(actions.setTimeNow(new Date().getTime()));
      } else {
        const now = new Date().getTime();
        await dispatch(actions.setTimeNow(now));
        await dispatch(
          actions.setDaysFromToday((closestExpiry - now) / daysScalar),
        );
      }
    }

    const initialized = settings.initialized;
    if (initialized) {
      const {
        graph: {xDomain},
      } = getState();

      if ((xDomain[0] === 0 && xDomain[1] === 1) || recalculateScales) {
        await dispatch(actions.calculateGraphAndPadding()); // recalculates scales
      } else {
        await dispatch(actions.calculateGraphData()); // tickers - real time messages
      }
    }
  };
};

export const portfolioUpdatedThrottled = () => {
  return {
    type: actionTypes.PORTFOLIO_UPDATED,
    meta: {
      throttle: 1000,
    },
    fn: async (dispatch, getState) => {
      await dispatch(actions.portfolioUpdated());

      const {
        graph: {plotDragged},
      } = getState();
      if (plotDragged) return;

      return dispatch(actions.calculateGraphData());
    },
  };
};
