import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: [[], []],
  closestExpiry: 0,
  daysFromToday: 0,
  xDomain: [0, 1],
  yDomain: [0, 1],
  plotDragged: false,
};

const setGraphData = (state, action) => {
  return {
    ...state,
    data: action.data,
  };
};

const setClosestExpiry = (state, action) => {
  return {
    ...state,
    closestExpiry: action.expiry,
  };
};

const setDaysFromToday = (state, action) => {
  return {
    ...state,
    daysFromToday: action.days,
  };
};

const setXDomain = (state, action) => {
  return {
    ...state,
    xDomain: action.domain,
  };
};

const setYDomain = (state, action) => {
  return {
    ...state,
    yDomain: action.domain,
  };
};

const setXYDomain = (state, {domain}) => {
  const [xDomain, yDomain] = domain;
  return {
    ...state,
    xDomain,
    yDomain,
  };
};

const setPlotDragged = (state, action) => {
  return {
    ...state,
    plotDragged: action.bool,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_PORTFOLIO:
      return initialState;
    case actionTypes.SET_CURRENCY:
      return setDaysFromToday(state, {days: 0});
    case actionTypes.SET_GRAPH_DATA:
      return setGraphData(state, action);
    case actionTypes.SET_CLOSEST_EXPIRY:
      return setClosestExpiry(state, action);
    case actionTypes.SET_DAYS_FROM_TODAY:
      return setDaysFromToday(state, action);
    case actionTypes.SET_X_DOMAIN:
      return setXDomain(state, action);
    case actionTypes.SET_Y_DOMAIN:
      return setYDomain(state, action);
    case actionTypes.SET_XY_DOMAIN:
      return setXYDomain(state, action);
    case actionTypes.SET_PLOT_DRAGGED:
      return setPlotDragged(state, action);
    default:
      return state;
  }
};

export default reducer;
