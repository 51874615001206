import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../store/actions/portfolio';

class AvgPriceLockRenderer extends React.Component {
  render() {
    if (!this.props.node.group) {
      return (
        <div
          onClick={() => {
            this.props.onToggleAvgPriceLock(
              this.props.node.data.instrument,
              this.props.node.data.kind,
              this.props.node.data.verity,
              !this.props.node.data.avg_price_locked,
            );
          }}>
          <FontAwesomeIcon
            icon={[
              'fas',
              this.props.node.data.avg_price_locked ? 'lock' : 'lock-open',
            ]}
          />
        </div>
      );
    } else {
      return '';
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleAvgPriceLock: (instrument, kind, verity, boolean) => {
      if (kind === 'option') {
        dispatch(
          actions.updateOptionPosition(
            instrument,
            verity,
            {
              avg_price_locked: boolean,
            },
            true,
            'avg_price_locked',
          ),
        );
      } else {
        dispatch(
          actions.updateFuturePosition(
            instrument,
            verity,
            {
              avg_price_locked: boolean,
            },
            true,
            'avg_price_locked',
          ),
        );
      }
    },
  };
};
export default connect(null, mapDispatchToProps)(AvgPriceLockRenderer);
