import * as d3 from 'd3';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {breakEvens} from '../../../utils/Graph';
import isEqual from 'lodash/isEqual';

export const Lines = props => {
  const {
    plotWidth,
    plotHeight,
    colorLine1,
    colorLine2,
    data,
    xDomain,
    yDomain,
    currency,
    indices,
  } = props;

  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(xDomain)
        .range([0, plotWidth]),
    [xDomain, plotWidth],
  );
  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(yDomain)
        .range([plotHeight, 0]),
    [yDomain, plotHeight],
  );

  const yBound = 2 * yScale.ticks()[yScale.ticks().length - 1];

  let line = useMemo(
    () =>
      d3
        .line()
        .x(d => xScale(d.x))
        .y(d => {
          if (yBound > 0) {
            return d.y < yBound ? yScale(d.y) : yScale(yBound);
          } else {
            return yScale(d.y);
          }
        }),
    [xScale, yScale, yBound],
  );

  const index =
    currency.toLowerCase() + '_usd' in indices
      ? indices[currency.toLowerCase() + '_usd']['price']
      : 0;

  let breakEvenValues;
  if (data[0][0]) {
    breakEvenValues = breakEvens(data);
  }

  return (
    <React.Fragment>
      <line
        className="borderTop"
        x1={0}
        x2={plotWidth}
        y1={0}
        y2={0}
        style={{
          stroke: 'var(--component-border)',
          strokeWidth: 'calc(1px / var(--pixel-divider)',
        }}
      />

      <line
        className="borderBottom"
        x1={0}
        x2={plotWidth}
        y1={plotHeight}
        y2={plotHeight}
        style={{
          stroke: 'var(--component-border)',
          strokeWidth: 'calc(1px / var(--pixel-divider)',
        }}
      />

      <line
        className="line0"
        x1={0}
        x2={plotWidth}
        y1={yScale(0)}
        y2={yScale(0)}
        style={{
          stroke: 'var(--mute-2)',
          strokeWidth: '2px',
        }}
      />

      {index && (
        <React.Fragment>
          <line
            className="DeribitIndexPrice"
            x1={xScale(index)}
            x2={xScale(index)}
            y1={0}
            y2={plotHeight}
            style={{
              stroke: 'var(--mute-2)',
              strokeWidth: '1px',
            }}
          />

          <text
            className="DeribitIndexPriceText"
            x={xScale(index) + 7}
            y={plotHeight - 10}
            style={{
              fontSize: '11px',
              fill: 'var(--mute-1)',
            }}>
            {index.toFixed(2)}
          </text>
        </React.Fragment>
      )}

      <path
        className="line1"
        d={line(data[0])}
        style={{
          fill: 'none',
          stroke: colorLine1,
          strokeWidth: '2px',
        }}
      />

      <path
        className="line2"
        d={line(data[1])}
        style={{
          fill: 'none',
          stroke: colorLine2,
          strokeWidth: '2px',
        }}
      />

      {breakEvenValues
        ? breakEvenValues[0].map((x, i) => {
            return (
              <line
                className={'expiryBE' + (i + 1)}
                key={i + 1}
                x1={xScale(x)}
                x2={xScale(x)}
                y1={0}
                y2={plotHeight * 0.05}
                style={{
                  stroke: colorLine1,
                  strokeWidth: '0.5px',
                  transform: `translate(0px, ${yScale(0) -
                    plotHeight * 0.025}px)`,
                }}
              />
            );
          })
        : null}

      {breakEvenValues
        ? breakEvenValues[1].map((x, i) => {
            return (
              <line
                className={'todayBE' + (i + 1)}
                key={i + 1}
                x1={xScale(x)}
                x2={xScale(x)}
                y1={0}
                y2={plotHeight * 0.05}
                style={{
                  stroke: colorLine2,
                  strokeWidth: '0.5px',
                  transform: `translate(0px, ${yScale(0) -
                    plotHeight * 0.025}px)`,
                }}
              />
            );
          })
        : null}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    data: state.graph.data,
    xDomain: state.graph.xDomain,
    yDomain: state.graph.yDomain,
    currency: state.settings.currency,
    indices: state.instrument.indices,
    plotDragged: state.graph.plotDragged,
  };
};

export default connect(mapStateToProps, null, null, {
  areStatePropsEqual: (next, prev) => {
    if (prev.plotDragged) {
      return true;
    }

    return (
      next.data === prev.data &&
      next.xDomain === prev.xDomain &&
      next.currency === prev.currency &&
      isEqual(next.indices, prev.indices)
    );
  },
})(Lines);
