export const setAuthLocalStorageItems = (
  testWs,
  access_token,
  expires_in,
  refresh_token,
) => {
  const expirationDate = new Date(new Date().getTime() + expires_in * 1000);
  localStorage.setItem(
    `access_token_${testWs ? 'test' : 'prod'}`,
    access_token,
  );
  localStorage.setItem(
    `expires_in_${testWs ? 'test' : 'prod'}`,
    expirationDate,
  );
  localStorage.setItem(
    `refresh_token_${testWs ? 'test' : 'prod'}`,
    refresh_token,
  );
};

export const makeid = length => {
  let result = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
