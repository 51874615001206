import shortid from 'shortid';
import * as actionTypes from '../actions/actionTypes';

const key = shortid.generate();
const initialState = {
  portfolios: [
    {
      name: 'default',
      key: key,
      futures: [],
      options: [],
      created_at: new Date().getTime(),
      isLive: false,
      username: 'anonymous',
      system_name: 'anonymous',
    },
  ],
  positions: {
    name: 'default',
    key: key,
    futures: [],
    options: [],
    created_at: new Date().getTime(),
    isLive: false,
    username: 'anonymous',
    system_name: 'anonymous',
  },
};

const addOptionPositions = (state, action) => {
  const positions = {
    ...state.positions,
    options: [...state.positions.options, ...action.payload],
  };

  const portfolios = state.portfolios.filter(
    el => el.key !== state.positions.key,
  );

  return {
    ...state,
    positions,
    portfolios: [positions, ...portfolios],
  };
};

const addFuturePositions = (state, action) => {
  const positions = {
    ...state.positions,
    futures: [...state.positions.futures, ...action.payload],
  };

  const portfolios = state.portfolios.filter(
    el => el.key !== state.positions.key,
  );

  return {
    ...state,
    positions,
    portfolios: [positions, ...portfolios],
  };
};

const updateFuturePosition = (state, action) => {
  const payloadFutures = action.payload.map(payloadFuture => {
    const stateFuture = state.positions.futures.find(
      item => item.key === payloadFuture.key,
    );

    // data is coming from tickers
    // if the lock is on, preserve state data; otherwise update with payload data
    if (!action.userAction) {
      return {
        ...payloadFuture,
        avg_price: stateFuture.avg_price_locked
          ? stateFuture.avg_price
          : payloadFuture.avg_price,
        cur_price: stateFuture.cur_price_locked
          ? stateFuture.cur_price
          : payloadFuture.cur_price,
      };
    }

    // update data based on the changes coming from the user
    let {
      avg_price_locked,
      cur_price_locked,
      avg_price,
      cur_price,
    } = payloadFuture;
    switch (action.colId) {
      case 'avg_price_locked':
        if (!avg_price_locked) {
          cur_price_locked = false;
        }
        break;
      case 'cur_price_locked':
        if (cur_price_locked) {
          avg_price_locked = true;
        }
        break;
    }

    return {
      ...payloadFuture,
      avg_price,
      cur_price,
      avg_price_locked,
      cur_price_locked,
    };
  });

  const positions = {
    ...state.positions,
    futures: [
      ...state.positions.futures.filter(
        el => !payloadFutures.map(el => el.key).includes(el.key),
      ),
    ],
  };

  if (payloadFutures[0].size !== 0) {
    positions.futures.push(...payloadFutures);
  }

  const portfolios = state.portfolios.filter(
    el => el.key !== state.positions.key,
  );

  return {
    ...state,
    positions,
    portfolios: [positions, ...portfolios],
  };
};

const updateOptionPosition = (state, action) => {
  const payloadOptions = action.payload.map(payloadOption => {
    const stateOption = state.positions.options.find(
      item => item.key === payloadOption.key,
    );

    // data is coming from tickers
    // if the lock is on, preserve state data; otherwise update with payload data
    if (!action.userAction) {
      return {
        ...payloadOption,
        avg_price: stateOption.avg_price_locked
          ? stateOption.avg_price
          : payloadOption.avg_price,
        cur_price: stateOption.cur_price_locked
          ? stateOption.cur_price
          : payloadOption.cur_price,
        iv: stateOption.cur_price_locked ? stateOption.iv : payloadOption.iv,
      };
    }

    // update data based on the changes coming from the user
    let {
      avg_price_locked,
      cur_price_locked,
      avg_price,
      cur_price,
    } = payloadOption;
    switch (action.colId) {
      case 'avg_price_locked':
        if (!avg_price_locked) {
          cur_price_locked = false;
        }
        break;
      case 'cur_price_locked':
        if (cur_price_locked) {
          avg_price_locked = true;
        }
        break;
      case 'iv':
        avg_price_locked = true;
        cur_price_locked = true;
        if (stateOption.avg_price_locked) {
          avg_price = stateOption.avg_price;
        }
        break;
    }

    return {
      ...payloadOption,
      avg_price,
      cur_price,
      avg_price_locked,
      cur_price_locked,
    };
  });

  const positions = {
    ...state.positions,
    options: [
      ...state.positions.options.filter(
        el => !payloadOptions.map(el => el.key).includes(el.key),
      ),
    ],
  };

  if (payloadOptions[0].size !== 0) {
    positions.options.push(...payloadOptions);
  }

  const portfolios = state.portfolios.filter(
    el => el.key !== state.positions.key,
  );

  return {
    ...state,
    positions,
    portfolios: [positions, ...portfolios],
  };
};

const deletePosition = (state, action) => {
  return {
    ...state,
    positions: {
      ...state.positions,
      [action.kind]: state.positions[action.kind].filter(
        el => el.key !== action.key,
      ),
    },
    portfolios: state.portfolios.map(e =>
      e.key === state.positions.key // check portfolio key
        ? {
            ...e,
            [action.kind]: e[action.kind].filter(f => f.key !== action.key),
          }
        : e,
    ),
  };
};

const fetchPositionsFromDeribitSuccess = (state, action) => {
  const {payload} = action;
  const {futures, options} = state.positions;
  const positions = payload.reduce(
    (acc, position) => {
      acc[`${position.kind}s`].push(position);
      return acc;
    },
    {futures: [], options: []},
  );

  return {
    ...state,
    positions: {
      ...state.positions,
      futures: [
        ...positions.futures,
        ...futures.filter(el => el.verity === 'simulated'),
      ],
      options: [
        ...positions.options,
        ...options.filter(el => el.verity === 'simulated'),
      ],
    },
  };
};

const portfolioIsLiveChange = (state, action) => {
  return {
    ...state,
    positions: {
      ...state.positions,
      isLive: action.bool,
    },
  };
};

const replacePositions = (state, action) => {
  const positions = {
    ...state.positions,
    ...action.data,
  };

  const portfolios = state.portfolios.filter(el => el.key !== action.data.key);

  return {
    ...state,
    positions,
    portfolios: [positions, ...portfolios],
  };
};

const deletePositions = (state, action) => {
  let {
    positions: {futures, options},
  } = state;

  if (action.verity === 'both') {
    if (action.currency === 'all') {
      futures = [];
      options = [];
    } else {
      futures = futures.filter(el => el.currency !== action.currency);
      options = options.filter(el => el.currency !== action.currency);
    }
  } else {
    if (action.currency === 'all') {
      futures = futures.filter(el => el.verity !== action.verity);
      options = options.filter(el => el.verity !== action.verity);
    } else {
      futures = futures.filter(
        el => el.verity !== action.verity && el.currency !== action.currency,
      );
      options = options.filter(
        el => el.verity !== action.verity && el.currency !== action.currency,
      );
    }
  }

  return {
    ...state,
    positions: {
      ...state.positions,
      futures,
      options,
    },
  };
};

const togglePositions = (state, action) => {
  const enableOnFind = pos => {
    // Only update positions where currency equals provided currency
    // This is necessary due to ag-grid losing track of selected rows when changing currencies
    if (pos.currency === action.currency) {
      pos.enabled = false;
    }

    const el = action.positions.find(el => el.key === pos.key);
    if (el) {
      el.enabled = true;
      pos = el;
    }
    return pos;
  };

  return {
    ...state,
    positions: {
      ...state.positions,
      futures: state.positions.futures.map(pos => enableOnFind(pos)),
      options: state.positions.options.map(pos => enableOnFind(pos)),
    },
  };
};

const switchPortfolio = (state, action) => {
  const positions = {...state.positions};
  const portfolios = [...state.portfolios];

  const setCurrent = state.portfolios.find(el => el.key === action.key);

  const index = portfolios.findIndex(
    el => el.name === positions.name && el.username === positions.username,
  );
  portfolios.splice(index, 1, positions);

  return {
    portfolios,
    positions: setCurrent,
  };
};

const newPortfolio = (state, action) => {
  return {
    ...state,
    portfolios: [
      ...state.portfolios,
      {
        name: action.id,
        key: shortid.generate(),
        futures: action.futures,
        options: action.options,
        created_at: new Date().getTime(),
        isLive: false,
        username: action.username,
      },
    ],
  };
};

const deletePortfolio = (state, action) => {
  const newState = {...state};
  if (
    newState.positions.key === action.key &&
    newState.positions.name !== 'Default'
  ) {
    newState.positions = newState.portfolios.pop();
  }

  newState.portfolios = newState.portfolios.filter(el => el.key !== action.key);

  return newState;
};

const createPortfolioForSubaccount = (state, action) => {
  const {portfolios, positions} = state;
  const portfolioNames = [
    ...portfolios.map(el => `${el.username}_${el.name}`),
    positions.name,
  ];

  const newPortfolios = action.response.reduce((acc, cur) => {
    if (
      !portfolioNames.includes(`${cur.username}_default`) &&
      cur.type !== 'main'
    ) {
      acc.push({
        name: 'default',
        key: shortid.generate(),
        futures: [],
        options: [],
        created_at: new Date().getTime(),
        isLive: false,
        username: cur.username,
        system_name: cur.system_name,
      });
    }
    return acc;
  }, []);

  return {
    ...state,
    portfolios: [...state.portfolios, ...newPortfolios],
  };
};

const setPortfolioOwner = (state, {username, system_name}) => {
  const {username: cur_username} = state.positions;

  const positions = {
    ...state.positions,
    username: username,
    system_name: system_name,
  };

  return {
    ...state,
    portfolios: [
      positions,
      ...state.portfolios.filter(el => el.username !== cur_username),
    ],
    positions,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBACCOUNTS_SUCCESS:
      return createPortfolioForSubaccount(state, action);
    case actionTypes.ADD_FUTURE_POSITIONS:
      return addFuturePositions(state, action);
    case actionTypes.ADD_OPTION_POSITIONS:
      return addOptionPositions(state, action);
    case actionTypes.DELETE_POSITION:
      return deletePosition(state, action);
    case actionTypes.REPLACE_POSITIONS:
      return replacePositions(state, action);
    case actionTypes.UPDATE_FUTURE_POSITION:
      return updateFuturePosition(state, action);
    case actionTypes.UPDATE_OPTION_POSITION:
      return updateOptionPosition(state, action);
    case actionTypes.DELETE_POSITIONS:
      return deletePositions(state, action);
    case actionTypes.TOGGLE_POSITIONS:
      return togglePositions(state, action);
    case actionTypes.NEW_PORTFOLIO:
      return newPortfolio(state, action);
    case actionTypes.SWITCH_PORTFOLIO:
      return switchPortfolio(state, action);
    case actionTypes.DELETE_PORTFOLIO:
      return deletePortfolio(state, action);
    case actionTypes.PORTFOLIO_IS_LIVE_CHANGE:
      return portfolioIsLiveChange(state, action);
    case actionTypes.FETCH_POSITIONS_FROM_DERIBIT_SUCCESS:
      return fetchPositionsFromDeribitSuccess(state, action);
    case actionTypes.SET_PORTFOLIO_OWNER:
      return setPortfolioOwner(state, action);
    default:
      return state;
  }
};

export default reducer;
