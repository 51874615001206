import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import React, {memo} from 'react';

export const Input = props => {
  return (
    <>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <Paper elevated={1}>
        <TextField
          fullWidth
          color="primary"
          variant="outlined"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          id={props.id}
          type={props.type}
          {...(props.type === 'number' && {inputMode: 'decimal'})}
        />
      </Paper>
    </>
  );
};

export default memo(Input);
