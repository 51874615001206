import React from 'react';

const bisectStyles = {
  stroke: 'rgba(150,150,150,0.75)',
  strokeWidth: '1px',
  strokeDasharray: '3',
};

export const Tooltip = props => {
  const {plotWidth, plotHeight, colorLine1, colorLine2} = props;

  return (
    <g className="focus" style={{display: 'none'}}>
      <line
        className="lineBisectX"
        x1={0}
        x2={0}
        y1={0}
        y2={plotHeight}
        style={bisectStyles}
      />
      {Array(2)
        .fill()
        .map((_, i) => {
          return (
            <line
              className={'lineBisectY' + (i + 1)}
              key={i + 1}
              x1={0}
              x2={plotWidth}
              y1={0}
              y2={0}
              style={bisectStyles}
            />
          );
        })}
      <circle className="circle-line1" r={3} style={{fill: colorLine1}} />
      <circle className="circle-line2" r={3} style={{fill: colorLine2}} />
      <text
        className="text-line1"
        style={{
          textAnchor: 'end',
          fontSize: '13px',
        }}>
        <tspan
          className="text-line1-x"
          x={-8}
          y={-4}
          style={{fill: 'var(--color-text-main)'}}
        />
        <tspan
          className="text-line1-y"
          x={-8}
          y={12}
          style={{fill: 'var(--color-text-main)'}}
        />
      </text>
      <text
        className="text-line2"
        style={{
          textAnchor: 'start',
          fontSize: '13px',
        }}>
        <tspan
          className="text-line2-x"
          x={8}
          y={-4}
          style={{fill: 'var(--color-text-main)'}}
        />
        <tspan
          className="text-line2-y"
          x={8}
          y={12}
          style={{fill: 'var(--color-text-main)'}}
        />
      </text>
    </g>
  );
};
