import {Grid, Tab, Tabs} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/styles';
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Input from '../../components/UI/Input/Input';
import SelectField from '../../components/UI/SelectField/SelectField';
import * as actions from '../../store/actions';
import classes from './PositionBuilder.module.scss';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const PositionBuilder = props => {
  const {currency} = props;
  const classes2 = useStyles();

  const [formData, setFormData] = useState({
    kind: 'options',
    instrument: {
      value: '',
      label: '',
    },
    size: '',
    amount_in: {
      value: 'USD',
      label: 'USD',
    },
    expiry: '',
    error: '',
    tab: 0,
  });

  const {kind, instrument, size, amount_in, expiry, error, tab} = formData;

  // Reset form if currency changes
  useEffect(() => {
    setFormData({
      kind: 'options',
      instrument: '',
      size: '',
      amount_in: {
        value: 'USD',
        label: 'USD',
      },
      expiry: '',
      error: '',
      tab: 0,
    });
  }, [props.currency]);

  const updateFormData = useCallback(values =>
    setFormData({...formData, ...values}),
  );

  const submitHandler = useCallback(
    side => {
      if (!isFormValid()) {
        return;
      }

      if (kind === 'options') {
        const pos = props.positions.options.find(
          el => el.instrument === instrument.value && el.verity === 'simulated',
        );
        if (pos) {
          props.onUpdateOptionPosition(instrument.value, {
            size: +pos.size + +(side === 'long' ? size : -size),
          });
        } else {
          props.onAddOptionPosition(
            currency,
            instrument.value,
            side,
            side === 'long' ? size : -size,
            +props.tickers['options'][instrument.value]['mark_price'],
          );
        }
      } else {
        const q =
          amount_in.value === 'USD'
            ? size
            : size * props.tickers['futures'][instrument.value]['mark_price'];

        const pos = props.positions.futures.find(
          el => el.instrument === instrument.value && el.verity === 'simulated',
        );
        if (pos) {
          props.onUpdateFuturePosition(instrument.value, {
            size: +pos.size + +(side === 'long' ? q : -q),
          });
        } else {
          props.onAddFuturePosition(
            currency,
            instrument.value,
            side,
            side === 'long' ? q : -q,
            +props.tickers['futures'][instrument.value]['mark_price'],
          );
        }
      }
    },
    [currency, instrument, size, amount_in, props.positions],
  );

  const isFormValid = useCallback(() => {
    if (!kind) {
      updateFormData({error: 'Please select an instrument type'});
      return false;
    } else if (!instrument.value) {
      updateFormData({error: 'Please select an instrument'});
      return false;
    } else if (!size) {
      updateFormData({error: 'Please enter a size'});
      return false;
    } else if (size < 0) {
      updateFormData({error: 'Size must be greater than 0'});
      return false;
    }

    return true;
  });

  const expirations = Object.values(props.instruments.options).reduce(
    (acc, cur) => {
      const [sym, exp] = cur.instrument_name.split('-');
      if (
        sym === currency &&
        !acc.expirations.includes(cur.expiration_timestamp)
      ) {
        acc.expirations.push(cur.expiration_timestamp);
        acc.items.push({
          value: cur.expiration_timestamp,
          label: exp,
        });
      }
      return acc;
    },
    {
      items: [],
      expirations: [],
    },
  );

  expirations.items.sort((a, b) => {
    return a.value - b.value;
  });

  expirations.expirations.sort((a, b) => {
    return a - b;
  });

  const optionInstruments = Object.values(props.instruments.options)
    .reduce((acc, cur) => {
      if (
        cur.expiration_timestamp === expiry &&
        cur.instrument_name.includes(currency)
      ) {
        acc.push({
          value: cur.instrument_name,
          label: cur.instrument_name.split(/(.*?-.*?)-/g)[2],
        });
      }
      return acc;
    }, [])
    .sort((a, b) => {
      const [strikeA] = a.label.split('-');
      const [strikeB] = b.label.split('-');
      return +strikeA - +strikeB;
    });

  const futureInstruments = Object.keys(props.instruments.futures)
    .reduce((acc, cur) => {
      if (cur.includes(currency)) {
        acc.push({
          value: cur,
          label: cur,
          expiry: props.instruments.futures[cur].expiration_timestamp,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => b.expiry - a.expiry);

  return (
    <div className={classes.PositionBuilder}>
      <Grid item xs={12}>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(event, value) => {
            updateFormData({
              tab: value,
              kind: value === 0 ? 'options' : 'futures',
              instrument: {
                value: '',
                label: '',
              },
              size: '',
            });
          }}
          classes={{
            fixed: classes.TabsFixed,
            root: classes.TabsRoot,
            indicator: classes.TabsIndicator,
          }}>
          <Tab
            disableRipple
            classes={{root: classes.TabRoot}}
            label="Options"
          />
          <Tab
            disableRipple
            classes={{root: classes.TabRoot}}
            label="Futures"
          />
        </Tabs>
      </Grid>

      <Grid container spacing={3} alignItems="flex-end">
        {kind === 'options' && (
          <>
            <Grid item xs={12}>
              <SelectField
                className={classes2.capitalize}
                label="Expiry"
                value={expiry}
                onChange={e => {
                  updateFormData({
                    expiry: +e.target.value,
                    instrument: {
                      value: '',
                      label: '',
                    },
                  });
                }}>
                {expirations.items.map(({value, label}) => (
                  <MenuItem
                    className={classes2.capitalize}
                    key={value}
                    value={value}>
                    {label
                      .match(/[a-z]+|[^a-z]+/gi)
                      .join(' ')
                      .toLowerCase()}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            {expiry && (
              <>
                <Grid item xs={12}>
                  <SelectField
                    label="Instrument"
                    value={instrument.value}
                    onChange={e => {
                      props.onFetchTicker(e.target.value);
                      updateFormData({
                        instrument: {
                          value: e.target.value,
                          label: e.target.value.split(/(.*?-.*?)-/g)[2],
                        },
                      });
                    }}>
                    {optionInstruments.map(el => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    value={size}
                    type="number"
                    onChange={e => updateFormData({size: e.target.value})}
                    label="Size"
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {kind === 'futures' && (
          <>
            <Grid item xs={12}>
              <SelectField
                label="Instrument"
                value={instrument.value}
                onChange={e => {
                  props.onFetchTicker(e.target.value);
                  updateFormData({
                    instrument: {
                      value: e.target.value,
                      label: e.target.value,
                    },
                  });
                }}>
                {futureInstruments.map(el => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  {['USD', currency].includes(amount_in.value) && (
                    <Input
                      value={size}
                      type="number"
                      pattern="\d*"
                      onChange={e => updateFormData({size: e.target.value})}
                      label="Amount"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    label="In"
                    value={amount_in.value}
                    onChange={e => {
                      updateFormData({
                        amount_in: {
                          value: e.target.value,
                          label: e.target.value,
                        },
                      });
                    }}>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value={currency}>{currency}</MenuItem>
                  </SelectField>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {error !== '' && (
          <Grid item xs={12}>
            <small>{error}</small>
            <p />
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.TotalWrapper}>
            <span className={classes.TotalWrapperLeft}>Price</span>
            <span className={classes.TotalWrapperRight}>
              {kind === 'options' &&
                (instrument.value &&
                props.tickers[kind].hasOwnProperty(instrument.value)
                  ? props.tickers[kind][instrument.value][
                      'mark_price'
                    ].toLocaleString('en-US', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })
                  : '0.0000') + ` ${props.currency}`}
              {kind === 'futures' &&
                (instrument.value &&
                props.tickers[kind].hasOwnProperty(instrument.value)
                  ? props.tickers[kind][instrument.value][
                      'mark_price'
                    ].toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0.00') + ' USD'}
            </span>
          </div>
          <div className={classes.TotalWrapper}>
            <span className={classes.TotalWrapperLeft}>Total</span>
            <span className={classes.TotalWrapperRight}>
              {kind === 'options' &&
                (instrument.value &&
                size &&
                props.tickers[kind].hasOwnProperty(instrument.value)
                  ? (
                      +size *
                      props.tickers[kind][instrument.value]['mark_price']
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })
                  : '0.0000') + ` ${props.currency}`}
              {kind === 'futures' &&
                (amount_in.label === 'USD'
                  ? (size
                      ? (+size).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '0.00') + ' USD'
                  : (size
                      ? (+size).toLocaleString('en-US', {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        })
                      : '0.0000') + ' BTC')}
            </span>
          </div>
          <div className={classes.TotalWrapper}>
            <span className={classes.TotalWrapperLeft} />
            <span className={classes.TotalWrapperRight}>
              {kind === 'options' &&
                (instrument.value &&
                size &&
                props.tickers[kind].hasOwnProperty(instrument.value)
                  ? (
                      +size *
                      props.tickers[kind][instrument.value]['mark_price'] *
                      props.tickers[kind][instrument.value]['index_price']
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0.00') + ' USD'}
              {kind === 'futures' &&
                (amount_in.label === 'USD'
                  ? (instrument.value &&
                    size &&
                    props.tickers[kind].hasOwnProperty(instrument.value)
                      ? (
                          +size /
                          props.tickers[kind][instrument.value]['mark_price']
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        })
                      : '0.0000') + ` ${props.currency}`
                  : (instrument.value &&
                    size &&
                    props.tickers[kind].hasOwnProperty(instrument.value)
                      ? (
                          +size *
                          props.tickers[kind][instrument.value]['mark_price']
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '0.00') + ' USD')}
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{backgroundColor: 'var(--deribit-bid'}}
                fullWidth
                onClick={() => submitHandler('long')}>
                LONG
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{backgroundColor: 'var(--deribit-ask'}}
                fullWidth
                onClick={() => submitHandler('short')}>
                SHORT
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <label className={classes.InfoLabel}>
            This tool is designed to simulate positions. It will not create
            actual trades.
          </label>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currency: state.settings.currency,
    instruments: state.instrument.instruments,
    tickers: state.instrument.tickers,
    positions: state.portfolio.positions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddOptionPosition: (currency, instrument, side, size, avg_price) =>
      dispatch(
        actions.addOptionPositions([
          {currency, instrument, side, size, avg_price, verity: 'simulated'},
        ]),
      ),
    onAddFuturePosition: (currency, instrument, side, size, avg_price) =>
      dispatch(
        actions.addFuturePositions([
          {currency, instrument, side, size, avg_price, verity: 'simulated'},
        ]),
      ),
    onUpdateOptionPosition: (instrument, updates) =>
      dispatch(
        actions.updateOptionPosition(instrument, 'simulated', updates, true),
      ),
    onUpdateFuturePosition: (instrument, updates) =>
      dispatch(
        actions.updateFuturePosition(instrument, 'simulated', updates, true),
      ),
    onFetchTicker: instrument => dispatch(actions.fetchTickers([instrument])),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    areStatePropsEqual: (next, prev) => {
      return (
        prev.currency === next.currency &&
        prev.instruments === next.instruments &&
        prev.positions === next.positions
      );
    },
  })(PositionBuilder),
);
