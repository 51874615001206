import * as actionTypes from '../actions/actionTypes';

const initialState = {
  indices: {},
  instruments: {
    options: {},
    futures: {},
  },
  tickers: {
    options: {},
    futures: {},
  },
  subscriptions: [],
};

const fetchIndicesSuccess = (state, action) => {
  const data = action.response;
  const indices = data.reduce(
    (acc, index) => {
      acc[index.index_name] = {
        price: index.price,
        base: index.index_name.split('_')[0].toUpperCase(),
        quote: 'USD',
      };
      return acc;
    },
    {...state.indices},
  );

  return {
    ...state,
    indices,
  };
};

const fetchInstrumentsSuccess = (state, action) => {
  const data = action.response;
  const instruments = data.reduce(
    (acc, instrument) => {
      acc[`${instrument.kind}s`][instrument.instrument_name] = instrument;
      return acc;
    },
    {...state.instruments},
  );

  return {
    ...state,
    instruments,
  };
};

const fetchTickersSuccess = (state, action) => {
  const data = action.response;
  const tickers = data.reduce(
    (acc, ticker) => {
      acc[`${ticker.underlying_price ? 'option' : 'future'}s`][
        ticker.instrument_name
      ] = ticker;
      return acc;
    },
    {...state.tickers},
  );

  return {
    ...state,
    tickers,
  };
};

const updateOptionUnderlyingTicker = (state, action) => {
  const tickers = {...state.tickers};

  for (let [key, {underlying_index}] of Object.entries(tickers.options)) {
    if (underlying_index === action.instrument) {
      tickers.options[key].underlying_price = action.ticker;
    }
  }

  return {
    ...state,
    tickers: tickers,
  };
};

const addedSubscriptions = (state, action) => {
  return {
    ...state,
    subscriptions: [...new Set([...state.subscriptions, ...action.result])],
  };
};

const deletedSubscriptions = (state, action) => {
  return {
    ...state,
    subscriptions: state.subscriptions.filter(
      el => !action.result.includes(el),
    ),
  };
};

const clearSubscriptions = state => {
  return {
    ...state,
    subscriptions: [],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INSTRUMENTS_SUCCESS:
      return fetchInstrumentsSuccess(state, action);
    case actionTypes.FETCH_TICKERS_SUCCESS:
      return fetchTickersSuccess(state, action);
    case actionTypes.UPDATE_OPTION_UNDERYLING_TICKER:
      return updateOptionUnderlyingTicker(state, action);
    case actionTypes.INSTRUMENT_SUBSCRIBE_SUCCESS:
      return addedSubscriptions(state, action);
    case actionTypes.INSTRUMENT_UNSUBSCRIBE_SUCCESS:
      return deletedSubscriptions(state, action);
    case actionTypes.FETCH_INDICES_SUCCESS:
      return fetchIndicesSuccess(state, action);
    case actionTypes.CLEAR_SUBSCRIPTIONS:
      return clearSubscriptions(state);
    default:
      return state;
  }
};

export default reducer;
