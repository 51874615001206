export const defaultLayout = {
  global: {
    splitterSize: 8,
    tabEnableClose: false,
    tabEnableRename: false,
    tabSetHeaderHeight: 33,
    tabSetTabStripHeight: 33,
  },
  layout: {
    type: 'row',
    id: '#1',
    children: [
      {
        type: 'row',
        id: '#8',
        weight: 42.733253588516746,
        children: [
          {
            type: 'row',
            id: '#9',
            weight: 69.25315227934044,
            children: [
              {
                type: 'tabset',
                id: '#5',
                weight: 16.72661870503597,
                width: 279,
                children: [
                  {
                    type: 'tab',
                    id: '#10',
                    name: 'Position Builder',
                    component: 'PositionBuilder',
                    config: {
                      moreIcon: false,
                    },
                  },
                ],
                active: true,
              },
              {
                type: 'tabset',
                id: '#6',
                weight: 83.27338129496403,
                children: [
                  {
                    type: 'tab',
                    id: '#7',
                    name: 'Profit / Loss',
                    component: 'DualLineChart',
                    config: {
                      moreIcon: true,
                    },
                  },
                  // {
                  //   type: 'tab',
                  //   id: '#71',
                  //   name: 'Implied Volatility Skews',
                  //   component: 'IVSkews',
                  //   config: {
                  //     moreIcon: false,
                  //   },
                  // },
                ],
              },
            ],
          },
          {
            type: 'tabset',
            id: '#4',
            weight: 30.746847720659552,
            children: [
              {
                type: 'tab',
                id: '#15',
                name: 'Portfolio',
                component: 'Portfolio',
                config: {
                  moreIcon: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  borders: [],
};
