const _timers = {};
const middleware = () => dispatch => action => {
  const {meta: {debounce = {}} = {}, type, fn} = action;

  if (fn) {
    action = fn;
  }

  const {
    time,
    key = type,
    cancel = false,
    leading = false,
    trailing = true,
  } = debounce;

  const shouldDebounce =
    ((time && key) || (cancel && key)) && (trailing || leading);
  const dispatchNow = leading && !_timers[key];

  const later = resolve => () => {
    if (trailing && !dispatchNow) {
      resolve(dispatch(action));
    }
    _timers[key] = null;
  };

  if (!shouldDebounce) {
    return dispatch(action);
  }

  if (_timers[key]) {
    clearTimeout(_timers[key]);
    _timers[key] = null;
  }

  if (!cancel) {
    return new Promise(resolve => {
      if (dispatchNow) {
        resolve(dispatch(action));
      }
      _timers[key] = setTimeout(later(resolve), time);
    });
  }
};

export default middleware;
