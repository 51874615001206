import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/styles';
import Menu from 'material-ui-popup-state/HoverMenu';
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';

const useStyles = makeStyles(() => ({
  button: {
    width: 21,
    height: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    boxShadow: 'none',
    border: 'none',
    margin: 6,
    marginRight: 10,
    padding: 8,
  },
  menu: {
    borderBottom: `1px solid var(--light-component-border)`,
  },
  submenu: {
    overflow: 'auto',
    maxHeight: 320,
    borderBottom: `1px solid var(--light-component-border)`,
  },
}));

const UserMenu = props => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <div style={{height: '100%'}}>
      <IconButton
        className={classes.iconButton}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <div className={classes.button}>
          <FontAwesomeIcon size="xs" icon={['fas', 'user']} />
        </div>
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {props.accounts.map(acc => (
          <MenuItem
            dense
            disableRipple
            className={classes.menu}
            key={acc.id}
            onClick={() => {
              if (acc.username !== props.username)
                props.onSwitchAccount(acc.id);
              handleClose();
            }}>
            <Grid container item>
              <Grid item>
                <Radio
                  size="small"
                  classes={{colorPrimary: classes.Radio}}
                  checked={acc.username === props.username}
                  color="primary"
                />
              </Grid>
              <ListItemText>{acc.username}</ListItemText>
            </Grid>
          </MenuItem>
        ))}
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    currency: state.settings.currency,
    accounts: state.user.accounts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSwitchAccount: accountId => dispatch(actions.switchAccount(accountId)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(UserMenu),
);
