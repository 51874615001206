import {Black76} from './PricingModels';

const daysScalar = 24 * 60 * 60 * 1000;

/*
 ** The ProfitLossValue calculator
 */
export const OptionPLValueExpiry = (
  isCall,
  isUSD,
  F,
  strike,
  isClosestExpiry,
  avg_price,
  cur_price,
) => {
  const isInTheMoney = isCall ? F > strike : F < strike;

  if (isClosestExpiry) {
    if (isInTheMoney) {
      if (isUSD) {
        return (isCall ? F - strike : strike - F) - avg_price * F;
      } else {
        // isBTC || isETH
        return (isCall ? 1 - strike / F : strike / F - 1) - avg_price;
      }
    } else {
      // isOutTheMoney
      return isUSD ? -(avg_price * F) : -avg_price;
    }
  } else {
    // isNotClosestExpiry
    return isUSD ? cur_price - avg_price * F : cur_price / F - avg_price;
  }
};

export const OptionPLValueToday = (isUSD, F, avg_price, cur_price) => {
  return isUSD ? cur_price - avg_price * F : cur_price / F - avg_price;
};

/**
 * Function that calculates the Profit & Loss values for an Option
 *
 * Parameter cum contains the cumulative values, this code might be implicit but to mitigate the need for a second
 * loop we use the previous value to calculate the sum
 */
export const OptionPLValues = (
  closestExpiry,
  daysFromToday,
  option,
  index,
  ticker,
  instrument,
  domain,
  isUSD,
  lastNow,
  cum,
) => {
  // handle slow ticker arrival
  ticker = ticker || {};

  const isCall = instrument.option_type === 'call',
    strike = instrument.strike,
    expiry = instrument.expiration_timestamp,
    isClosestExpiry = expiry === closestExpiry,
    daysToClosestExpiry = (closestExpiry - lastNow) / daysScalar,
    daysToExpiry = (expiry - lastNow) / daysScalar,
    line1Tau = isClosestExpiry ? 0 : (expiry - closestExpiry) / daysScalar,
    line2Tau =
      (isClosestExpiry ? daysToClosestExpiry : daysToExpiry) - daysFromToday;

  // assume constancy in percentage-terms and linear decay
  const spread = day => {
    // handle slow index arrival
    const denom = index || ticker.index_price;
    return (ticker.underlying_price / denom - 1) * (1 - day / daysToExpiry);
  };

  const black76 = new Black76({
    contractType: instrument.option_type,
    futureUSD: ticker.underlying_price,
    strikeUSD: strike,
    timeToExpiry: daysToExpiry,
    riskFreeRate: ticker.interest_rate,
    optionTOK: option.cur_price,
  });

  // extract and assign to the object instance the implied volatility from the current option price
  black76.vol();

  // data
  const line1 = [],
    line2 = [];

  for (let i = 0; i < domain.length; i++) {
    const I = domain[i];

    let F =
        I * (1 + spread(daysToClosestExpiry, daysToExpiry)) > 1
          ? I * (1 + spread(daysToClosestExpiry, daysToExpiry))
          : 1,
      cumLine1PLValue = 0;

    black76.setFutureUSD(F);
    black76.setTimeToExpiry(line1Tau);

    if (cum && cum[0].length > 0) {
      cumLine1PLValue = cum[0][i]['y'];
    }

    line1.push({
      x: I,
      y:
        cumLine1PLValue +
        OptionPLValueExpiry(
          isCall,
          isUSD,
          F,
          strike,
          isClosestExpiry,
          option.avg_price,
          black76.price(),
        ) *
          option.size,
    });

    F =
      I * (1 + spread(daysFromToday, daysToExpiry)) > 1
        ? I * (1 + spread(daysFromToday, daysToExpiry))
        : 1;

    let cumLine2PLValue = 0;

    black76.setFutureUSD(F);
    black76.setTimeToExpiry(line2Tau);

    if (cum && cum[1].length > 0) {
      cumLine2PLValue = cum[1][i]['y'];
    }

    line2.push({
      x: I,
      y:
        cumLine2PLValue +
        OptionPLValueToday(isUSD, F, option.avg_price, black76.price()) *
          option.size,
    });
  }

  return [line1, line2];
};

export const FuturePLValue = (isUSD, F, price) => {
  return isUSD ? F / price - 1 : 1 / price - 1 / F;
};

/**
 * Function that calculates the Profit & Loss values for a Future
 *
 * Parameter cum contains the cumulative values, this code might be implicit but to mitigate the need for a second
 * loop we use the previous value to calculate the sum
 */
export const FuturePLValues = (
  closestExpiry,
  daysFromToday,
  future,
  index,
  instrument,
  domain,
  isUSD,
  lastNow,
  cum,
) => {
  const expiry = instrument.expiration_timestamp,
    isPerpetual = future.instrument.includes('PERPETUAL'),
    daysToClosestExpiry = (closestExpiry - lastNow) / daysScalar,
    daysToExpiry = (expiry - lastNow) / daysScalar;

  let expirySpread, todaySpread;

  const spread = day => {
    const denom = index || future.cur_price;
    return (future.cur_price / denom - 1) * (1 - day / daysToExpiry);
  };

  if (isPerpetual) {
    expirySpread = 0;
    todaySpread = daysFromToday >= 1 ? 0 : spread(daysFromToday, 1);
  } else {
    expirySpread = spread(daysToClosestExpiry, daysToExpiry);
    todaySpread = spread(daysFromToday, daysToExpiry);
  }

  // data
  const line1 = [],
    line2 = [];

  for (let i = 0; i < domain.length; i++) {
    const I = domain[i];

    let F = I * (1 + expirySpread) > 1 ? I * (1 + expirySpread) : 1,
      cumLine1PLValue = 0;

    if (cum && cum[0].length > 0) {
      cumLine1PLValue = cum[0][i]['y'];
    }

    line1.push({
      x: I,
      y:
        cumLine1PLValue +
        FuturePLValue(isUSD, F, future.avg_price) * future.size,
    });

    F = I * (1 + todaySpread) > 1 ? I * (1 + todaySpread) : 1;

    let cumLine2PLValue = 0;

    if (cum && cum[1].length > 0) {
      cumLine2PLValue = cum[1][i]['y'];
    }

    line2.push({
      x: I,
      y:
        cumLine2PLValue +
        FuturePLValue(isUSD, F, future.avg_price) * future.size,
    });
  }

  return [line1, line2];
};

export const PLSumOfPositions = (
  closestExpiry,
  daysFromToday,
  positions,
  currency,
  indices,
  tickers,
  instruments,
  domain,
  isUSD,
  isNormalized,
  lastNow,
) => {
  // eslint-disable-next-line consistent-return
  const index = (() => {
    if (currency === 'BTC' && 'btc_usd' in indices) {
      return indices['btc_usd']['price'];
    } else if (currency === 'ETH' && 'eth_usd' in indices) {
      return indices['eth_usd']['price'];
    }
  })();

  const PNLAdjustment = !isNormalized
    ? null
    : [...positions.options, ...positions.futures].reduce(
        (acc, cur) => ({pnl: +acc.pnl + +cur.pnl}),
        {pnl: 0},
      ).pnl;

  let result = [[], []];

  if (positions.options.length > 0 && tickers['options']) {
    result = positions.options.reduce(
      (acc, cur) =>
        OptionPLValues(
          closestExpiry,
          daysFromToday,
          cur,
          index,
          tickers['options'][cur.instrument],
          instruments['options'][cur.instrument],
          domain,
          isUSD,
          lastNow,
          acc,
        ),
      result,
    );
  }

  if (positions.futures.length > 0) {
    result = positions.futures.reduce(
      (acc, cur) =>
        FuturePLValues(
          closestExpiry,
          daysFromToday,
          cur,
          index,
          instruments['futures'][cur.instrument],
          domain,
          isUSD,
          lastNow,
          acc,
        ),
      result,
    );
  }

  if (PNLAdjustment) {
    result.forEach(line =>
      line.forEach(point => (point['y'] -= PNLAdjustment)),
    );
  }

  return result;
};

export const breakEvens = data => {
  const expiry = [],
    today = [];

  // can this linear search be improved upon, given that the data isn't guaranteed to be sorted?
  for (let i = 0; i < data[0].length - 1; i++) {
    if (Math.sign(data[0][i]['y']) !== Math.sign(data[0][i + 1]['y'])) {
      expiry.push((data[0][i]['x'] + data[0][i + 1]['x']) / 2);
    }

    if (Math.sign(data[1][i]['y']) !== Math.sign(data[1][i + 1]['y'])) {
      today.push((data[1][i]['x'] + data[1][i + 1]['x']) / 2);
    }
  }

  return [expiry, today];
};
