import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import {makeStyles} from '@material-ui/styles';
import FlexLayout from 'flexlayout-react';
import React from 'react';
import {connect} from 'react-redux';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import {defaultLayout} from '../../flexlayout';
import '../../flexlayout.scss';
import * as actions from '../../store/actions';
import DualLineChart from '../Graph/DualLineChart';
import Portfolio from '../Portfolio/Portfolio';
import PositionBuilder from '../PositionBuilder/PositionBuilder';
import IVSkews from '../IVSkews/IVSkews';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
  icon: {
    padding: 6,
  },
  rotate: {
    transform: 'rotate(45deg)',
  },
}));

const factory = node => {
  const component = node.getComponent();

  switch (component) {
    case 'PositionBuilder':
      return <PositionBuilder />;
    case 'Portfolio':
      return <Portfolio />;
    case 'DualLineChart':
      return <DualLineChart node={node} />;
    case 'IVSkews':
      return <IVSkews node={node} />;
    default:
      return <div />;
  }
};

const onRenderTabSet = (node, tabSetNameContent, model, classes, props) => {
  tabSetNameContent.buttons = [
    <IconButton
      key={0}
      className={classes.icon}
      onClick={() =>
        model.doAction(FlexLayout.Actions.maximizeToggle(node.getId()))
      }>
      {node.isMaximized() ? (
        <UnfoldLessIcon className={classes.rotate} fontSize="small" />
      ) : (
        <UnfoldMoreIcon className={classes.rotate} fontSize="small" />
      )}
    </IconButton>,
  ];

  if (node.getSelectedNode().getConfig()['moreIcon']) {
    // props.onAddModalConfig(node.getSelectedNode().getName());

    tabSetNameContent.buttons.push(
      <IconButton
        key={1}
        className={classes.icon}
        onClick={e =>
          props.onToggleModalConfig(
            node.getSelectedNode().getName(),
            e.clientX,
            e.clientY,
          )
        }>
        <MoreVertIcon fontSize="small" />
      </IconButton>,
    );
  }
};

const onRenderTab = (node, tabNameContent, model, classes, props) => {
  if (node.getName() === 'Portfolio' && props.username) {
    tabNameContent.leading = 'Portfolio';
    tabNameContent.content = (() => {
      if (!['anonymous', 'shared'].includes(props.positions.username)) {
        return (
          <Chip
            clickable
            onMouseDown={e => {
              e.stopPropagation();
              props.onToggleIsLive(!props.positions.isLive);
            }}
            size="small"
            variant="outlined"
            label="Sync portfolio"
            icon={
              <FiberManualRecordRoundedIcon
                style={{
                  color: props.positions.isLive
                    ? 'var(--deribit-bid)'
                    : 'var(--deribit-ask)',
                }}
              />
            }
            onDelete={() => {}}
            deleteIcon={
              <Tooltip
                title="Synchronizes your actual portfolio on the trade platform with the actual positions shown below"
                aria-label="sync">
                <InfoOutlinedIcon />
              </Tooltip>
            }
          />
        );
      } else {
        return null;
      }
    })();
  }
};

const DesktopLayout = props => {
  const classes = useStyles();
  const model = FlexLayout.Model.fromJson(props.flexLayout || defaultLayout);

  return (
    <FlexLayout.Layout
      tabIcon
      onModelChange={layout => props.onSaveFlexLayout(layout.toJson())}
      model={model}
      factory={factory}
      onRenderTabSet={(node, tabSetNameContent) =>
        onRenderTabSet(node, tabSetNameContent, model, classes, props)
      }
      onRenderTab={(node, tabNameContent) =>
        onRenderTab(node, tabNameContent, model, classes, props)
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    flexLayout: state.settings.flexLayout,
    positions: state.portfolio.positions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveFlexLayout: layout => dispatch(actions.saveFlexLayout(layout)),
    onToggleModalConfig: (id, x, y) =>
      dispatch(actions.toggleModalConfig(id, x, y)),
    onAddModalConfig: id => dispatch(actions.addModalConfig(id)),
    onToggleIsLive: bool => dispatch(actions.portfolioIsLiveChange(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: (next, prev) => {
    return (
      next.username === prev.username &&
      next.flexLayout === prev.flexLayout &&
      next.positions.username === prev.positions.username &&
      next.positions.isLive === prev.positions.isLive
    );
  },
})(DesktopLayout);
