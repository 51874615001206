import * as d3 from 'd3';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';

const textStyles = {
  textAnchor: 'middle',
  fill: 'var(--mute-1)',
};

export const Axes = props => {
  const {
    plotWidth,
    plotHeight,
    xDomain,
    yDomain,
    currency,
    PNLCurrencyInUSD,
  } = props;

  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(xDomain)
        .range([0, plotWidth]),
    [xDomain, plotWidth],
  );

  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain(yDomain)
        .range([plotHeight, 0]),
    [yDomain, plotHeight],
  );

  const xAxis = useMemo(
    () =>
      d3
        .axisBottom(xScale)
        .ticks(8)
        .tickSize(-plotHeight)
        .tickPadding(8)
        .tickFormat(d3.format('.3s')),
    [xScale, plotHeight],
  );

  const yAxis = useMemo(
    () =>
      d3
        .axisRight(yScale)
        .ticks(10)
        .tickSize(-plotWidth)
        .tickPadding(7)
        .tickFormat(d3.format('.2s')),
    [yScale, plotWidth],
  );

  d3.select('.x-axis').call(xAxis);
  d3.select('.y-axis').call(yAxis);

  return (
    <React.Fragment>
      <g
        className="x-axis"
        transform={`translate(0, ${plotHeight})`}
        style={{pointerEvents: 'none'}}
      />

      <text
        className="x-axis-label"
        transform={`translate(${plotWidth - 95}, ${plotHeight - 14})`}
        style={{
          ...textStyles,
          fontSize: '13px',
        }}>
        {'Deribit Index Price \xA0 (USD)'}
      </text>

      <g
        className="y-axis"
        transform={`translate(${plotWidth}, 0)`}
        style={{pointerEvents: 'none'}}
      />

      <text
        className="y-axis-label"
        transform={`rotate(90)translate(${75}, ${25 - plotWidth})`}
        style={{
          ...textStyles,
          fontSize: '14px',
        }}>{`Profit / Loss \xA0 (${
        PNLCurrencyInUSD ? 'USD' : currency
      })`}</text>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  xDomain: state.graph.xDomain,
  yDomain: state.graph.yDomain,
  currency: state.settings.currency,
  PNLCurrencyInUSD: state.settings.PNLCurrencyInUSD,
});

export default connect(mapStateToProps)(Axes);
