import * as d3 from 'd3';
import {XDomain, YDomain} from '../../utils/Domains';
import {PLSumOfPositions} from '../../utils/Graph';
import * as actionTypes from './actionTypes';
import * as actions from './index';

export const setGraphDimensions = dimensions => {
  return {
    type: actionTypes.SET_GRAPH_DIMENSIONS,
    dimensions: dimensions,
  };
};

export const setGraphData = data => {
  return {
    type: actionTypes.SET_GRAPH_DATA,
    data: data,
    meta: {
      throttle: 100,
    },
  };
};

export const setClosestExpiry = expiry => {
  return {
    type: actionTypes.SET_CLOSEST_EXPIRY,
    expiry: expiry,
    meta: {
      throttle: 100,
    },
  };
};

export const setDaysFromToday = days => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.SET_DAYS_FROM_TODAY,
      days: days,
      meta: {
        throttle: 100,
      },
    });

    return dispatch(actions.calculateGraphData());
  };
};

export const setXDomain = domain => {
  return async dispatch => {
    await dispatch({
      type: actionTypes.SET_X_DOMAIN,
      domain: domain,
    });

    return dispatch(actions.calculateGraphData());
  };
};

export const setYDomain = domain => {
  return {
    type: actionTypes.SET_Y_DOMAIN,
    domain: domain,
  };
};

export const setPlotDragged = bool => {
  return {
    type: actionTypes.SET_PLOT_DRAGGED,
    bool: bool,
  };
};

export const calculateGraphData = () => {
  return (dispatch, getState) => {
    let {
      graph: {closestExpiry, daysFromToday, xDomain},
      settings: {currency, PNLCurrencyInUSD, PNLNormalized, now},
      portfolio: {positions: portfolio},
      instrument: {indices, tickers, instruments},
    } = getState();

    const options = portfolio.options.filter(
        el => el.currency === currency && el.enabled,
      ),
      futures = portfolio.futures.filter(
        el => el.currency === currency && el.enabled,
      );

    const data = PLSumOfPositions(
      closestExpiry,
      daysFromToday,
      {
        options,
        futures,
      },
      currency,
      indices,
      tickers,
      instruments,
      d3.range(
        ...xDomain,
        (xDomain[1] - xDomain[0]) /
          Math.max(300, Math.ceil(500 - (options.length + futures.length) * 2)),
      ),
      PNLCurrencyInUSD,
      PNLNormalized,
      now,
    );

    return dispatch(actions.setGraphData(data));
  };
};

export const calculateGraphAndPadding = () => {
  return async (dispatch, getState) => {
    let {
      graph: {data, closestExpiry, daysFromToday, xDomain, yDomain},
      settings: {currency, PNLCurrencyInUSD, PNLNormalized, now},
      portfolio: {positions: portfolio},
      instrument: {indices, tickers, instruments},
    } = getState();

    const options = portfolio.options.filter(
        el => el.currency === currency && el.enabled,
      ),
      futures = portfolio.futures.filter(
        el => el.currency === currency && el.enabled,
      );

    if (options.length + futures.length > 0) {
      xDomain = XDomain(tickers, instruments, currency);

      data = PLSumOfPositions(
        closestExpiry,
        daysFromToday,
        {
          options,
          futures,
        },
        currency,
        indices,
        tickers,
        instruments,
        d3.range(
          ...xDomain,
          (xDomain[1] - xDomain[0]) /
            Math.max(
              300,
              Math.ceil(500 - (options.length + futures.length) * 2),
            ),
        ),
        PNLCurrencyInUSD,
        PNLNormalized,
        now,
      );

      yDomain = YDomain(
        PNLCurrencyInUSD,
        {
          options,
          futures,
        },
        data,
      );

      await dispatch({
        type: actionTypes.SET_XY_DOMAIN,
        domain: [xDomain, yDomain],
      });
    } else {
      data = [[], []];
    }

    return dispatch(actions.setGraphData(data));
  };
};

export const calculateClosestExpiry = () => {
  return (dispatch, getState) => {
    const {
      portfolio: {positions: portfolio},
      instrument: {instruments},
      settings: {currency},
    } = getState();

    const closestExpiry = Math.min(
      portfolio.options
        .filter(el => el.currency === currency && el.enabled)
        .reduce(
          (acc, cur) =>
            Math.min(
              acc,
              instruments.options[cur.instrument].expiration_timestamp,
            ),
          Number.MAX_VALUE,
        ),
      portfolio.futures
        .filter(el => el.currency === currency && el.enabled)
        .reduce(
          (acc, cur) =>
            Math.min(
              acc,
              instruments.futures[cur.instrument].expiration_timestamp,
            ),
          Number.MAX_VALUE,
        ),
    );

    return dispatch(
      actions.setClosestExpiry(
        closestExpiry === Number.MAX_VALUE ? 0 : closestExpiry,
      ),
    );
  };
};
