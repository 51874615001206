import {makeid} from '../../utils/Auth';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  testWs: false,
  wsUrl: 'wss://www.deribit.com/ws/api/v2/',
  clientId: 'VhICMVdG',
  open: false,
  reconnecting: false,
  sessionId: makeid(6),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WEBSOCKET:CONNECT':
      return {
        ...state,
      };
    case 'WEBSOCKET:OPEN':
      return {
        ...state,
        open: true,
      };
    case 'WEBSOCKET:CLOSED':
      return {
        ...state,
        open: false,
      };
    case 'WEBSOCKET:BEGIN_RECONNECT':
      return {
        ...state,
        reconnecting: true,
      };
    case 'WEBSOCKET:RECONNECTED':
      return {
        ...state,
        reconnecting: false,
      };
    case actionTypes.SWITCH_ENVIRONMENT:
      return {
        ...state,
        testWs: !state.testWs,
        wsUrl: `wss://${state.testWs ? 'www' : 'test'}.deribit.com/ws/api/v2/`,
        clientId: state.testWs ? 'VhICMVdG' : '2f2vQ_vi',
      };
    default:
      return state;
  }
};

export default reducer;
