import FormLabel from '@material-ui/core/FormLabel';
import Hidden from '@material-ui/core/Hidden';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import React, {memo} from 'react';

const SelectField = props => {
  const {onChange, label, children, value, className} = props;
  let curValue = value || 'default';

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Paper elevated={1}>
        <Hidden mdUp implementation="css">
          <NativeSelect
            className={className}
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={curValue}>
            <option disabled value={'default'} />
            {React.Children.map(props.children, child => (
              <option value={child.props.value} label={child.props.children}>
                {child.props.children}
              </option>
            ))}
          </NativeSelect>
        </Hidden>
        <Hidden smDown implementation="css">
          <Select
            className={className}
            fullWidth
            variant="outlined"
            onChange={onChange}
            value={value}>
            {children}
          </Select>
        </Hidden>
      </Paper>
    </>
  );
};

export default memo(SelectField);
