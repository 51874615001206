import * as actionTypes from './actionTypes';
import * as actions from './index';

export const fetchedUserData = (currency, data) => {
  return {
    type: actionTypes.FETCHED_USER_DATA,
    currency: currency,
    data: data,
  };
};

export const deleteUserData = () => {
  return {
    type: actionTypes.DELETE_USER_DATA,
  };
};

export const fetchIndices = currencies => {
  return async dispatch => {
    try {
      dispatch({type: actionTypes.FETCH_INDICES_REQUEST});

      const responses = await Promise.all(
        currencies.map(currency =>
          dispatch(actions.send('public/get_index', {currency})),
        ),
      );

      return dispatch({
        type: actionTypes.FETCH_INDICES_SUCCESS,
        response: responses.flat(),
      });
    } catch (error) {
      dispatch(actions.error(error));
    }
  };
};

export const fetchSubaccounts = () => {
  return async dispatch => {
    // const {
    //   auth: {account_type},
    // } = getState();
    // if (account_type !== 'main') return;

    try {
      dispatch({type: actionTypes.FETCH_SUBACCOUNTS_REQUEST});
      const response = await dispatch(
        actions.send('private/get_subaccounts', {with_portfolio: true}),
      );

      return dispatch({
        type: actionTypes.FETCH_SUBACCOUNTS_SUCCESS,
        response,
      });
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

export const fetchAccountSummary = () => {
  return async (dispatch, getState) => {
    const {
      auth: {username},
    } = getState();

    try {
      dispatch({type: actionTypes.FETCH_ACCOUNT_SUMMARY_REQUEST});
      const calls = [
        {currency: 'BTC', extended: true},
        {currency: 'ETH', extended: true},
      ];

      const responses = await Promise.all(
        calls.map(({currency, extended}) =>
          dispatch(
            actions.send('private/get_account_summary', {currency, extended}),
          ),
        ),
      );

      if (username === 'anonymous') {
        await dispatch({
          type: actionTypes.SET_PORTFOLIO_OWNER,
          username: responses[0].username,
          system_name: responses[0].system_name,
        });
      }
      return dispatch({
        type: actionTypes.FETCH_ACCOUNT_SUMMARY_SUCCESS,
        response: responses.flat(),
      });
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};
