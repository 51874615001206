import * as actionTypes from '../actions/actionTypes';

const initialState = {
  Portfolio: {
    x: 0,
    y: 0,
    open: false,
  },
  'Profit / Loss': {
    x: 0,
    y: 0,
    open: false,
  },
};

const addModalConfig = (state, {id}) => {
  return {
    ...state,
    [id]: {
      x: 0,
      y: 0,
      open: false,
    },
  };
};

const toggleModalConfig = (state, {id, x, y}) => {
  return {
    ...state,
    [id]: {
      x: x || 0,
      y: y || 0,
      open: !state[id].open,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MODAL_CONFIG:
      return addModalConfig(state, action);
    case actionTypes.TOGGLE_MODAL_CONFIG:
      return toggleModalConfig(state, action);
    default:
      return state;
  }
};

export default reducer;
