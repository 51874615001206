import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import classes from './CustomHeader.module.scss';

class CustomHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: '',
    };

    props.column.addEventListener('sortChanged', this.onSortChanged);
  }

  onSortChanged = () => {
    if (this.props.column.isSortAscending()) {
      this.setState({sortType: 'asc'});
    } else if (this.props.column.isSortDescending()) {
      this.setState({sortType: 'desc'});
    } else {
      this.setState({sortType: ''});
    }
  };

  setSort = () => {
    let newSortType = '';

    if (this.state.sortType === '') {
      newSortType = 'asc';
    } else if (this.state.sortType === 'asc') {
      newSortType = 'desc';
    }

    this.props.setSort(newSortType);
  };

  componentWillUnmount() {
    this.props.column.removeEventListener('sortChanged', this.onSortChanged);
  }

  render() {
    return (
      <div
        className={[classes.CustomHeader, 'ag-cell-label-container'].join(' ')}>
        <div
          onClick={this.setSort}
          className={[classes.SortHeader, 'ag-header-cell-label'].join(' ')}>
          {this.state.sortType === 'asc' && (
            <div className={classes.SortIcon}>
              <FontAwesomeIcon icon={['fas', 'long-arrow-alt-up']} />
            </div>
          )}
          {this.state.sortType === 'desc' && (
            <div className={classes.SortIcon}>
              <FontAwesomeIcon icon={['fas', 'long-arrow-alt-down']} />
            </div>
          )}
          <Tooltip title={this.props.tooltipContent}>
            <span>{this.props.displayName}</span>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default CustomHeader;
