import Popover from '@material-ui/core/Popover';
import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';

const ModalConfig = ({onToggleModalConfig, id, children, config}) => {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{
        top: config.y,
        left: config.x,
      }}
      open={config.open}
      onClose={() => onToggleModalConfig(id)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      {children}
    </Popover>
  );
};

const mapStateToProps = (state, props) => {
  return {
    config: state.modalConfig[props.id],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleModalConfig: id => dispatch(actions.toggleModalConfig(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfig);
