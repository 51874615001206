import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {connect} from 'react-redux';
import Tooltip from '../../components/UI/Tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  statsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    borderRight: `1px solid var(--component-border)`,
  },
  suffix: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    padding: 0,
    display: 'inline-block',
  },
  stat: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  green: {
    color: 'var(--deribit-bid)',
  },
  red: {
    color: 'var(--deribit-ask)',
  },
}));

const Stats = props => {
  const classes = useStyles();

  const {currency, positions, PNLCurrencyInUSD} = props;

  const portfolio = [...positions.options, ...positions.futures].filter(
    el => el.currency === currency && el.enabled,
  );

  const sum = portfolio.reduce(
    (acc, cur) => ({
      pnl: +acc.pnl + +cur.pnl,
      delta:
        +acc.delta +
        ((cur.kind === 'future'
          ? +cur.size / +cur.cur_price
          : +cur.delta - +cur.cur_price * +cur.size) || 0),
      gamma: +acc.gamma + (+cur.gamma || 0),
      theta: +acc.theta + (+cur.theta || 0),
      vega: +acc.vega + (+cur.vega || 0),
    }),
    {
      pnl: 0,
      tDelta: 0,
      delta: 0,
      gamma: 0,
      theta: 0,
      vega: 0,
    },
  );

  return (
    <Grid container wrap="nowrap">
      {(props.username || props.positions.username === 'shared') && (
        <Grid item className={classes.statsContainer}>
          <Grid container wrap="nowrap">
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item>
                <Typography variant="button" color="textSecondary">
                  Username
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="span">
                  {props.positions.username === 'shared'
                    ? 'shared'
                    : props.username}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item xs={12}>
                <Typography variant="button" color="textSecondary">
                  Portfolio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="span">
                  {props.positions.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.statsContainer}>
        <Grid container wrap="nowrap">
          <Grid
            wrap="nowrap"
            container
            direction="column"
            className={classes.stat}>
            <Grid item xs={12}>
              <Typography variant="button" color="textSecondary">
                Profit / Loss
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="span"
                className={sum.pnl >= 0 ? classes.green : classes.red}>
                {Math.abs(sum.pnl).toLocaleString('en-US', {
                  minimumFractionDigits: PNLCurrencyInUSD ? 2 : 4,
                  maximumFractionDigits: PNLCurrencyInUSD ? 2 : 4,
                })}
                <Typography
                  variant="subtitle2"
                  component="small"
                  className={classes.suffix}>
                  {PNLCurrencyInUSD ? 'USD' : currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Tooltip
            arrow
            title="The sum of all deltas, where each future delta is defined as: size / mark_price, and each option delta is defined as: (delta - mark price) * size.">
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item xs={12}>
                <Typography variant="button" color="textSecondary">
                  Total Delta (δ)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="span">
                  {sum.delta.toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
          <Tooltip
            arrow
            title="The rate of change of the sum of all option deltas due to a unit-change in the underlying asset's price.">
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item xs={12}>
                <Typography variant="button" color="textSecondary">
                  GAMMA (γ)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="span">
                  {sum.gamma.toLocaleString('en-US', {
                    minimumFractionDigits: 5,
                    maximumFractionDigits: 5,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
          <Tooltip
            arrow
            title="The rate of change of the sum of all option prices due to the passage of one day.">
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item xs={12}>
                <Typography variant="button" color="textSecondary">
                  THETA (θ)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="span">
                  {sum.theta.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
          <Tooltip
            arrow
            title="The rate of change of the sum of all option prices due to a one percentage-point change in the implied volatility of each option.">
            <Grid
              wrap="nowrap"
              container
              direction="column"
              className={classes.stat}>
              <Grid item xs={12}>
                <Typography variant="button" color="textSecondary">
                  VEGA (ν)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="span">
                  {sum.vega.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      {props.username && props.positions.username !== 'anonymous' && (
        <Grid item className={classes.statsContainer}>
          <Grid container wrap="nowrap">
            {props.equity !== undefined && (
              <Grid
                wrap="nowrap"
                container
                direction="column"
                className={classes.stat}>
                <Grid item xs={12}>
                  <Typography variant="button" color="textSecondary">
                    Equity
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="span">
                    {props.equity.toLocaleString('en-US', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    <Typography
                      variant="subtitle2"
                      component="small"
                      className={classes.suffix}>
                      {currency}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {props.equity !== undefined && (
              <Grid
                wrap="nowrap"
                container
                direction="column"
                className={classes.stat}>
                <Grid item xs={12}>
                  <Typography variant="button" color="textSecondary">
                    Margin balance
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="span">
                    {props.marginBalance.toLocaleString('en-US', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    <Typography
                      variant="subtitle2"
                      component="small"
                      className={classes.suffix}>
                      {currency}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  const acc = state.user.accounts.find(
    el => el.username === state.auth.username,
  );

  let marginBalance = 0,
    equity = 0;

  if (acc) {
    marginBalance =
      acc.portfolio[state.settings.currency.toLowerCase()].margin_balance;
    equity = acc.portfolio[state.settings.currency.toLowerCase()].equity;
  }

  return {
    username: state.auth.username,
    currency: state.settings.currency,
    positions: state.portfolio.positions,
    PNLCurrencyInUSD: state.settings.PNLCurrencyInUSD,
    marginBalance: marginBalance,
    equity: equity,
  };
};

export default connect(mapStateToProps)(Stats);
