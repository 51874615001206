import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';

let _websocket;
// let _calls = 0;
let _requestId = 0;
let _responsesHandler = [];

const middleware = ({dispatch}) => next => action => {
  switch (action.type) {
    case 'WEBSOCKET:CONNECT':
      _websocket = new WebSocket(action.payload.url);

      // Attach the callbacks
      _websocket.onopen = () => dispatch(actions.open());
      _websocket.onclose = event =>
        dispatch({type: 'WEBSOCKET:CLOSE', payload: event});
      _websocket.onmessage = event =>
        dispatch({type: 'WEBSOCKET:MESSAGE', payload: event});

      break;

    // User request to send a message
    case 'WEBSOCKET:SEND':
      const request = {
        jsonrpc: '2.0',
        id: _requestId++, // we look for this number when answer comes
        method: action.payload.method,
        params: action.payload.params,
      };

      try {
        // _calls++;
        _websocket.send(JSON.stringify(request));
        if (action.meta && action.meta.synchronous) {
          return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
              console.warn('API call timeout!', action);
              reject(request.id, 'Timeout');
              dispatch(actions.disconnect());
            }, 10000);

            _responsesHandler[request.id] = [resolve, reject, timeout, request];
          });
        }
      } catch (err) {
        console.warn('WS send ERROR', err);
      }

      break;

    case 'WEBSOCKET:MESSAGE':
      const response = JSON.parse(action.payload.data);
      const error = response.error;

      if (response.method === 'subscription' && response.params) {
        if (response.params.channel === 'user.changes.any.any.100ms') {
          dispatch(actions.fetchedPositionUpdate(response.params.data));
        }

        if (response.params.channel.includes('ticker')) {
          dispatch({
            type: actionTypes.FETCH_TICKERS_SUCCESS,
            response: [response.params.data],
          });
          dispatch(actions.updatePositionsWithTicker(response.params.data));
        }

        if (response.params.channel.includes('price_index')) {
          dispatch({
            type: actionTypes.FETCH_INDICES_SUCCESS,
            response: [response.params.data],
          });
        }

        // TODO
        if (response.params.channel.includes('user')) {
          dispatch(
            actions.fetchedUserData(
              response.params.channel.split('.')[2].toUpperCase(),
              response.params.data,
            ),
          );
        }
      }

      if (_responsesHandler[response.id]) {
        const [resolve, reject, timeout] = _responsesHandler[response.id];

        clearTimeout(timeout);

        if (error) {
          // TODO handle error
          reject(error);
        } else {
          resolve(response.result);
        }
      }

      delete _responsesHandler[response.id];
      break;

    // User request to disconnect
    case 'WEBSOCKET:DISCONNECT':
      _websocket.close();
      break;

    case 'WEBSOCKET:CLOSE':
      dispatch(actions.clearSubscriptions());
      break;

    default:
      break;
  }

  return next(action);
};

export default middleware;
