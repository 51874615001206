export const arraySum = a => {
  return a.reduce((acc, cur) => acc + cur, 0);
};

export const dotProduct = (a1, a2) => {
  let s = 0;
  for (let i = 0; i < a1.length; i++) {
    s += a1[i] * a2[i];
  }
  return s;
};
