import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ModalConfig from '../../components/UI/ModalConfig/ModalConfig';
import * as actions from '../../store/actions';
import classes from '../Graph/DualLineChart.module.scss';
import './Portfolio.scss';
import AvgPriceLockRenderer from './Renderers/AvgPriceLockRenderer';
import CurPriceLockRenderer from './Renderers/CurPriceLockRenderer';
import CustomHeader from './Renderers/CustomHeader';
import tooltipMap from './Tooltips/tooltipTextMap';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.onGridReady = this.onGridReady.bind(this);

    this.state = {
      portfolioMenu: false,
      editAlertDialogOpen: false,
      editAlertDialogConfirm: () => {},
      columnDefs: this.getColDefs(),
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: [],
        suppressMovable: true,
      },
      autoGroupColumnDef: {
        headerName: 'Instrument',
        field: 'instrument',
        width: 280,
        minWidth: 270,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {checkbox: true},
        comparator: instrumentComparator,
        cellStyle: params => {
          if (!params.node.group) {
            if (params.node.data.side === 'short' && params.node.data.enabled) {
              return {color: 'var(--deribit-ask)'};
            }
            if (params.node.data.side === 'long' && params.node.data.enabled) {
              return {color: 'var(--deribit-bid)'};
            }
          }
          return {color: 'var(--mute-2)'};
        },
      },
      rowClassRules: {
        long: params => {
          if (!params.node.group) {
            return params.data.size > 0;
          } else {
            return params.node.aggData.pnl > 0;
          }
        },
        short: params => {
          if (!params.node.group) {
            return params.data.size < 0;
          } else {
            return params.node.aggData.pnl < 0;
          }
        },
        neutral: params => {
          if (!params.node.group) {
            return params.data.size === 0;
          } else {
            return params.node.aggData.pnl === 0;
          }
        },
      },
    };
  }

  getColDefs() {
    return [
      {
        field: 'verity',
        headerName: 'Verity',
        hide: true,
        rowGroupIndex: 0,
        valueFormatter: ({value}) =>
          value ? value[0].toUpperCase() + value.substr(1) + ' Positions' : '',
      },
      {
        field: 'kind',
        headerName: 'Instrument Type',
        hide: true,
        rowGroupIndex: 1,
        valueFormatter: ({value}) =>
          value ? value[0].toUpperCase() + value.substr(1) + 's' : '',
      },
      {
        field: 'expiration',
        headerName: 'Expiration',
        hide: true,
        rowGroupIndex: 2,
        valueFormatter: ({value}) =>
          new Date(+value)
            .toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: '2-digit',
            })
            .toUpperCase(),
      },
      {
        field: 'side',
        headerName: 'Side',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: ['long', 'short'],
        },
        minWidth: 52,
        width: 50,
        cellStyle: params => {
          if (params.node.group) {
            if (params.value === 'short') {
              return {color: 'var(--deribit-ask)'};
            }
            if (params.value === 'long') {
              return {color: 'var(--deribit-bid)'};
            }
          } else {
            if (params.value === 'short' && params.node.data.enabled) {
              return {color: 'var(--deribit-ask)'};
            }
            if (params.value === 'long' && params.node.data.enabled) {
              return {color: 'var(--deribit-bid)'};
            }
          }
          return {color: 'var(--mute-3)'};
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        editable: true,
        type: 'numericColumn',
        valueFormatter: ({value, data}) =>
          currencyValueFormatter('size', this.props.currency, value, data),
        width: 120,
        minWidth: 120,
      },
      {
        field: 'avg_price',
        headerName: 'Avg. Price',
        editable: true,
        type: 'numericColumn',
        valueFormatter: ({value, data}) =>
          currencyValueFormatter('avg_price', this.props.currency, value, data),
        width: 120,
        minWidth: 120,
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['avg_price']}
            tooltipHeader="Average Price"
          />
        ),
      },
      {
        field: 'avg_price_locked',
        headerName: '',
        editable: false,
        sortable: false,
        resizable: false,
        cellRendererFramework: AvgPriceLockRenderer,
        width: 35,
        minWidth: 35,
      },
      {
        field: 'cur_price',
        headerName: 'Mark Price',
        editable: true,
        type: 'numericColumn',
        valueFormatter: ({value, data}) =>
          currencyValueFormatter('cur_price', this.props.currency, value, data),
        width: 120,
        minWidth: 120,
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['cur_price']}
            tooltipHeader="Mark Price"
          />
        ),
      },
      {
        field: 'cur_price_locked',
        headerName: '',
        editable: false,
        sortable: false,
        resizable: false,
        cellRendererFramework: CurPriceLockRenderer,
        width: 35,
        minWidth: 35,
      },
      {
        field: 'pnl',
        headerName: 'PNL',
        headerComponentParams: () => ({
          currency: this.props.PNLCurrencyInUSD ? 'USD' : this.props.currency,
        }),
        editable: false,
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({value, data}) =>
          currencyValueFormatter('pnl', this.props.currency, value, data),
        width: 100,
        minWidth: 100,
        cellStyle: params => {
          if (params.node.group) {
            if (params.value < 0) {
              return {color: 'var(--deribit-ask)'};
            }
            if (params.value > 0) {
              return {color: 'var(--deribit-bid)'};
            }
            if (+params.value === 0) {
              return {color: 'var(--color-text-main)'};
            }
          } else {
            if (params.value < 0 && params.node.data.enabled) {
              return {color: 'var(--deribit-ask)'};
            }
            if (params.value > 0 && params.node.data.enabled) {
              return {color: 'var(--deribit-bid)'};
            }
            if (+params.value === 0) {
              return {color: 'var(--color-text-main)'};
            }
          }
          return {color: 'var(--mute-3)'};
        },
      },
      {
        field: 'iv',
        headerName: 'IV',
        editable: true,
        type: 'numericColumn',
        width: 60,
        minWidth: 60,
        valueFormatter: ({value}) =>
          value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['iv']}
            tooltipHeader="IV"
          />
        ),
      },
      {
        field: 'delta',
        headerName: 'Delta',
        type: 'numericColumn',
        width: 90,
        minWidth: 90,
        valueFormatter: ({value}) =>
          value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '0.00',
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['delta']}
            tooltipHeader="Delta"
          />
        ),
      },
      {
        field: 'gamma',
        headerName: 'Gamma',
        type: 'numericColumn',
        width: 90,
        minWidth: 90,
        valueFormatter: ({value}) =>
          value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              })
            : '',
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['gamma']}
            tooltipHeader="Gamma"
          />
        ),
      },
      {
        field: 'theta',
        headerName: 'Theta',
        type: 'numericColumn',
        width: 90,
        minWidth: 90,
        valueFormatter: ({value}) =>
          value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['theta']}
            tooltipHeader="Theta"
          />
        ),
      },
      {
        field: 'vega',
        headerName: 'Vega',
        type: 'numericColumn',
        width: 90,
        minWidth: 90,
        valueFormatter: ({value}) =>
          value
            ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
        headerComponentFramework: props => (
          <CustomHeader
            {...props}
            tooltipContent={tooltipMap['vega']}
            tooltipHeader="Vega"
          />
        ),
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (!this.gridApi) {
      return;
    }

    const positions = [
      ...this.props.positions.futures,
      ...this.props.positions.options,
    ].filter(el => el.currency === this.props.currency);

    this.gridApi.setRowData(positions);
    this.gridApi.forEachNode(node => {
      if (!node.group) {
        return node.setSelected(node.data.enabled, true, true);
      }
    });
    //
    // // TODO: improve efficiency
    // this.gridApi.refreshClientSideRowModel('group');
    //
    // // Update header on currency change
    // if (
    //   prevProps.currency !== this.props.currency ||
    //   prevProps.PNLCurrencyInUSD !== this.props.PNLCurrencyInUSD
    // ) {
    //   this.gridApi.refreshHeader();
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridApi.refreshCells();
    // } else {
    //   this.gridApi.refreshCells();
    // }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // Set columnState from storage if exists
    if (this.props.columnLayout !== null) {
      this.columnApi.setColumnState(this.props.columnLayout);
    }

    // Set new row data
    const positions = [
      ...this.props.positions.futures,
      ...this.props.positions.options,
    ].filter(el => el.currency === this.props.currency);
    this.gridApi.setRowData(positions);

    // Select all nodes that are enabled
    this.gridApi.forEachNode(node => {
      if (!node.group) {
        return node.setSelected(node.data.enabled, true, true);
      }
    });

    // Set default sort
    this.gridApi.setSortModel([
      {
        colId: 'ag-Grid-AutoColumn',
        sort: 'asc',
      },
    ]);

    // Update client side row model
    this.gridApi.refreshClientSideRowModel('group');
  }

  onFirstDataRendered() {
    this.gridApi.sizeColumnsToFit();
  }

  saveGridState() {
    this.props.actions.saveColumnLayout(this.columnApi.getColumnState());
  }

  cellChanged(params) {
    const {
      data: {kind, instrument, verity, size},
      column: {colId},
      newValue,
      rowIndex,
    } = params;

    const updates = {
      [colId]: newValue,
    };

    switch (colId) {
      case 'cur_price':
        updates.cur_price_locked = true;
        updates.cur_price = Math.max(newValue, 0);
        break;
      case 'avg_price':
        updates.avg_price_locked = true;
        updates.avg_price = Math.max(newValue, 0);
        break;
      case 'side':
        updates.side = newValue === 'long' ? 'buy' : 'sell';
        updates.size = newValue === 'long' ? Math.abs(size) : -Math.abs(size);
        break;
      case 'size':
        updates.side = newValue <= 0 ? 'sell' : 'buy';
        break;
      default:
        break;
    }

    const action = () => {
      if (kind === 'option') {
        this.props.actions.updateOptionPosition(
          instrument,
          verity,
          updates,
          true,
          colId,
        );
      } else {
        this.props.actions.updateFuturePosition(
          instrument,
          verity,
          updates,
          true,
          colId,
        );
      }

      if (verity === 'actual') this.props.actions.portfolioIsLiveChange(false);
    };

    if (this.props.positions.isLive && verity === 'actual') {
      this.setState({
        editAlertDialogOpen: true,
        editAlertDialogConfirm: () => {
          action();
          this.setState({editAlertDialogOpen: false});
        },
      });
    } else {
      action();
    }

    // We have to redraw rows because rowClassRules only receives the edited column
    // and we have two columns (side, size) on which styles rely
    this.gridApi.redrawRows({
      rowNodes: [this.gridApi.getDisplayedRowAtIndex(rowIndex)],
    });
  }

  getContextMenuItems = params => {
    const positionActions = [];
    if (params.node && !params.node.group) {
      positionActions.push({
        name: 'Delete Position',
        action: () =>
          this.props.actions.deletePosition(
            params.node.data.key,
            params.node.data.kind + 's',
            params.node.data.instrument,
          ),
      });
    }
    return positionActions;
  };

  groupRowAggNodes(nodes) {
    let result = {
      delta: 0,
      gamma: 0,
      theta: 0,
      vega: 0,
      pnl: 0,
    };

    nodes.forEach(node => {
      const position = node.group ? node.aggData : node.data;
      const selected = node.group ? true : node.selected;
      if (position.kind === 'option' && selected) {
        result.delta += +position.delta;
        result.gamma += +position.gamma;
        result.theta += +position.theta;
        result.vega += +position.vega;
        result.pnl += +position.pnl;
        result.kind = 'option';
      }

      if (position.kind === 'future' && selected) {
        result.pnl += +position.pnl;
        result.delta += +position.delta;
        result.gamma = 0;
        result.theta = 0;
        result.vega = 0;
        result.kind = 'future';
      }
    });

    return result;
  }

  portfolioIsLiveChangeHandler(bool) {
    this.props.actions.portfolioIsLiveChange(bool);
  }

  onSelectionChanged() {
    this.props.actions.togglePositions(
      this.props.currency,
      this.gridApi.getSelectedRows(),
    );
    this.gridApi.refreshCells({force: true});
    this.gridApi.refreshClientSideRowModel('aggregate');
  }

  render() {
    return (
      <React.Fragment>
        <ModalConfig id="Portfolio">
          {this.props.username &&
            this.props.positions.username !== 'anonymous' && (
              <MenuItem>
                <ListItemIcon>
                  <Switch
                    color="primary"
                    size="small"
                    checked={this.props.positions.isLive}
                    onChange={() =>
                      this.portfolioIsLiveChangeHandler(
                        !this.props.positions.isLive,
                      )
                    }
                  />
                </ListItemIcon>
                <ListItemText>Sync portfolio</ListItemText>
              </MenuItem>
            )}
          <MenuItem onClick={this.props.actions.clearPortfolio}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Clear portfolio</ListItemText>
          </MenuItem>
          {this.props.username && (
            <MenuItem onClick={() => this.props.actions.savePositionsToAWS()}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText>Share portfolio</ListItemText>
            </MenuItem>
          )}
        </ModalConfig>
        <Dialog open={this.state.editAlertDialogOpen}>
          <DialogTitle>Edit an actual position?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              By editing an actual position, live updates of your actual
              portfolio will be disabled.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({editAlertDialogOpen: false})}>
              Cancel
            </Button>
            <Button onClick={this.state.editAlertDialogConfirm} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <div
          id="OptionPositions"
          className="ag-theme-balham"
          style={{
            height: '100%',
            width: '100%',
          }}>
          {this.props.portfolioLoading && (
            <React.Fragment>
              <div
                style={{
                  backgroundColor: 'var(--elevated-2)',
                  width: '100%',
                  height: '100%',
                }}>
                <CircularProgress
                  classes={{
                    root: classes.Progress,
                    colorPrimary: classes.ProgressColorPrimary,
                  }}
                />
              </div>
            </React.Fragment>
          )}
          <AgGridReact
            suppressMovableColumns={window.innerWidth <= 960}
            suppressMenuHide={true}
            reactNext={true}
            suppressRowClickSelection={true}
            rowSelection="multiple"
            groupUseEntireRow={false}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            groupRowAggNodes={params => this.groupRowAggNodes(params)}
            suppressAggFuncInHeader={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            deltaRowDataMode={true}
            getRowNodeId={data => data.key}
            onGridReady={this.onGridReady}
            headerHeight={this.props.isMobile ? 32 : 24}
            rowHeight={24}
            sortingOrder={['asc', 'desc', null]}
            onDragStopped={() => this.saveGridState()}
            onCellValueChanged={e => this.cellChanged(e)}
            getContextMenuItems={this.getContextMenuItems}
            groupDefaultExpanded={-1}
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            onSelectionChanged={event => this.onSelectionChanged(event)}
            frameworkComponents={this.state.frameworkComponents}
            onFirstDataRendered={() => this.onFirstDataRendered()}
          />
        </div>
      </React.Fragment>
    );
  }
}

function currencyValueFormatter(column, currency, value, data) {
  const sign = value < 0 ? '-' : '';
  let prefix = '';

  let precision = 4;
  if (column === 'pnl' && currency === 'USD') {
    precision = 2;
  }

  if (data) {
    if (data.kind === 'future') {
      if (['size', 'avg_price', 'cur_price'].includes(column)) {
        precision = 2;
        prefix = '$';
      }
    }

    if (column === 'size') {
      precision = 2;
    }

    return (
      sign +
      prefix +
      Math.abs(value).toLocaleString('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
    );
  } else {
    if (['pnl', 'margin'].includes(column)) {
      return (
        sign +
        Math.abs(value).toLocaleString('en-US', {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        })
      );
    }
  }

  return value;
}

function instrumentComparator(A, B) {
  const map = {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OKT: 9,
    NOV: 10,
    DEC: 11,
  };

  const [, expA, strikeA, typeA] = A.split('-');
  const [, expB, strikeB, typeB] = B.split('-');

  if (expA && expB) {
    const [dayA, monthA, yearA] = expA.split(/(\d+)/).filter(Boolean);
    const [dayB, monthB, yearB] = expB.split(/(\d+)/).filter(Boolean);

    if (dayA === 'PERPETUAL') {
      return -1;
    }

    return (
      yearA - yearB ||
      map[monthA] - map[monthB] ||
      dayA - dayB ||
      strikeA - strikeB ||
      typeA - typeB
    );
  }

  if (+A && +B) {
    return A - B;
  }
}

const mapStateToProps = state => {
  return {
    username: state.auth.username,
    positions: state.portfolio.positions,
    columnLayout: state.settings.columnLayout,
    currency: state.settings.currency,
    PNLCurrencyInUSD: state.settings.PNLCurrencyInUSD,
    portfolioLoading: state.settings.portfolioLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
