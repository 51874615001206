import _ from 'lodash';

/**
 *  The 'Black-76' model for pricing options on futures contracts
 */
export class Black76 {
  constructor({
    contractType,
    futureUSD,
    strikeUSD,
    timeToExpiry,
    riskFreeRate,
    optionTOK,
    volatility,
  }) {
    this.e = contractType === 'call' ? 1 : -1;
    this.F = futureUSD;
    this.K = strikeUSD;
    this.T = timeToExpiry / 365;
    this.r = riskFreeRate / 100;
    this.p = futureUSD * optionTOK;
    this.v = volatility / 100;
  }

  // the Standard Normal probability density function (PDF)
  normal(x) {
    return (
      (1 / Math.sqrt(2 * Math.PI)) * Math.pow(Math.E, -0.5 * Math.pow(x, 2))
    );
  }

  // the Cumulative Standard Normal PDF
  normalCDF(z) {
    return Math.pow(
      1 + Math.pow(Math.E, -(0.07056 * Math.pow(z, 3) + 1.5976 * z)),
      -1,
    );
  }

  // setters
  setContractType(contractType) {
    this.e = contractType === 'call' ? 1 : -1;
  }

  setFutureUSD(futureUSD) {
    this.F = futureUSD;
  }

  setStrikeUSD(strikeUSD) {
    this.K = strikeUSD;
  }

  setTimeToExpiry(timeToExpiry) {
    this.T = timeToExpiry / 365;
  }

  setRiskFreeRate(riskFreeRate) {
    this.r = riskFreeRate / 100;
  }

  setOptionPriceUSD(futureUSD, optionTOK) {
    this.p = futureUSD * optionTOK;
  }

  setVolatility(volatility) {
    this.v = volatility / 100;
  }

  // helper expressions
  d1() {
    if (this.F === this.K && this.T === 0) {
      return Infinity;
    } else {
      return (
        (Math.log(this.F / this.K) +
          (this.r + (this.v * this.v) / 2) * this.T) /
        (this.v * Math.sqrt(this.T))
      );
    }
  }

  d2() {
    return this.d1() - this.v * Math.sqrt(this.T);
  }

  /**
   *  API
   */
  price() {
    return (
      this.e *
      Math.pow(Math.E, -this.r * this.T) *
      (this.F * this.normalCDF(this.e * this.d1()) -
        this.K * this.normalCDF(this.e * this.d2()))
    );
  }

  // Delta (dp / dF) is the change in option price with respect to (w.r.t.) a unit-change in the futures price
  delta() {
    return (
      this.e *
      Math.pow(Math.E, -this.r * this.T) *
      this.normalCDF(this.e * this.d1())
    );
  }

  // Gamma (d^2p / dF^2) is the change in Delta w.r.t. a unit-change in the futures price
  gamma() {
    if (this.T === 0) {
      return 0;
    } else {
      return (
        (Math.pow(Math.E, -this.r * this.T) * this.normal(this.d1())) /
        (this.F * this.v * Math.sqrt(this.T))
      );
    }
  }

  // Theta (dp / dt) is the change in option price w.r.t. a one-day change in time, where t = T - tau
  theta() {
    if (this.T === 0) {
      return 0;
    } else {
      return (
        (Math.pow(Math.E, -this.r * this.T) *
          (this.F *
            (this.e * this.r * this.normalCDF(this.e * this.d1()) -
              (this.v * this.normal(this.d1())) / (2 * Math.sqrt(this.T))) -
            this.K * this.e * this.r * this.normalCDF(this.e * this.d2()))) /
        365
      );
    }
  }

  // Vega (dp / dv) is the change in option price w.r.t. a one percentage-point change in volatility
  vega() {
    return (
      (this.F *
        Math.pow(Math.E, -this.r * this.T) *
        this.normal(this.d1()) *
        Math.sqrt(this.T)) /
      100
    );
  }

  // Rho (dp / dr) is the change in option price w.r.t. a one percentage-point change in the risk-free rate of interest
  rho() {
    return (
      (this.e *
        this.K *
        Math.pow(Math.E, -this.r * this.T) *
        this.normalCDF(this.e * this.d2()) *
        this.T) /
      100
    );
  }

  // more higher-order Greeks arriving here in the future...

  // the 'Newton-Raphson' method (with bracketing) for extracting annualized implied volatility from an option's price
  vol() {
    const iterations = 50,
      precision = 1e-10;

    // initial bracket
    let low = 0,
      hgh = 10;

    // initial guess
    this.v = (low + hgh) / 2;

    for (let i = 0; i < iterations; i++) {
      // calculate error between market and theoretical prices using current guess
      let error = this.p - this.price();

      // stop if within error tolerance
      if (Math.abs(error) < precision) {
        return this.v * 100;
      }

      // refine the bracket
      if (Math.sign(error) === 1) {
        low = this.v;
      } else {
        hgh = this.v;
      }

      // calculate the next estimate
      this.v += (error / this.vega()) * 100;

      // ensure the new estimate lies within the refined bracket
      if (this.v < low || hgh < this.v) {
        this.v = (low + hgh) / 2;
      }
    }

    return this.v * 100;
  }
}

function black76Factory(
  contractType,
  futureUSD,
  strikeUSD,
  timeToExpiry,
  riskFreeRate,
  optionTOK,
  volatility,
) {
  return new Black76({
    contractType,
    futureUSD,
    strikeUSD,
    timeToExpiry,
    riskFreeRate,
    optionTOK,
    volatility,
  });
}

export const memoizedBlack76Factory = _.memoize(black76Factory, (...args) =>
  args.join('_'),
);
