import MTooltip from '@material-ui/core/Tooltip';
import React, {memo} from 'react';
import classes from './Tooltip.module.scss';

// eslint-disable-next-line react/display-name
const Tooltip = React.forwardRef((props, ref) => {
  return (
    <MTooltip
      ref={ref}
      enterDelay={0}
      leaveDelay={0}
      classes={{tooltip: classes.Tooltip}}
      {...props}>
      {props.children}
    </MTooltip>
  );
});

export default memo(Tooltip);
