import {push} from 'connected-react-router';
import {makeid} from '../../utils/Auth';
import {
  getLatestSessionData,
  removeExpiredSessionsData,
  removeSessionsData,
  setSessionData,
} from '../../utils/SessionDataStorage';
import srp from '../../utils/srp';
import * as actionTypes from './actionTypes';
import * as actions from './index';

export const deauth = () => {
  return async (dispatch, getState) => {
    const {
      websocket: {testWs},
    } = getState();

    await dispatch({type: actionTypes.DEAUTH});
    await dispatch(actions.deleteUserData());
    await dispatch(actions.deletePositions('actual', 'all'));

    // Set portfolio to anonymous user
    await dispatch({
      type: actionTypes.SET_PORTFOLIO_OWNER,
      username: 'anonymous',
      system_name: 'anonymous',
    });

    removeSessionsData(`loginResp.${testWs ? 'test' : 'prod'}`);
  };
};

export const restoreSession = () => {
  return async (dispatch, getState) => {
    const {
      websocket: {testWs},
    } = getState();

    let session = getLatestSessionData(`loginResp.${testWs ? 'test' : 'prod'}`);
    if (session) {
      try {
        await dispatch(_forkToken(session.refresh_token));
        await dispatch(_login());
      } catch (error) {
        await dispatch(actions.deauth());
        await dispatch(actions.error(error));
      }
    }

    removeExpiredSessionsData(`loginResp.${testWs ? 'test' : 'prod'}`);
  };
};

export const login = (email, password, tfa) => {
  return async (dispatch, getState) => {
    try {
      const {
        websocket: {sessionId},
      } = getState();

      let response = await dispatch(
        actions.send('public/login', {
          request: 'hello',
          email,
          lang: 'en',
        }),
      );

      if (response.type === 'password') {
        response = await dispatch(
          actions.send('public/login', {
            request: 'authenticate',
            scope: `session:${sessionId}`,
            tfa,
            password,
          }),
        );
      } else if (response.type === 'srp') {
        const {N, g, I, s, B} = response;
        const {A, M1} = srp.generateClientKeys(I, password, N, g, s, B);
        response = await dispatch(
          actions.send('public/login', {
            request: 'authenticate',
            scope: `session:${sessionId}`,
            tfa,
            A,
            M1,
          }),
        );
      }

      await dispatch(_handleToken(response));
      return dispatch(_login());
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

const _login = () => {
  return async (dispatch, getState) => {
    await dispatch(actions.fetchAccountSummary());
    await dispatch(actions.fetchSubaccounts());

    const {
      portfolio: {
        positions: {isLive, username},
      },
      auth: {username: loggedUsername, system_name},
    } = getState();

    console.log(loggedUsername);
    // Set anonymous portfolio to current logged in user
    if (username === 'anonymous') {
      await dispatch({
        type: actionTypes.SET_PORTFOLIO_OWNER,
        username: loggedUsername,
        system_name,
      });
    }

    // Resync portfolio if portfolio is live.
    if (isLive) {
      await dispatch(actions.fetchPositionsFromDeribitWithMissingTickers());
    }
  };
};

const _handleToken = (response, publicAuth) => {
  return async (dispatch, getState) => {
    try {
      const {
        websocket: {sessionId, testWs},
      } = getState();

      // Save session data
      setSessionData(
        `loginResp.${testWs ? 'test' : 'prod'}`,
        sessionId,
        response,
      );

      if (!publicAuth) {
        return dispatch(
          actions.send('public/bind_access_token', {
            access_token: response.access_token,
          }),
        );
      }
    } catch (error) {
      dispatch(actions.error(error));
    }
  };
};

export const oauth = () => {
  return (dispatch, getState) => {
    const {
      websocket: {testWs, clientId, sessionId},
    } = getState();

    const host = `https://${testWs ? 'test' : 'www'}.deribit.com/`;
    const redirect =
      window.location.hostname === 'localhost'
        ? 'http://localhost:3000/#/oauth'
        : 'https://pb.deribit.com/#/oauth';
    const url = `${host}app_authorization?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirect,
    )}&scope=${encodeURIComponent(
      `account:read trade:read expires:604800 session:${sessionId}`,
    )}&state=0&response_type=token`;
    window.location.href = url;
  };
};

export const authSecret = (accessKey, secretKey) => {
  return dispatch => {
    return dispatch(
      actions.send('public/auth', {
        grant_type: 'client_credentials',
        scope: 'account:read_write trade:read expires:604800',
        client_id: accessKey,
        client_secret: secretKey,
      }),
    ).then(
      response => response,
      error => dispatch(actions.error(error)),
    );
  };
};

const _forkToken = refresh_token => {
  return async dispatch => {
    // const {
    //   websocket: {sessionId},
    // } = getState();

    try {
      const response = await dispatch(
        actions.send('public/fork_token', {
          session_name: makeid(6),
          refresh_token,
        }),
      );

      await dispatch(_getNewAuthToken(response));
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

const _getNewAuthToken = ({refresh_token}) => {
  return async dispatch => {
    try {
      const response = await dispatch(
        actions.send('public/auth', {
          grant_type: 'refresh_token',
          refresh_token,
        }),
      );

      return dispatch(_handleToken(response, true));
    } catch (error) {
      let expires = 0;
      if (refresh_token)
        expires = (refresh_token.split('.')[0] - Date.now()) / 1000;

      if (expires <= 0) dispatch(actions.deauth());
    }
  };
};

// If we login using oauth we need to authenticate our websocket
// using the received response
export const oauthResponse = response => {
  return async dispatch => {
    try {
      await dispatch(_getNewAuthToken(response));
      await dispatch(_login());
      return dispatch(push('/BTC'));
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};

export const switchAccount = subject_id => {
  return (dispatch, getState) => {
    const {
      websocket: {testWs},
    } = getState();

    return dispatch(
      actions.exchangeToken(
        getLatestSessionData(`loginResp.${testWs ? 'test' : 'prod'}`)
          .refresh_token,
        subject_id,
      ),
    );
  };
};

export const exchangeToken = (refresh_token, subject_id) => {
  return async dispatch => {
    try {
      const response = await dispatch(
        actions.send('public/exchange_token', {refresh_token, subject_id}),
      );

      await dispatch(_handleToken(response));

      window.location.reload();
    } catch (error) {
      return dispatch(actions.error(error));
    }
  };
};
